import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'

import '../Compensation.less';
import { TagsStart } from './Tags'
import { TimesStart } from './Times'

import { TTime } from './index'

type TCountdownProps = {
  startTime: TTime
  endTime: TTime
}
const CountStart: React.FC<TCountdownProps>
  = ({
       startTime, endTime
     }) => {
  const [timeRefresh, setTimeRefresh] = useState<number>(new Date().getTime());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRefresh(new Date().getTime());
    }, 2000)
    return () => clearInterval(timer);
  }, []);

  return (
    <Row justify="space-between" className="countdown">
      <Col>
        {TagsStart(startTime, endTime)}
      </Col>
      <Col>
        {TimesStart(startTime, endTime, timeRefresh)}
      </Col>
    </Row>
  )
}

export default CountStart;
