import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useBlockNumber } from '../state/application/hooks'
import { useTokenContract } from './useContract'
import { Contract } from 'ethers'

const useTokenBalance = (tokenAddress: string, accountInput?: string) => {
  const [balance, setBalance] = useState(new BigNumber(0))

  //TODO danie 这里会实时，性能
  const block = useBlockNumber()
  const tokenContract: Contract | null = useTokenContract(tokenAddress, true)

  const fetchBalance = useCallback(async () => {
    if (!tokenContract) return

    let addr
    if (accountInput) {
      addr = accountInput
    } else if (tokenContract.signer) {
      addr = await tokenContract.signer.getAddress()
    }

    if (!addr) return

    const balance = await tokenContract.functions.balanceOf(addr)
    setBalance(new BigNumber(balance))
  }, [tokenContract, accountInput])

  useEffect(() => {
    fetchBalance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBalance, block, tokenAddress, tokenContract])

  return balance
}

export default useTokenBalance
