import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default class DateTimeUtils {
  public static toNiceDate(date: any): string {
    return dayjs.utc(dayjs.unix(date)).format('YYYY-MMM-DD')
  }

  public static toDateOfMD(date: any): string {
    return dayjs.utc(dayjs.unix(date)).format('MMM-DD')
  }

  public static formatTime(unix: any): string {
    const now = dayjs()
    const timestamp = dayjs.unix(unix)

    const inSeconds = now.diff(timestamp, 'second')
    const inMinutes = now.diff(timestamp, 'minute')
    const inHours = now.diff(timestamp, 'hour')
    const inDays = now.diff(timestamp, 'day')

    if (inHours >= 24) {
      return `${inDays} ${inDays === 1 ? 'day' : 'days'} ago`
    } else if (inMinutes >= 60) {
      return `${inHours} ${inHours === 1 ? 'hour' : 'hours'} ago`
    } else if (inSeconds >= 60) {
      return `${inMinutes} ${inMinutes === 1 ? 'minute' : 'minutes'} ago`
    } else {
      return `${inSeconds} ${inSeconds === 1 ? 'second' : 'seconds'} ago`
    }
  }

  /**
   * 获取1天前、2天前、7天前的时间戳
   */
  public static getTimestampsForChanges(): [number, number, number] {
    const utcCurrentTime = dayjs()
    const t1 = utcCurrentTime
      .subtract(1, 'day')
      .startOf('minute')
      .unix()
    const t2 = utcCurrentTime
      .subtract(2, 'day')
      .startOf('minute')
      .unix()
    const tWeek = utcCurrentTime
      .subtract(1, 'week')
      .startOf('minute')
      .unix()
    return [t1, t2, tWeek]
  }
}
