import axios from 'axios'

import { tokenPriceAPI } from '../constants/sashimi/constants'

// {
//   "USD": 366.93,
//   "symbol": "ETH"
// }
// https://wallet-test.aelf.io/api/token/price?fsym=ETH&tsyms=USD
export async function queryTokenPrice(symbolA: string, symbolB: string) {
  const result: any = await axios.get(`${tokenPriceAPI}?fsym=${symbolA}&tsyms=${symbolB}`)
  return result
}
