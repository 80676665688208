import React from 'react'
import styled from 'styled-components'

interface CardTitleProps {
  icon?: React.ReactNode
  text?: string | React.ReactNode
  children?: React.ReactNode
  borderBottom?: string
}

// eslint-disable-next-line react/prop-types
const CardTitle: React.FC<CardTitleProps> = ({ icon, text, children, borderBottom }) => (
  <StyledCardTitle borderBottom={borderBottom}>
    <div>
      {icon ? <ImgWrap>{icon}</ImgWrap> : null}
      {text}
    </div>
    {children}
  </StyledCardTitle>
)

const StyledCardTitle = styled.div<{borderBottom?: string}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #121212;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 18px 0 16px 0;
  text-align: left;
  border-bottom: ${({ borderBottom }) => (borderBottom ? borderBottom : '1px solid #f5f5f5')}
`

const ImgWrap = styled.span`
  margin-right: 10px;
`

export default CardTitle
