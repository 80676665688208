import { ethers } from 'ethers'

export default class ExchangeUtils {
  // /**
  //  * 根据时间戳列表获取对应的区块
  //  * @param timestamps
  //  * @param skipCount
  //  */
  // public static async getBlocksFromTimestamps(timestamps: number[], skipCount = 500) {
  //   if (!timestamps || timestamps?.length === 0) {
  //     return []
  //   }
  //
  //   let fetchedData = await splitQuery(GET_BLOCKS, blockClient, [], timestamps, skipCount)
  //
  //   let blocks = []
  //   if (fetchedData) {
  //     for (let t in fetchedData) {
  //       if (fetchedData[t].length > 0) {
  //         blocks.push({
  //           timestamp: t.split('t')[1],
  //           number: fetchedData[t][0]['number']
  //         })
  //       }
  //     }
  //   }
  //   return blocks
  // }

  public static getPercentChange(valueNow: number | string, value24HoursAgo: number | string): number {
    const v: number = typeof valueNow === 'string' ? parseFloat(valueNow) : valueNow
    const v24: number = typeof value24HoursAgo === 'string' ? parseFloat(value24HoursAgo) : value24HoursAgo

    const adjustedPercentChange = ((v - v24) / v24) * 100
    if (isNaN(adjustedPercentChange) || !isFinite(adjustedPercentChange)) {
      return 0
    }
    return adjustedPercentChange
  }

  /**
   * gets the amoutn difference plus the % change in change itself (second order change)
   * @param {*} valueNow
   * @param {*} value24HoursAgo
   * @param {*} value48HoursAgo
   */
  public static get2DayPercentChange(
    valueNow: string | number,
    value24HoursAgo: string | number,
    value48HoursAgo: string | number
  ): [number, number] {
    const v: number = typeof valueNow === 'string' ? parseFloat(valueNow) : valueNow
    const v24: number = typeof value24HoursAgo === 'string' ? parseFloat(value24HoursAgo) : value24HoursAgo
    const v48: number = typeof value48HoursAgo === 'string' ? parseFloat(value48HoursAgo) : value48HoursAgo

    // get volume info for both 24 hour periods
    const currentChange: number = v - v24
    const previousChange: number = v24 - v48

    const adjustedPercentChange = ((currentChange - previousChange) / previousChange) * 100

    if (isNaN(adjustedPercentChange) || !isFinite(adjustedPercentChange)) {
      return [currentChange, 0]
    }
    return [currentChange, adjustedPercentChange]
  }

  public static isAddress(value: string): string | undefined {
    try {
      return ethers.utils.getAddress(value.toLowerCase())
    } catch {
      return
    }
  }
}
