import { Contract } from '@ethersproject/contracts'
import ERC20_ABI from '../../../constants/abis/erc20.json'
import EXCHANGE_ABI from '../abi/Exchange.json';
import SHD_ABI from '../abi/ShardingDAO.json';
import { useContract } from '../../../hooks/useContract'
import { SHARDING_DAO_ADDRESS } from '../config/config'

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useExchangeContract(exchangeAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(exchangeAddress, EXCHANGE_ABI, withSignerIfPossible)
}

export function useSHDContract(withSignerIfPossible?: boolean): Contract | null {
  return useContract(SHARDING_DAO_ADDRESS, SHD_ABI, withSignerIfPossible)
}

