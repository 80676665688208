// the Uniswap Default token list lives here
// todo: 修改token list url
export const DEFAULT_TOKEN_LIST_URL =
  'https://raw.githubusercontent.com/SashimiProject/token-list/master/sashimiswap.tokenlist.json'

export const DEFAULT_LIST_OF_LISTS: string[] = [
  DEFAULT_TOKEN_LIST_URL,
  'defi.cmc.eth',
  'erc20.cmc.eth',
  'stablecoin.cmc.eth',
  'tokenlist.zerion.eth',
  'tokenlist.aave.eth',
  'https://tokens.coingecko.com/uniswap/all.json',
  'https://app.tryroll.com/tokens.json',
  'https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json',
  'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json'
  // 'https://defiprime.com/defiprime.tokenlist.json',
  // 'https://umaproject.org/uma.tokenlist.json'
  // 't2crtokens.eth', // kleros
  // 'tokens.1inch.eth', // 1inch
  // 'synths.snx.eth',
  // 'tokenlist.dharma.eth',
  // 'tokenlist.zerion.eth',
  // 'tokenlist.aave.eth',
  // 'https://app.tryroll.com/tokens.json',
]
