import { AppDispatch, AppState } from '../index'
import { useDispatch, useSelector } from 'react-redux'
import {
  isBarChart,
  PairChartData,
  PairChartPeriod,
  PairChartShowType,
  updateChartPeriod,
  updateChartType,
  updatePairAddress
} from './actions'
import { useCallback, useMemo } from 'react'
import { ChartState } from './reducer'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export function useChartPairAddress(): AppState['pairChart']['pairAddress'] {
  return useSelector<AppState, AppState['pairChart']['pairAddress']>(state => state.pairChart.pairAddress)
}

export function useChartState(): AppState['pairChart'] {
  return useSelector<AppState, AppState['pairChart']>(state => state.pairChart)
}

export function useChartShowPeriod(): AppState['pairChart']['showPeriod'] {
  return useSelector<AppState, AppState['pairChart']['showPeriod']>(state => state.pairChart.showPeriod)
}

export function useCharShowType(): AppState['pairChart']['showType'] {
  return useSelector<AppState, AppState['pairChart']['showType']>(state => state.pairChart.showType)
}

/**
 * 根据周期计算图表的显示内容开始时间
 * @param period
 */
function getTimeframeByPeriod(period: PairChartPeriod) {
  const utcEndTime = dayjs.utc()
  let utcStartTime
  switch (period) {
    case PairChartPeriod.WEEK:
      utcStartTime =
        utcEndTime
          .subtract(1, 'week')
          .endOf('day')
          .unix() - 1
      break
    case PairChartPeriod.MONTH:
      utcStartTime =
        utcEndTime
          .subtract(1, 'month')
          .endOf('day')
          .unix() - 1
      break
    case PairChartPeriod.ALL:
      utcStartTime =
        utcEndTime
          .subtract(1, 'year')
          .endOf('day')
          .unix() - 1
      break
    default:
      utcStartTime =
        utcEndTime
          .subtract(1, 'year')
          .startOf('year')
          .unix() - 1
      break
  }
  return utcStartTime
}

export function useChartDataByAddress(
  address: string
): {
  pairAddress: string
  showType: PairChartShowType
  showPeriod: PairChartPeriod
  showList: any[] | undefined
} {
  const { showType, showPeriod, byPair }: ChartState = useChartState()

  const data: PairChartData = byPair[address]

  return useMemo(() => {
    let showList: any[] | undefined

    const utcStartTime = getTimeframeByPeriod(showPeriod)
    if (isBarChart(showType)) {
      showList = data?.barChart?.filter(entry => entry.date >= utcStartTime)
    } else if (showType === PairChartShowType.PositiveCandles) {
      showList = data?.candles?.[showPeriod.toString()]?.[0]?.filter(entry => entry.timestamp >= utcStartTime)
    } else if (showType === PairChartShowType.ReverseCandles) {
      showList = data?.candles?.[showPeriod.toString()]?.[1]?.filter(entry => entry.timestamp >= utcStartTime)
    }

    return {
      pairAddress: address,
      showType: showType,
      showPeriod: showPeriod,
      showList: showList
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showType, showPeriod, data])
}

export function useChartActionHandler(): {
  updateType: (type: PairChartShowType) => void
  updatePeriod: (period: PairChartPeriod) => void
  updateAddress: (address: string) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const updateType = useCallback(
    (type: PairChartShowType) => {
      dispatch(updateChartType({ showType: type, data: null }))
    },
    [dispatch]
  )

  const updatePeriod = useCallback(
    (period: PairChartPeriod) => {
      return dispatch(updateChartPeriod({ period: period }))
    },
    [dispatch]
  )
  const updateAddress = useCallback(
    (address: string) => {
      return dispatch(updatePairAddress({ pairAddress: address }))
    },
    [dispatch]
  )

  return {
    updateType,
    updatePeriod,
    updateAddress
  }
}
