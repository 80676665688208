import { createAction } from '@reduxjs/toolkit'
import { PairData } from '../exchange/entities/pair_entity'

// fired once when the app reloads but before the app renders
// allows any updates to be applied to store data loaded from localStorage
export const updateVersion = createAction<void>('global/updateVersion')

export const updateEtherPrice = createAction<{
  now: number
  oneDayAgo: number
  change: number
}>('global/updateEtherPrice')
export const initialGlobalStatisticsData = createAction<any>('global/initialGlobalStatisticsData')
export const initialGlobalChartData = createAction<any[]>('global/initialGlobalChartData')
export const initialPairs = createAction<{ [key: string]: PairData }>('global/initialPairs')

export const initialTVLChartData = createAction<any[]>('global/initialTVLChartData')