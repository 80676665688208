import { Spin } from 'antd'
import styled from 'styled-components'
import React from 'react'
const LoadingWrap = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Loader: React.FC = () => {
  return (
    <LoadingWrap>
      <Spin size="large" tip="Loading···" />
    </LoadingWrap>
  )
}
