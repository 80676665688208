import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useMedia } from 'react-use'
import styled from 'styled-components'
import Card from '../../components/Card'
import CardContent from '../../components/CardContent'
import CardTitle from '../../components/CardTitle'
import MainContainer from '../../components/MainContainer'
import CoinLogo from '../../components/CoinLogo'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons'
import BalanceUtils from '../../fuck/formatBalance'
import { PairData, PairUtils } from '../../state/exchange/entities/pair_entity'
import { formattedPercent } from '../../utils'
import { useChartActionHandler } from '../../state/pair-chart/hooks'
import JumpIcon from '../../assets/images/jump_icon.png'
import { usePairs } from '../../state/global/hooks'
import { isEthAddress } from '../../utils'
import Tokens from '../Tokens'
import { TYPE } from '../../theme'

const SortType = {
  LIQUIDITY: 0,
  VOLUMN24H: 1,
  VOLUMN7D: 2,
  FEES24H: 3,
  FEES1Y: 4
}
const FIELD_TO_VALUE: { [key: number]: string } = {
  [SortType.LIQUIDITY]: 'trackedReserveUSD',
  [SortType.VOLUMN24H]: 'oneDayVolumeUSD',
  [SortType.VOLUMN7D]: 'oneWeekVolumeUSD',
  [SortType.FEES24H]: 'oneDayVolumeUSD'
}

const ITEMS_PER_PAGE = 10
const Pairs: React.FC = () => {
  const pairs: PairData[] = usePairs()

  const below1300 = useMedia('(max-width:1300px)')
  const below576 = useMedia('(max-width:576px)')
  const [sortType, setSortType] = useState<number>(SortType.LIQUIDITY)
  const [orderByDesc, setOrderByDesc] = useState<boolean>(true)
  const [page, setPage] = useState<number>(1)
  const [maxPage, setMaxPage] = useState<number>(1)
  const [searchInfo, setSearchInfo] = useState<string>('')
  useEffect(() => {
    setMaxPage(1) // edit this to do modular
    setPage(1)
  }, [pairs])
  const pairsList = useMemo(() => {
    if (searchInfo !== '') setPage(1)
    return pairs
      .sort((pairA: any, pairB: any) => {
        if (sortType === SortType.FEES1Y) {
          const apy0 = (parseFloat(pairA?.oneDayVolumeUSD) * 0.003 * 356 * 100) / parseFloat(pairA.reserveUSD)
          const apy1 = (parseFloat(pairB?.oneDayVolumeUSD) * 0.003 * 356 * 100) / parseFloat(pairB.reserveUSD)
          return apy0 > apy1 ? (orderByDesc ? -1 : 1) * 1 : (orderByDesc ? -1 : 1) * -1
        }
        return parseFloat(pairA[FIELD_TO_VALUE[sortType]]) > parseFloat(pairB[FIELD_TO_VALUE[sortType]])
          ? (orderByDesc ? -1 : 1) * 1
          : (orderByDesc ? -1 : 1) * -1
      })
      .filter((item: PairData) => {
        const symbol0: string = item?.token0?.symbol.toLocaleLowerCase()
        const symbol1: string = item?.token1?.symbol.toLocaleLowerCase()
        return symbol0.indexOf(searchInfo) !== -1 || symbol1.indexOf(searchInfo) !== -1
      })
      // .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
  }, [pairs, searchInfo, orderByDesc, sortType])

  useEffect(() => {
    let pairsLen = 0;
    if(searchInfo) {
      pairsLen = pairsList.length
    }else {
      pairsLen = pairs.length

    }
    // console.log(pairsLen, 'parisLen____')
    // setMaxPage(
    //   pairsLen
    //     ? pairsLen % ITEMS_PER_PAGE === 0
    //       ? Math.floor(pairsLen / ITEMS_PER_PAGE)
    //       : Math.floor(pairsLen / ITEMS_PER_PAGE) + 1
    //     : 0
    // )
    if (pairs && pairsList) {
      let extraPages = 1
      if (pairsLen % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(pairsLen / ITEMS_PER_PAGE) + extraPages)
    }
  }, [pairsList, pairs, searchInfo])

  //   if (tokens && formattedTokens) {
  //     let extraPages = 1
  //     if (tokenLen % ITEMS_PER_PAGE === 0) {
  //       extraPages = 0
  //     }
  //     setMaxPage(Math.floor(tokenLen / ITEMS_PER_PAGE) + extraPages)
  //   }
  // }, [tokens, formattedTokens, ITEMS_PER_PAGE, filteredList,searchInfo])
  return (
    <MainContainer>
      <Card>
        <CardContent>
          <CardTitle text={`${below576 ? '' : 'Trading pairs on Sashimiswap'}`}>
            <InputSearchWrap>
              <InputSearch
                type="text"
                placeholder="Find a pair"
                maxLength={15}
                onKeyUp={event => {
                  const val = event.currentTarget.value.toLocaleLowerCase().replace(/\s/g, '')
                  setSearchInfo(val)
                }}
              />
              <SearchOutlined style={{ color: '#9DA2AB' }} className="search-icon" />
            </InputSearchWrap>
          </CardTitle>
          <ListItem className="title">
            <span className="item name">Name</span>
            <span
              className="item"
              onClick={() => {
                setSortType(SortType.LIQUIDITY)
                setOrderByDesc(sortType === SortType.LIQUIDITY ? !orderByDesc : true)
              }}
            >
              Liquidity {sortType === SortType.LIQUIDITY ? (orderByDesc ? '↓' : '↑') : ''}
            </span>
            <span
              className="item"
              onClick={() => {
                setSortType(SortType.VOLUMN24H)
                setOrderByDesc(sortType === SortType.VOLUMN24H ? !orderByDesc : true)
              }}
            >
              Volume（24hrs）{sortType === SortType.VOLUMN24H ? (orderByDesc ? '↓' : '↑') : ''}
            </span>
            {!below1300 && (
              <span
                className="item"
                onClick={() => {
                  setSortType(SortType.VOLUMN7D)
                  setOrderByDesc(sortType === SortType.VOLUMN7D ? !orderByDesc : true)
                }}
              >
                Volume（7d）{sortType === SortType.VOLUMN7D ? (orderByDesc ? '↓' : '↑') : ''}
              </span>
            )}
            {!below1300 && (
              <span
                className="item"
                onClick={() => {
                  setSortType(SortType.FEES24H)
                  setOrderByDesc(sortType === SortType.FEES24H ? !orderByDesc : true)
                }}
              >
                Fees（24hr）{sortType === SortType.FEES24H ? (orderByDesc ? '↓' : '↑') : ''}
              </span>
            )}
            {!below1300 && (
              <span
                className="item"
                onClick={() => {
                  setSortType(SortType.FEES1Y)
                  setOrderByDesc(sortType === SortType.FEES1Y ? !orderByDesc : true)
                }}
              >
                1y Fees / Liquiduty {sortType === SortType.FEES1Y ? (orderByDesc ? '↓ ' : '↑ ') : ' '}
                <Tooltip placement="left" title="Based on 24hr volume annualized">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            )}
          </ListItem>
          {pairsList &&
            pairsList.slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE).map((item, index) => {
              return <PairItem pairData={item} index={index} key={'PairItem' + index} />
            })}
        </CardContent>
        <ButtonWrap>
          <div onClick={() => setPage(page === 1 ? page : page - 1)}>
            <Arrow faded={page === 1 ? true : false}>←</Arrow>
          </div>
          <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
          <div onClick={() => setPage(page === maxPage ? page : page + 1)}>
            <Arrow faded={page === maxPage ? true : false}>→</Arrow>
          </div>
        </ButtonWrap>
      </Card>
      <div style={{ height: '50px' }}></div>
      <Tokens />
    </MainContainer>
  )
}

function PairItem(payload: { [key: string]: any }) {
  const below1300 = useMedia('(max-width:1300px)')
  const below576 = useMedia('(max-width:576px)')
  const history = useHistory()
  const { updateAddress } = useChartActionHandler()
  const pairData: PairData = payload.pairData
  const index: number = payload.index

  const liquidity = BalanceUtils.formattedNum(pairData.reserveUSD, true)
  const volume = BalanceUtils.formattedNum(pairData.oneDayVolumeUSD, true)

  const _onDayVolumeUSD = parseFloat(pairData.oneDayVolumeUSD)
  const _reserveUSD = parseFloat(pairData.reserveUSD)
  const _per = (_onDayVolumeUSD * 0.003 * 365 * 100) / _reserveUSD
  const _apy = formattedPercent(_per)
  const apy: string = _apy.value

  const fee = BalanceUtils.formattedNum(_onDayVolumeUSD * 0.003, true)

  return (
    <ListItem
      key={index}
      onClick={() => {
        updateAddress(pairData.id)
        history.push({
          pathname: `/exchange/swap/${isEthAddress(pairData?.token0?.id)}/${isEthAddress(pairData?.token1?.id)}`,
          state: pairData.id
        })
      }}
    >
      <span className="name">
        <div className="img-wrap">
          <CoinLogo key={`${pairData?.id}img`} size={below576 ? 14 : 24} url={PairUtils.getPairLogo(pairData)} />
        </div>
        <span>
          <strong className="ellipsis">{PairUtils.getPairString(pairData)}</strong>
          <img className="jump" src={JumpIcon} alt="" style={{ display: `${!below576 ? 'block' : 'none'}` }} />
        </span>
      </span>
      <span className="item">{liquidity}</span>
      <span className="item">{volume}</span>
      {!below1300 && <span className="item">{BalanceUtils.formattedNum(pairData.oneWeekVolumeUSD, true)}</span>}
      {!below1300 && <span className={`item ${_per < 0 ? 'red' : 'green'}`}>{fee}</span>}
      {!below1300 && <span className={`item ${_per < 0 ? 'red' : 'green'}`}>{apy}</span>}
    </ListItem>
  )
}

const InputSearchWrap = styled.div`
  position: relative;
  .search-icon {
    position: absolute;
    top: 11px;
    right: 20px;
  }
  @media screen and (max-width: 576px) {
    flex: 1;
  }
`
const InputSearch = styled.input`
  box-sizing: border-box;
  width: 360px;
  border: none;
  outline: none;
  line-height: 20px;
  padding: 10px 0;
  text-indent: 26px;
  border-radius: 20px;
  background-color: #f3f3f4;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
  &::placeholder {
    color: #b2b2b2;
    font-size: 14px;
  }
`
const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f2;
  color: #333;
  cursor: pointer;
  /* display:grid;
    grid-template-columns:1.5fr 1fr 1fr 1fr 1fr 1fr; */
  &:not(.title):hover {
    background-color: rgba(252, 133, 95, 0.06);
  }
  &.title {
    .item {
      &:first-child {
        cursor: default;
      }
      &:not(:first-child):hover {
        color: #333;
      }
    }
  }
  &.title {
    color: #9da2ab;
    border-bottom: none;
    white-space: nowrap;
  }
  .jump {
    width: 16px;
  }

  .item {
    flex: 1;
    text-align: right;
    height: 50px;
    line-height: 50px;
    font-weight: 500;
    &:first-child {
      display: flex;
      justify-self: flex-start;
      align-self: center;
    }
    &.green {
      color: #06b478;
    }
    &.red {
      color: #ff5d5d;
    }
  }
  .name {
    flex: 1.4;
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    span {
      display: flex;
      align-items: center;
      color: #333;
    }
    .img-wrap {
      margin-right: 18px;
      @media screen and (max-width: 576px) {
        margin-right: 8px;
      }
    }
  }
  strong {
    margin-right: 18px;
    @media screen and (max-width: 576px) {
      width: 80px;
      margin-right: 8px;
    }
  }
`
const ButtonWrap = styled.div`
  padding: 22px 0 34px 0;
  display: flex;
  justify-content: center;
`
const Arrow = styled.div`
  color: ${({ theme }) => theme.primary1};
  opacity: ${({ faded }: { faded: boolean }) => (faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`
export default Pairs
