import { useEffect } from 'react'
import { getEtherPrice } from '../global/updater'
import { useDispatch, useSelector } from 'react-redux'
import { UPDATE_TOP_TOKENS } from './actions'
import dayjs from 'dayjs'
import { getBlocksFromTimestamps } from '../common/hooks'
import { GraphCommand, SubgraphClient as client, SwapInfoClient, HealthClient, HealthCommand } from '../../api/apollo'
import ExchangeUtils from '../../fuck/exchangeUtils'
import { AppState } from '../index'
// import {splitQuery} from '../common/hooks'
import { ApolloClient } from 'apollo-client'
export function useTokens(): any {
  return useSelector<AppState, AppState['tokens']['allTokens']>(state => state.tokens.allTokens)
}

export function useTokensData(): any {
  return useSelector<AppState, AppState['tokens']['tokensData']>(state => state.tokens.tokensData)
}

async function getTopTokens(ethPrice: number, oldEthPrice: number): Promise<any[] | undefined> {
  try {

  const utcCurrentTime = dayjs()
  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
  const utcTwoDaysBack = utcCurrentTime.subtract(2, 'day').unix()

  // get the blocks needed for time travel queries
  const [{ number: oneDayBlock }, { number: twoDayBlock }] = await getBlocksFromTimestamps([
    // utcCurrentTime.subtract(1,'minute').unix(),
    utcOneDayBack,
    utcTwoDaysBack
  ])
    const current = await client.query({
      query: GraphCommand.queryTokens(),
      fetchPolicy: 'no-cache'
    })

    const oneDayResult = await client.query({
      query: GraphCommand.queryTokenDataByBlock(+oneDayBlock),
      fetchPolicy: 'cache-first'
    })
    
    const twoDayResult = await client.query({
      query: GraphCommand.queryTokenDataByBlock(+twoDayBlock),
      fetchPolicy: 'cache-first'
    })
    const oneDayData = oneDayResult?.data?.tokens.reduce((obj: any, cur: any) => {
      return { ...obj, [cur.id]: cur }
    }, {})

    const twoDayData = twoDayResult?.data?.tokens.reduce((obj: any, cur: any) => {
      return { ...obj, [cur.id]: cur }
    }, {})

    const bulkResults = await Promise.all(
      current &&
        oneDayData &&
        twoDayData &&
        current?.data?.tokens.map(async (token: any) => {
          const data = { ...token }
          // let liquidityDataThisToken = liquidityData?.[token.id]
          let oneDayHistory = oneDayData?.[token.id]
          let twoDayHistory = twoDayData?.[token.id]
          
          // catch the case where token wasnt in top list in previous days
          if (!oneDayHistory) {
            const oneDayResult = await client.query({
              query: GraphCommand.queryTokenDataWithTime(token.id, oneDayBlock && 0),
              fetchPolicy: 'cache-first'
            })
            oneDayHistory = oneDayResult.data.tokens[0]
          }
          if (!twoDayHistory) {
            const twoDayResult = await client.query({
              query: GraphCommand.queryTokenDataWithTime(token.id, twoDayBlock && 0),
              fetchPolicy: 'cache-first'
            })
            twoDayHistory = twoDayResult.data.tokens[0]
          }

          // calculate percentage changes and daily changes
          const [oneDayVolumeUSD, volumeChangeUSD] = ExchangeUtils.get2DayPercentChange(
            data.tradeVolumeUSD,
            oneDayHistory?.tradeVolumeUSD ?? 0,
            twoDayHistory?.tradeVolumeUSD ?? 0
          )
          const [oneDayTxns, txnChange] = ExchangeUtils.get2DayPercentChange(
            data.txCount,
            oneDayHistory?.txCount ?? 0,
            twoDayHistory?.txCount ?? 0
          )

          const currentLiquidityUSD = data?.totalLiquidity * ethPrice * data?.derivedETH
          const oldLiquidityUSD = oneDayHistory?.totalLiquidity * oldEthPrice * oneDayHistory?.derivedETH

          // percent changes
          const priceChangeUSD = ExchangeUtils.getPercentChange(
            data?.derivedETH * ethPrice,
            oneDayHistory?.derivedETH ? oneDayHistory?.derivedETH * oldEthPrice : 0
          )

          // set data
          data.priceUSD = data?.derivedETH * ethPrice
          data.totalLiquidityUSD = currentLiquidityUSD
          data.oneDayVolumeUSD = oneDayVolumeUSD
          data.volumeChangeUSD = volumeChangeUSD
          data.priceChangeUSD = priceChangeUSD
          data.liquidityChangeUSD = ExchangeUtils.getPercentChange(currentLiquidityUSD ?? 0, oldLiquidityUSD ?? 0)
          data.oneDayTxns = oneDayTxns
          data.txnChange = txnChange

          // new tokens
          if (!oneDayHistory && data) {
            data.oneDayVolumeUSD = data.tradeVolumeUSD
            data.oneDayVolumeETH = data.tradeVolume * data.derivedETH
            data.oneDayTxns = data.txCount
          }

          if (data.id === '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2') {
            data.name = `Ether (Wrapped)`
            data.symbol = 'ETH'
          }
          return data
        })
    )

    return bulkResults

    // calculate percentage changes and daily changes
  } catch (e) {
    console.log('getTopTokens error:',e)
    return
  }
}

export async function useFetchTokensInfo() {
  const dispatch = useDispatch()
  useEffect(() => {
    getEtherPrice()
      .then(async data => {
        const [topTokens] = await Promise.all([getTopTokens(data.now, data.oneDayAgo)])
        topTokens && dispatch(UPDATE_TOP_TOKENS(topTokens))
      })
      .catch(console.error)
  }, [dispatch])
}

const getTokenChartData = async (tokenAddress: string) => {
  let data: any[] = []
  const utcEndTime = dayjs.utc()
  const utcStartTime = utcEndTime.subtract(1, 'year')
  const startTime = utcStartTime.startOf('minute').unix() - 1

  try {
    let allFound = false
    let skip = 0
    while (!allFound) {
      const result = await client.query({
        query: GraphCommand.queryTokenChart(),
        variables: {
          tokenAddr: tokenAddress,
          skip
        },
        fetchPolicy: 'cache-first'
      })
      if (result.data.tokenDayDatas.length < 1000) {
        allFound = true
      }
      skip += 1000
      data = data.concat(result.data.tokenDayDatas)
    }
    const dayIndexSet = new Set()
    const dayIndexArray: any[] = []
    const oneDay = 24 * 60 * 60
    data.forEach((dayData, i) => {
      // add the day index to the set of days
      dayIndexSet.add((data[i].date / oneDay).toFixed(0))
      dayIndexArray.push(data[i])
      dayData.dailyVolumeUSD = parseFloat(dayData.dailyVolumeUSD)
    })

    // fill in empty days
    let timestamp = data[0] && data[0].date ? data[0].date : startTime
    let latestLiquidityUSD = data[0] && data[0].totalLiquidityUSD
    let latestPriceUSD = data[0] && data[0].priceUSD
    let latestPairDatas = data[0] && data[0].mostLiquidPairs
    let index = 1
    while (timestamp < utcEndTime.startOf('minute').unix() - oneDay) {
      const nextDay = timestamp + oneDay
      const currentDayIndex = (nextDay / oneDay).toFixed(0)
      if (!dayIndexSet.has(currentDayIndex)) {
        data.push({
          date: nextDay,
          dayString: nextDay,
          dailyVolumeUSD: 0,
          priceUSD: latestPriceUSD,
          totalLiquidityUSD: latestLiquidityUSD,
          mostLiquidPairs: latestPairDatas
        })
      } else {
        latestLiquidityUSD = dayIndexArray[index].totalLiquidityUSD
        latestPriceUSD = dayIndexArray[index].priceUSD
        latestPairDatas = dayIndexArray[index].mostLiquidPairs
        index = index + 1
      }
      timestamp = nextDay
    }
    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))
  } catch (e) {
    console.log(e)
  }
  return data
}

export function TokenChartData(tokenAddress: string) {
  return getTokenChartData(tokenAddress)
}

const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  ALL_TIME: 'All time'
}
async function useLatestBlock() {
  try {
    const res = await HealthClient.query({
      query: HealthCommand.queryHealth()
    })
    return res.data.indexingStatusForCurrentVersion.chains[0].latestBlock.number
  } catch (e) {
    console.log(e)
  }
}
async function splitQuery(
  query: any,
  localClient: ApolloClient<any>,
  vars: any[],
  list: any[],
  skipCount = 100
): Promise<any> {
  let fetchedData = {}
  let allFound = false
  let skip = 0

  while (!allFound) {
    let end = list.length
    if (skip + skipCount < list.length) {
      end = skip + skipCount
    }
    const sliced = list.slice(skip, end)
    const result = await localClient.query({
      query: query(...vars, sliced),
      fetchPolicy: 'cache-first'
    })
    fetchedData = {
      ...fetchedData,
      ...result.data
    }
    if (Object.keys(result.data).length < skipCount || skip + skipCount > list.length) {
      allFound = true
    } else {
      skip += skipCount
    }
  }

  return fetchedData
}
async function getIntervalTokenData(
  tokenAddress: string,
  startTime: number,
  interval = 3600,
  latestBlock: number | string
) {
  const utcEndTime = dayjs.utc()
  let time = startTime

  // create an array of hour start times until we reach current hour
  // buffer by half hour to catch case where graph isnt synced to latest block
  const timestamps = []
  while (time < utcEndTime.unix()) {
    timestamps.push(time)
    time += interval
  }

  // backout if invalid timestamp format
  if (timestamps.length === 0) {
    return []
  }

  // once you have all the timestamps, get the blocks for each timestamp in a bulk query
  let blocks
  try {
    blocks = await getBlocksFromTimestamps(timestamps, 100)
    // catch failing case
    if (!blocks || blocks.length === 0) {
      return []
    }
    // return []
    if (latestBlock) {
      blocks = blocks.filter(({ number }) => {
        return Number(number) <= Number(latestBlock)
      })
    }

    const result = await splitQuery(GraphCommand.pricesByBlock, SwapInfoClient, [tokenAddress], blocks, 50)
    // format token ETH price results
    const values = []
    for (const row in result) {
      const timestamp = row.split('t')[1]
      const derivedETH = parseFloat(result[row]?.derivedETH)
      if (timestamp) {
        values.push({
          timestamp,
          derivedETH
        })
      }
    }

    // go through eth usd prices and assign to original values array
    let index = 0
    for (const brow in result) {
      const timestamp = brow.split('b')[1]
      if (timestamp) {
        // values[index].priceUSD = result[brow].ethPrice * values[index].derivedETH
        values[index] = {
          ...values[index],
          priceUSD: result[brow].ethPrice * values[index].derivedETH
        }
        index += 1
      }
    }

    const formattedHistory = []

    // for each hour, construct the open and close price
    for (let i = 0; i < values.length - 1; i++) {
      formattedHistory.push({
        timestamp: values[i].timestamp,
        open: values[i].priceUSD,
        close: values[i + 1].priceUSD
      })
    }

    return formattedHistory
  } catch (e) {
    console.log(e)
    console.log('error fetching blocks')
    return []
  }
}
export async function TokenPriceData(tokenAddress: string, timeWindow: string, interval = 3600) {
  const latestBlock = await useLatestBlock()

  const currentTime = dayjs.utc()
  const windowSize = timeWindow === timeframeOptions.MONTH ? 'month' : 'week'
  const startTime =
    timeWindow === timeframeOptions.ALL_TIME
      ? 1589760000
      : currentTime
          .subtract(1, windowSize)
          .startOf('hour')
          .unix()
  const data = await getIntervalTokenData(tokenAddress, startTime, interval, latestBlock)

  return data
}

async function TokenTransactions(allPairsFormatted: any[]) {
  let transactions = {}
  try {
    const result = await client.query({
      query: GraphCommand.queryTokenTransactions(),
      variables: {
        allPairs: allPairsFormatted
      },
      fetchPolicy: 'cache-first'
    })
    transactions = {
      mints: result.data.mints,
      burns: result.data.burns,
      swaps: result.data.swaps
    }
  } catch (e) {
    console.log(e)
  }
  return transactions
}

export async function getTokenTransactions(pairsList: any[]) {
  const allPairsFormatted = pairsList.map(pair => pair.id)
  const transactions = await TokenTransactions(allPairsFormatted)
  return transactions
}