import React, { useState, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { transparentize } from 'polished'
import { Search as SearchIcon, X } from 'react-feather'
import Row from '../../../../components/Row'
import CoinLogo from '../../../../components/CoinLogo'
import { useMedia } from 'react-use'
import { Text } from 'rebass'
import { useChartActionHandler } from '../../../../state/pair-chart/hooks'
import { usePairs } from '../../../../state/global/hooks'
import { useTokens } from '../../../../state/exchange/hooks'
import { PairData, PairUtils } from '../../../../state/exchange/entities/pair_entity'
import { isEthAddress } from '../../../../utils'
import { LogoUtils } from '../../../../fuck/logoUtils'
import FormattedName from './FormattedName'
interface WrapperInter {
  theme?: any
  small?: any
  open?: boolean
  large?: boolean
  hide?: boolean
  [propName: string]: any
}

interface MenuSwap {
  hide: boolean
  [propName: string]: any
}

const TextWrapper = styled(Text)`
  color: ${({ color, theme }: WrapperInter) => theme[color]};
`

const Container = styled.div`
  height: 48px;
  z-index: 2;
  position: relative;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 16px;
  border-radius: 12px;
  background: #ffffff;
  border-bottom-right-radius: ${({ open }: WrapperInter) => (open ? '0px' : '12px')};
  border-bottom-left-radius: ${({ open }) => (open ? '0px' : '12px')};
  z-index: 9999;
  width: 100%;
  min-width: 300px;
  box-sizing: border-box;
  box-shadow: ${({ open, small }) =>
    !open && !small
      ? '0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04) '
      : 'none'};
  @media screen and (max-width: 500px) {
    background: ${({ theme }) => transparentize(0.4, theme.bg1)};
    box-shadow: ${({ open }) =>
      !open
        ? '0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04) '
        : 'none'};
  }
`

const Input = styled.input`
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  color: ${({ theme }) => theme.text1};
  font-size: ${({ large }: { large: boolean }) => (large ? '20px' : '14px')};

  ::placeholder {
    color: ${({ theme }) => theme.text3};
    font-size: 16px;
  }

  @media screen and (max-width: 640px) {
    ::placeholder {
      font-size: 1rem;
    }
  }
`

const SearchIconLarge = styled(SearchIcon)`
  height: 20px;
  width: 20px;
  margin-right: 0.5rem;
  position: absolute;
  right: 10px;
  pointer-events: none;
  color: ${({ theme }) => theme.text3};
`

const CloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  margin-right: 0.5rem;
  position: absolute;
  right: 10px;
  color: ${({ theme }) => theme.text3};
  :hover {
    cursor: pointer;
  }
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 9999;
  width: 100%;
  top: 50px;
  max-height: 540px;
  overflow: scroll;
  left: 0;
  padding-bottom: 20px;
  background: #ffffff;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.04);
  display: ${({ hide }: MenuSwap) => hide && 'none'};
`

const MenuItem = styled(Row)`
  padding: 1rem;
  font-size: 0.85rem;
  & > * {
    margin-right: 6px;
  }
  :hover {
    cursor: pointer;
    background-color: rgba(252, 133, 95, 0.06);
  }
`
const Heading = styled(Row)`
  padding: 1rem;
  display: ${({ hide }: WrapperInter) => hide && 'none'};
`

const Gray = styled.span`
  color: #888d9b;
`

const Blue = styled.span`
  color: #2172e5;
  :hover {
    cursor: pointer;
  }
`

const ListItem = styled.div`
  text-decoration: none;
  color: inherit;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`

const Search = ({ small }: { small?: boolean }) => {
  const [showMenu, toggleMenu] = useState<boolean>(false)
  const [searchInfo, setSearchInfo] = useState<string>('')
  const [pairsShown, setPairsShown] = useState<number>(3)
  const [tokensShown, setTokensShown] = useState<number>(3)
  const { updateAddress } = useChartActionHandler()
  const history = useHistory()

  const wrapperRef = useRef<any>()
  const menuRef = useRef<any>()

  const below700 = useMedia('(max-width: 700px)')
  const below470 = useMedia('(max-width: 470px)')
  const below410 = useMedia('(max-width: 410px)')
  const below576 = useMedia('(max-width:576px)')
  const pairs: PairData[] = usePairs()
  const tokens = useTokens()
  useEffect(() => {
    if (searchInfo !== '') {
      toggleMenu(true)
    } else {
      toggleMenu(false)
    }
  }, [searchInfo])

  const pairsList = useMemo(() => {
    return pairs.filter((item: PairData) => {
      const symbol0: string = item?.token0?.symbol.toLocaleLowerCase()
      const symbol1: string = item?.token1?.symbol.toLocaleLowerCase()
      return symbol0.indexOf(searchInfo) !== -1 || symbol1.indexOf(searchInfo) !== -1
    })
  }, [pairs, searchInfo])

  const tokensList = useMemo(() => {
    return Object.values(tokens).filter(item => {
      const symbol: string = item?.symbol.toLocaleLowerCase()
      return symbol.indexOf(searchInfo) !== -1
    })
  }, [tokens, searchInfo])
  const handleClick = (e: any) => {
    if (
      !(menuRef.current && menuRef.current.contains(e.target)) &&
      !(wrapperRef.current && wrapperRef.current.contains(e.target))
    ) {
      setPairsShown(3)
      setTokensShown(3)
      toggleMenu(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  return (
    <Container>
      <Wrapper open={showMenu} shadow={false} small={small}>
        <Input
          large={!small}
          type={'text'}
          ref={wrapperRef}
          placeholder={
            small
              ? ''
              : below410
              ? 'Search...'
              : below470
              ? 'Search SashimiSwap...'
              : below700
              ? 'Search pairs and tokens...'
              : 'Search SashimiSwap pairs and tokens...'
          }
          value={searchInfo}
          onChange={event => {
            const val = event.currentTarget.value.toLocaleLowerCase().replace(/\s/g, '')
            setSearchInfo(val)
          }}
          onFocus={() => {
            if (!showMenu) {
              toggleMenu(true)
            }
          }}
        />
        {!showMenu ? <SearchIconLarge /> : <CloseIcon onClick={() => toggleMenu(false)} />}
      </Wrapper>
      <Menu ref={menuRef} hide={!showMenu}>
        <Heading>
          <Gray>Pairs</Gray>
        </Heading>
        <div>
          {pairsList && Object.keys(pairsList).length === 0 && (
            <MenuItem>
              <TextWrapper fontWeight={400} fontSize={14} color={'text1'}>
                No results
              </TextWrapper>
            </MenuItem>
          )}
          {pairsList &&
            pairsList.slice(0, pairsShown).map(pair => {
              return (
                <ListItem
                  key={pair.id}
                  onClick={() => {
                    updateAddress(pair.id)
                    history.push({
                      pathname: `/exchange/swap/${isEthAddress(pair?.token0?.id)}/${isEthAddress(pair?.token1?.id)}`,
                      state: pair.id
                    })
                  }}
                >
                  <MenuItem>
                    <CoinLogo size={below576 ? 14 : 24} url={PairUtils.getPairLogo(pair)} />
                    <span className="ellipsis">{PairUtils.getPairString(pair)} Pair</span>
                  </MenuItem>
                </ListItem>
              )
            })}
          <Heading hide={!(Object.keys(pairsList).length > 3 && Object.keys(pairsList).length >= pairsShown)}>
            <Blue
              onClick={() => {
                setPairsShown(pairsShown + 5)
              }}
            >
              See more...
            </Blue>
          </Heading>
        </div>
        <Heading>
          <Gray>Tokens</Gray>
        </Heading>
        <div>
          {Object.keys(tokensList).length === 0 && (
            <MenuItem>
              <TextWrapper fontWeight={400} fontSize={14} color={'text1'}>
                No results
              </TextWrapper>
            </MenuItem>
          )}
          {tokensList.slice(0, tokensShown).map(token => {
            return (
              <ListItem
                key={token.id}
                onClick={() => {
                  history.push({
                    pathname: `/token/${token?.id}`,
                    state: token.id
                  })
                }}
              >
                <MenuItem>
                  <CoinLogo size={below576 ? 14 : 24} url={[LogoUtils.getTokenLogo(token?.id)]} />
                  <FormattedName text={token.name} maxCharacters={20} />
                  (<FormattedName text={token.symbol} maxCharacters={6} />)
                </MenuItem>
              </ListItem>
            )
          })}

          <Heading hide={!(Object.keys(tokensList).length > 3 && Object.keys(tokensList).length >= tokensShown)}>
            <Blue
              onClick={() => {
                setTokensShown(tokensShown + 5)
              }}
            >
              See more...
            </Blue>
          </Heading>
        </div>
      </Menu>
    </Container>
  )
}

export default Search
