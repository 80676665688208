import ExchangeUtils from './exchangeUtils'
import ETH_LOGO from '../assets/images/ethereum-logo.png'

export class LogoUtils {
  public static getTokenLogo(address: string): string {
    if (!address) return ''

    if (address.startsWith('https') || address.startsWith('http')) return address

    address = ExchangeUtils.isAddress(address) ?? ''
    if (!address) return ''

    // Sashimi logo
    if (address?.toLowerCase() === '0xc28e27870558cf22add83540d2126da2e4b464c2') {
      //TODO danie
      return 'https://raw.githubusercontent.com/SashimiProject/token-list/master/assets/SASHIMI.png'
    }

    if (address === '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2') {
      return ETH_LOGO
    }

    // hard coded fixes for trust wallet api issues
    if (address?.toLowerCase() === '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb') {
      address = '0x42456d7084eacf4083f1140d3229471bba2949a8'
    }

    if (address?.toLowerCase() === '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f') {
      address = '0xc011a72400e58ecd99ee497cf89e3775d4bd732f'
    }

    return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`
  }
}
