import React from 'react';
import { Row, Col, Progress } from 'antd'
import BigNumber from 'bignumber.js'
import { nFormatter } from './common/utils';
import SymbolCoinIcon from './components/SymbolCoinIcon'
import { ISupplyRecord } from './types'
import CustomTooltip from './components/CustomTooltip'

type renderType = 'borrowing' | 'borrowingMarkets'

interface InnerCardProps {
  supplyRecord: ISupplyRecord
  renderType: renderType
  handleCardClick: (e: React.MouseEvent<Element, MouseEvent>) => void
  limit: BigNumber
  willRemovedToken?: boolean
}

const BorrowInnerCard: React.FC<InnerCardProps> = ({ supplyRecord, renderType, handleCardClick, limit, willRemovedToken = false }) => {
  const { symbol, borrowRate, borrowBalance, sashimiApyOfBorrow, borrowBalanceInTokenUnit = new BigNumber(0), marketType, walletBalance, liquidity, underlyingAmount, underlyingAddress } = supplyRecord;
  return <div className="item" onClick={(e) => handleCardClick(e)}
    data-symbol={symbol}
    data-markettype={marketType}
  >
    <div className="coin-info">
      <SymbolCoinIcon size={25} tokenSymbol={symbol} warningHint={willRemovedToken} tokenAddress={underlyingAddress} />
      {willRemovedToken && <CustomTooltip title={'Only "withdraw" and "repay" are enabled, please repay.'} type={'warning'} />}
    </div>
    <Row justify='space-between' className="num-info">
      <Col span={12}>
        <p>APY</p>
        <h2>{borrowRate.toFixed(4)}%</h2>
      </Col>
      <Col span={12}>
        <p>Farming APY</p>
        <h2>{sashimiApyOfBorrow.toFixed(4)}%</h2>
      </Col>
    </Row>
    {renderType === 'borrowing' ? <>
      <Row justify='space-between' className="num-info">
        <Col span={12}>
          <p>Balance</p>
          <h2>$ {new BigNumber(borrowBalance || 0).toFixed(2)}</h2>
        </Col>
        <Col span={12}>
          <p style={{ visibility: 'hidden' }}>just placeholders</p>
          <h2>{nFormatter(borrowBalanceInTokenUnit.toNumber(), 4)} {symbol}</h2>
        </Col>
      </Row>
      <Row className="num-info">
        <p>% of Limited</p>
        <Progress
          strokeColor="#d45d02"
          percent={
            // modify xjz
            parseFloat(
              new BigNumber(borrowBalance || 0)
                .dividedBy(limit)
                .times(100)
                .toFixed(2)
            )
          }
          size="small"
        />
      </Row>
    </> : (<>
      <Row justify='space-between' className='num-info'>
        <Col span={24}>
          <p>Wallet</p>
          <h2>{`${new BigNumber(walletBalance || 0).toFixed(4)} ${symbol}`}</h2>
        </Col>
      </Row>
      <Row justify='space-between' className='num-info'>
        <Col span={12}>
          <p>Liquidity</p>
          <h2>$ {nFormatter(new BigNumber(liquidity || 0).toNumber())}</h2>
        </Col>
        <Col span={12}>
          <p style={{ visibility: 'hidden' }}>just placeholders</p>
          <h2>{underlyingAmount.lt(1) ? underlyingAmount.toFixed(4) : nFormatter(new BigNumber(underlyingAmount.toFixed(4)).toNumber())} {symbol}</h2>
        </Col>
      </Row>
    </>)}
  </div >
}


export default BorrowInnerCard