import React, { useState, useMemo, useCallback } from 'react'
import { Text } from 'rebass'
import styled from 'styled-components'
import Link from './components/Link'
import Panel from './components/Panel'
import TokenLogo from './components/TokenLogo'
import PairList from './components/PairList'
import { AutoRow, RowBetween, RowFixed } from './components/Row'
import Column, { AutoColumn } from '../../components/Column'
import { ButtonLight, ButtonDark } from './components/ButtonStyled'
import TxnList from './components/TxnList'
import TokenChart from './components/TokenChart'
import { BasicLink } from './components/Link'
import Search from '../Overview/components/Search'
import { localNumber } from './utils'
import { TYPE } from '../../theme'
import { useColor } from '../../hooks/useColor'
import CopyHelper from './components/Copy'
import { useMedia } from 'react-use'
import { useEffect } from 'react'
import { PageWrapper, ContentWrapper } from './components'
import FormattedName from './components/FormattedName'
import BalanceUtils from '../../fuck/formatBalance'
import { formattedPercent } from './utils'
import { useTokens } from '../../state/tokens/hooks'
import { useHistory } from 'react-router-dom'
import { usePairs } from '../../state/global/hooks'
import { Loader } from './components/Loader'
import { getTokenTransactions } from '../../state/tokens/hooks'
import { isEthAddress } from '../../utils'
import { useCurrency, useDefalutTokenList } from '../../hooks/Tokens'
import TokenWarningModal from '../../components/TokenWarningModal'
import { useActiveWeb3React } from '../../hooks'

const DashboardWrapper = styled.div`
  width: 100%;
`

const PanelWrapper = styled.div`
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content;
  gap: 6px;
  display: inline-grid;
  width: 100%;
  align-items: start;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    align-items: stretch;
    > * {
      grid-column: 1 / 4;
    }

    > * {
      &:first-child {
        width: 100%;
      }
    }
  }
`

const TokenDetailsLayout = styled.div`
  display: inline-grid;
  width: 100%;
  grid-template-columns: auto auto auto 1fr;
  column-gap: 30px;
  align-items: start;

  &:last-child {
    align-items: center;
    justify-items: end;
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    align-items: stretch;
    > * {
      grid-column: 1 / 4;
      margin-bottom: 1rem;
    }

    &:last-child {
      align-items: start;
      justify-items: start;
    }
  }
`

function TokenPage() {
  const history = useHistory()
  // const pairAddress: string = (history.location.state as string) || '0x490ccb3c835597ff31e525262235487f9426312b'
  let tokenAddress = history.location.state

  const tokens = useTokens()
  if (!tokenAddress) {
    const urlToken = history.location.pathname.split('/')[2]
    if (tokens[urlToken]) {
      tokenAddress = urlToken
    } else {
      history.replace({ pathname: '/pairs' })
    }
  }

  const tokenData = tokens[tokenAddress]
  const symbol = tokenData?.symbol
  const id = tokenData?.id
  const name = tokenData?.name
  const priceUSD = tokenData?.priceUSD
  const oneDayVolumeUSD = tokenData?.oneDayVolumeUSD
  const totalLiquidityUSD = tokenData?.totalLiquidityUSD
  const volumeChangeUSD = tokenData?.volumeChangeUSD
  const oneDayVolumeUT = tokenData?.oneDayVolumeUT
  const volumeChangeUT = tokenData?.volumeChangeUT
  const priceChangeUSD = tokenData?.priceChangeUSD
  const liquidityChangeUSD = tokenData?.liquidityChangeUSD
  const oneDayTxns = tokenData?.oneDayTxns
  const txnChange = tokenData?.txnChange
  const [transactions, setTransactions] = useState()
  const { chainId } = useActiveWeb3React()
  const defaultTokenList = useDefalutTokenList()
  const [dismissTokenWarning, setDismissTokenWarning] = useState(false)
  // defaultToken don't warning
  const Token = useCurrency(id)
  const urlLoadedTokens = useMemo(
    () => [Token]?.filter(c => c && !(defaultTokenList[chainId || 1]?.[c.address] ?? false)) ?? [],
    [defaultTokenList, chainId, Token]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // // detect color from token
  const backgroundColor = useColor(id, symbol)

  const allPairs = usePairs()
  const pairsList = useMemo(() => {
    return allPairs.filter(item => {
      const token0 = item?.token0?.id
      const token1 = item?.token1?.id
      return token0 === tokenAddress || token1 === tokenAddress
    })
  }, [allPairs, tokenAddress])

  const transactionsFn = useMemo(async () => {
    return await getTokenTransactions(pairsList)
    // const
  }, [pairsList])
  useEffect(() => {
    let noSet = true
    transactionsFn.then(data => {
      if (noSet) setTransactions(data)
    })
    return () => {
      noSet = false
    }
  }, [transactionsFn])

  // // all transactions with this token
  // // // price
  const price = priceUSD ? BalanceUtils.formattedNum(priceUSD, true) : ''
  const priceChange = priceChangeUSD ? formattedPercent(priceChangeUSD) : ''

  // volume
  const volume =
    oneDayVolumeUSD || oneDayVolumeUSD === 0
      ? BalanceUtils.formattedNum(oneDayVolumeUSD === 0 ? oneDayVolumeUT : oneDayVolumeUSD, true)
      : oneDayVolumeUSD === 0
      ? '$0'
      : '-'

  // // mark if using untracked volume
  const [usingUtVolume, setUsingUtVolume] = useState(false)
  useEffect(() => {
    setUsingUtVolume(oneDayVolumeUSD === 0 ? true : false)
  }, [oneDayVolumeUSD])

  const volumeChange = formattedPercent(!usingUtVolume ? volumeChangeUSD : volumeChangeUT)

  // liquidity
  const liquidity = totalLiquidityUSD
    ? BalanceUtils.formattedNum(totalLiquidityUSD, true)
    : totalLiquidityUSD === 0
    ? '$0'
    : '-'
  const liquidityChange = formattedPercent(liquidityChangeUSD)

  // // transactions
  const txnChangeFormatted = formattedPercent(txnChange)

  const below1080 = useMedia('(max-width: 1080px)')
  const below600 = useMedia('(max-width: 600px)')
  const below500 = useMedia('(max-width: 500px)')

  // // format for long symbol
  const LENGTH = below1080 ? 10 : 16
  const formattedSymbol = symbol?.length > LENGTH ? symbol.slice(0, LENGTH) + '...' : symbol
  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [])

  return (
    <>
      <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />
      <PageWrapper>
        <ContentWrapper>
          <RowBetween style={{ flexWrap: 'wrap', alingItems: 'start' }}>
            <AutoRow align="flex-end" style={{ width: 'fit-content' }}>
              <TYPE.body>
                <BasicLink to="/pairs">{'Tokens '}</BasicLink>→ {symbol}
                {'  '}
              </TYPE.body>
              <Link
                style={{ width: 'fit-content' }}
                color={backgroundColor}
                external
                href={'https://etherscan.io/address/' + tokenAddress}
              >
                <Text style={{ marginLeft: '.15rem' }} fontSize={'14px'} fontWeight={400}>
                  ({tokenAddress ? tokenAddress.slice(0, 8) + '...' + tokenAddress.slice(36, 42) : ''})
                </Text>
              </Link>
            </AutoRow>
            {!below600 && (
              <div style={{ zIndex: '3' }}>
                <Search small={true} />
              </div>
            )}
          </RowBetween>
          <DashboardWrapper style={{ marginTop: below1080 ? '0' : '1rem' }}>
            <RowBetween style={{ flexWrap: 'wrap', marginBottom: '2rem', alignItems: 'flex-start' }}>
              <RowFixed style={{ flexWrap: 'wrap' }}>
                <RowFixed style={{ alignItems: 'baseline' }}>
                  <TokenLogo address={tokenAddress} size="32px" style={{ alignSelf: 'center' }} />
                  <TYPE.main fontSize={below1080 ? '1.5rem' : '2rem'} fontWeight={500} style={{ margin: '0 1rem' }}>
                    <RowFixed gap="6px">
                      <FormattedName text={name ? name + ' ' : ''} maxCharacters={16} style={{ marginRight: '6px' }} />{' '}
                      {formattedSymbol ? `(${formattedSymbol})` : ''}
                    </RowFixed>
                  </TYPE.main>{' '}
                  {!below1080 && (
                    <>
                      <TYPE.main fontSize={'1.5rem'} fontWeight={500} style={{ marginRight: '1rem' }}>
                        {price}
                      </TYPE.main>
                      {priceChange}
                    </>
                  )}
                </RowFixed>
              </RowFixed>
              <span>
                <RowFixed ml={below500 ? '0' : '2.5rem'} mt={below500 ? '1rem' : '0'}>
                  {/* <Link href={getPoolLink(tokenAddress)} target="_blank"> */}
                  <ButtonLight
                    onClick={() => {
                      history.push({
                        pathname: `/exchange/add/${isEthAddress(tokenAddress)}/ETH`
                      })
                    }}
                  >
                    + Add Liquidity
                  </ButtonLight>
                  {/* </Link> */}
                  {/* <Link href={getSwapLink(tokenAddress)} target="_blank"> */}
                  <ButtonDark
                    ml={'.5rem'}
                    mr={below1080 && '.5rem'}
                    onClick={() => {
                      history.push({
                        pathname: `/exchange/swap/${isEthAddress(tokenAddress)}/ETH`
                      })
                    }}
                  >
                    Trade
                  </ButtonDark>
                  {/* </Link> */}
                </RowFixed>
              </span>
            </RowBetween>
            <>
              <PanelWrapper style={{ marginTop: below1080 ? '0' : '1rem' }}>
                {below1080 && price && (
                  <Panel>
                    <AutoColumn gap="20px">
                      <RowBetween>
                        <TYPE.main>Price</TYPE.main>
                        <div />
                      </RowBetween>
                      <RowBetween align="flex-end">
                        {' '}
                        <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={500}>
                          {price}
                        </TYPE.main>
                        <TYPE.main>{priceChange}</TYPE.main>
                      </RowBetween>
                    </AutoColumn>
                  </Panel>
                )}
                <Panel>
                  <AutoColumn gap="20px">
                    <RowBetween>
                      <TYPE.main>Total Liquidity</TYPE.main>
                      <div />
                    </RowBetween>
                    <RowBetween align="flex-end">
                      <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={500}>
                        {liquidity}
                      </TYPE.main>
                      <TYPE.main>{liquidityChange}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </Panel>
                <Panel>
                  <AutoColumn gap="20px">
                    <RowBetween>
                      <TYPE.main>Volume (24hrs) {usingUtVolume && '(Untracked)'}</TYPE.main>
                      <div />
                    </RowBetween>
                    <RowBetween align="flex-end">
                      <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={500}>
                        {volume}
                      </TYPE.main>
                      <TYPE.main>{volumeChange}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </Panel>

                <Panel>
                  <AutoColumn gap="20px">
                    <RowBetween>
                      <TYPE.main>Transactions (24hrs)</TYPE.main>
                      <div />
                    </RowBetween>
                    <RowBetween align="flex-end">
                      <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={500}>
                        {oneDayTxns ? localNumber(oneDayTxns) : oneDayTxns === 0 ? 0 : '-'}
                      </TYPE.main>
                      <TYPE.main>{txnChangeFormatted}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </Panel>
                <Panel style={{ gridColumn: below1080 ? '1' : '2/4', gridRow: below1080 ? '' : '1/4' }}>
                  <TokenChart address={tokenAddress} color={'#EB591A'} base={priceUSD} />
                </Panel>
              </PanelWrapper>
            </>

            <span>
              <TYPE.main fontSize={'1.125rem'} style={{ marginTop: '3rem' }}>
                Top Pairs
              </TYPE.main>
            </span>
            <Panel
              rounded
              style={{
                marginTop: '1.5rem',
                padding: '1.125rem 0 '
              }}
            >
              {tokenAddress && allPairs ? <PairList address={tokenAddress} pairs={pairsList} /> : <Loader />}
            </Panel>

            <RowBetween mt={40} mb={'1rem'}>
              <TYPE.main fontSize={'1.125rem'}>Transactions</TYPE.main> <div />
            </RowBetween>
            <Panel rounded>{transactions ? <TxnList transactions={transactions} /> : <Loader />}</Panel>
            <>
              <RowBetween style={{ marginTop: '3rem' }}>
                <TYPE.main fontSize={'1.125rem'}>Token Information</TYPE.main>{' '}
              </RowBetween>
              <Panel
                rounded
                style={{
                  marginTop: '1.5rem'
                }}
                p={20}
              >
                <TokenDetailsLayout>
                  <Column>
                    <TYPE.main>Symbol</TYPE.main>
                    <Text style={{ marginTop: '.5rem' }} fontSize={24} fontWeight="500">
                      <FormattedName text={symbol} maxCharacters={12} />
                    </Text>
                  </Column>
                  <Column>
                    <TYPE.main>Name</TYPE.main>
                    <TYPE.main style={{ marginTop: '.5rem' }} fontSize={24} fontWeight="500">
                      <FormattedName text={name} maxCharacters={16} />
                    </TYPE.main>
                  </Column>
                  <Column>
                    <TYPE.main>Address</TYPE.main>
                    <AutoRow align="flex-end">
                      <TYPE.main style={{ marginTop: '.5rem' }} fontSize={24} fontWeight="500">
                        {tokenAddress ? tokenAddress.slice(0, 8) + '...' + tokenAddress.slice(36, 42) : ''}
                      </TYPE.main>
                      <CopyHelper toCopy={tokenAddress} />
                    </AutoRow>
                  </Column>
                  <ButtonLight>
                    <Link external href={'https://etherscan.io/address/' + tokenAddress}>
                      View on Etherscan ↗
                    </Link>
                  </ButtonLight>
                </TokenDetailsLayout>
              </Panel>
            </>
          </DashboardWrapper>
        </ContentWrapper>
      </PageWrapper>
    </>
  )
}

export default TokenPage
