import MetaMask from '../../../assets/images/metamask.png'
import { Button } from 'antd'
import React from 'react'
import { useWalletModalToggle } from '../../../state/application/hooks'

const LoginButton: React.FC = () => {
  const toggleWalletModal = useWalletModalToggle();

  return <Button className="button-full-width button-full-width-login" onClick={toggleWalletModal}>
    <img src={MetaMask} alt="MetaMask" /> Connect With Metamask
  </Button>
}

export default LoginButton;
