import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAllMarkets, getSashimiEarned } from '../utils'
import { useActiveWeb3React } from '../../../hooks'
import { AppState } from '../../../state'
import { batchAddMarket } from '../../../state/markets/actions'
import { useBlockNumber } from '../../../state/application/hooks'
import useInterval from 'react-use/lib/useInterval'
import { updateBalance } from '../../../state/wallet/actions'

export function useRefreshMarkets(hash?: string) {
  const [complete, setComplete] = useState<boolean>(false)
  const lastBlockNumber = useBlockNumber()
  const wallet = useSelector<AppState, AppState['wallet']>(state => state.wallet)
  const { library, chainId = 1 } = useActiveWeb3React()
  const dispatch = useDispatch()
  const allMarkets = useCallback(async () => {
    setComplete(false)
    if (!hash) {
      return
    }
    try {
      const res = await library?.getTransactionReceipt(hash)
      if (res) {
        await getAllMarkets(chainId, library?.provider).then(markets => {
          if (markets) {
            dispatch(
              batchAddMarket({
                chainId,
                markets
              })
            )
          }
        })
        setComplete(true)
      }
    } catch (error) {
      console.log(error)
    }
  }, [library, chainId, hash, dispatch])

  useEffect(() => {
    if (chainId && wallet.connected && hash) {
      allMarkets()
    }
  }, [lastBlockNumber, allMarkets, chainId, hash, wallet.connected])

  return {
    complete
  }
}

export function useSashimiBalance() {
  const { library } = useActiveWeb3React()
  const wallet = useSelector<AppState, AppState['wallet']>(state => state.wallet)
  const dispatch = useDispatch()
  const { chainId, connected, account,loginSettled } = wallet;
  useInterval(
    () => {
      getSashimiEarned({chainId, account: account || ''}, library?.provider).then(r => {
        dispatch(updateBalance(r.toNumber()))
      })
    },
    (loginSettled && chainId && account && connected ? 20000 : null)
  )
  useEffect(() => {
    if (!connected || !account) {
      dispatch(updateBalance(0))
    } else {
      getSashimiEarned({chainId, account}, library?.provider).then(r => {
        dispatch(updateBalance(r.toNumber()))
      })
    }
  }, [chainId, connected, account, dispatch, library])
}
