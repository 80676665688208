import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useMedia } from 'react-use'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { usePairTransactionEntityByAddress } from '../../../../state/exchange/hooks'
import { PairTransactionEntity, TransactionItem } from '../../../../state/exchange/entities/pair_transaction_entity'
import BalanceUtils from '../../../../fuck/formatBalance'
import DateTimeUtils from '../../../../fuck/formatDateTime'
import formatAddress from '../../../../fuck/formatAddress'
import { ETHER_SCAN_PREFIX } from '../../../../constants'

enum _ShowType {
  all,
  swaps,
  mints,
  burns
}
const ITEMS_PER_PAGE = 10
const PaginationStyle = { fontSize: '12px', color: '#FC855F', cursor: 'pointer' }
interface RecordsProps {
  readonly pairAddress: string
}

function getTransactionType(event: string, symbol0: string, symbol1: string) {
  const _symbol0 = symbol0.toLowerCase() === 'weth' ? 'ETH' : symbol0
  const _symbol1 = symbol1.toLowerCase() === 'weth' ? 'ETH' : symbol1
  const formattedS0 = _symbol0?.length > 8 ? _symbol0.slice(0, 7) + '...' : _symbol0
  const formattedS1 = _symbol1?.length > 8 ? _symbol1.slice(0, 7) + '...' : _symbol1
  switch (event) {
    case 'Mint':
      return 'Add ' + formattedS0 + ' and ' + formattedS1
    case 'Burn':
      return 'Remove ' + formattedS0 + ' and ' + formattedS1
    case 'Swap':
      return 'Swap ' + formattedS0 + ' for ' + formattedS1
    default:
      return ''
  }
}

/**
 * 交易对交易记录
 * @param pairAddress
 * @constructor
 */
const Records: React.FC<RecordsProps> = ({ pairAddress }: RecordsProps) => {
  const [page, setPage] = useState<number>(1)
  const [showLists, setShowLists] = useState<any[]>([])
  const [maxPage, setMaxPage] = useState(1)
  const [showType, updateShowType] = useState<_ShowType>(_ShowType.all)
  const pairTransactions: PairTransactionEntity = usePairTransactionEntityByAddress(pairAddress)
  const below1200 = useMedia('(max-width: 1200px)')
  const below1500 = useMedia('(max-width: 1500px)')
  useEffect(() => {
    let showList: TransactionItem[] = []
    if (pairTransactions?.swaps && [_ShowType.all, _ShowType.swaps].includes(showType)) {
      showList = [...showList, ...pairTransactions.swaps]
    }
    if (pairTransactions?.mints && [_ShowType.all, _ShowType.mints].includes(showType)) {
      showList = [...showList, ...pairTransactions.mints]
    }
    if (pairTransactions?.burns && [_ShowType.all, _ShowType.burns].includes(showType)) {
      showList = [...showList, ...pairTransactions.burns]
    }
    showList.sort((a, b) => (a.transaction.timestamp < b.transaction.timestamp ? 1 : -1))

    if (showList.length === 0) {
      setMaxPage(1)
    } else {
      setMaxPage(
        showList.length % ITEMS_PER_PAGE !== 0
          ? Math.floor(showList.length / ITEMS_PER_PAGE) + 1
          : Math.floor(showList.length / ITEMS_PER_PAGE)
      )
    }
    setShowLists(showList)
  }, [pairTransactions, showType])

  // .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
  const showListsOfPage: any[] = useMemo(() => {
    return showLists.slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE) || []
  }, [showLists, page])
  return (
    <ListWrap>
      <div className="list-item">
        <div className="item title">
          <span
            className={showType === _ShowType.all ? 'active' : ''}
            onClick={() => {
              updateShowType(_ShowType.all)
              setPage(1)
            }}
          >
            AII
          </span>
          <span
            className={showType === _ShowType.swaps ? 'active' : ''}
            onClick={() => {
              updateShowType(_ShowType.swaps)
              setPage(1)
            }}
          >
            Swaps
          </span>
          <span
            className={showType === _ShowType.mints ? 'active' : ''}
            onClick={() => {
              updateShowType(_ShowType.mints)
              setPage(1)
            }}
          >
            Adds
          </span>
          <span
            className={showType === _ShowType.burns ? 'active' : ''}
            onClick={() => {
              updateShowType(_ShowType.burns)
              setPage(1)
            }}
          >
            Removes
          </span>
        </div>
        <span className="item">Total Value</span>
        {!below1200 && <span className="item">Token Amount</span>}
        {!below1200 && <span className="item">Token Amount</span>}
        {!below1500 && <span className="item">Account</span>}
        <span className="item">Time</span>
      </div>

      {showListsOfPage &&
        showListsOfPage.map((item: TransactionItem, index) => {
          const __typename: string = item.__typename
          let token0: string = item.pair.token0.symbol
          let token1: string = item.pair.token1.symbol
          let amount0: string = BalanceUtils.formattedNum(item.amount0)
          let amount1: string = BalanceUtils.formattedNum(item.amount1)

          if (__typename?.toLowerCase() === 'swap') {
            const netToken0 = parseFloat(item.amount0In) - parseFloat(item.amount0Out)
            const netToken1 = parseFloat(item.amount1In) - parseFloat(item.amount1Out)

            if (netToken0 < 0) {
              amount0 = BalanceUtils.formattedNum(Math.abs(netToken0))
              amount1 = BalanceUtils.formattedNum(Math.abs(netToken1))
            } else if (netToken1 < 0) {
              token0 = item.pair.token1.symbol
              token1 = item.pair.token0.symbol
              amount0 = BalanceUtils.formattedNum(Math.abs(netToken1))
              amount1 = BalanceUtils.formattedNum(Math.abs(netToken0))
            }
          }

          token0 = token0.toLowerCase() === 'weth' ? 'ETH' : token0
          token1 = token1.toLowerCase() === 'weth' ? 'ETH' : token1

          const amountUSD: string = BalanceUtils.formattedNum(item.amountUSD, true)
          const address: string = formatAddress(item.to ?? item.sender ?? '')
          const addressLink = `${ETHER_SCAN_PREFIX}/address/${item.to}`
          const time: string = DateTimeUtils.formatTime(item.transaction.timestamp)

          return (
            <div className="list-item" key={index}>
              <span className="item">
                <a href={`${ETHER_SCAN_PREFIX}/tx/${item.transaction.id}/`} target="_blank" rel="noreferrer">
                  <strong>{getTransactionType(__typename, token1, token0)}</strong>
                </a>
              </span>
              <span className="item">{amountUSD}</span>
              {!below1200 && (
                <span className="item">
                  {amount1} {token1}
                </span>
              )}
              {!below1200 && (
                <span className="item">
                  {amount0} {token0}
                </span>
              )}
              {!below1500 && (
                <span className="item">
                  <a href={addressLink} target="_blank" rel="noopener noreferrer">
                    {address}
                  </a>
                </span>
              )}
              <span className="item">{time}</span>
            </div>
          )
        })}
      <Pagination>
        <div className="container">
          <LeftOutlined
            onClick={() => {
              const tempPage: number = page - 1
              if (tempPage < 1) return
              setPage(tempPage)
            }}
            style={{ ...PaginationStyle, marginRight: '30px', opacity: `${page === 1 ? '0.5' : 1}` }}
          />
          <span>
            Page {page} of {maxPage}
          </span>
          <RightOutlined
            onClick={() => {
              const tempPage: number = page + 1
              if (tempPage > maxPage) return
              setPage(tempPage)
            }}
            style={{ ...PaginationStyle, marginLeft: '30px', opacity: `${page === maxPage ? '0.5' : 1}` }}
          />
        </div>
      </Pagination>
    </ListWrap>
  )
}

const ListWrap = styled.div`
  .list-item {
    display: flex;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f5f5f5;
    &:first-child {
      height: 60px;
      line-height: 60px;
      color: #9da2ab;
    }
    .item {
      flex: 1;
      text-align: right;
      &:first-child {
        text-align: left;
        flex: 1.2;
      }
      strong {
        color: #fc855f;
        white-space: nowrap;
        overflow: hidden;
      }
      a {
        color: #0091ff;
      }
      &.title {
        display: flex;
        align-items: center;
        span {
          margin-right: 30px;
          color: #9da2ab;
          cursor: pointer;
          &.active {
            color: #131313;
          }
        }
      }
    }
  }
`

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
`
export default Records
