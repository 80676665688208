import { createAction } from '@reduxjs/toolkit'

export interface LpBarApyInfo {
  valueInEth: number //eth估值
  totalSupply: string //发行lp数量
  apy: number //自身年化
}

export interface LpBarInfo {
  lpBarIdAddr: string
  // name: string //@显示名称
  lpAddress: string //lp合约地址
}

export const updateLpBarsInfo = createAction<{ [key: string]: LpBarInfo }>('lpBar/updateLpBarsInfo')
export const updateLpBarApys = createAction<{ [key: string]: LpBarApyInfo }>('lpBar/updateLpBarApys')
