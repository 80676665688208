import React, { useState, useEffect } from 'react'
import CountUp from 'react-countup'

const CountUpAni: React.FC<{ num: number; decimal?: number; max?: number }> = ({
  num,
  decimal = 5,
  max = 10
}: {
  num: number
  decimal?: number
  max?: number
}) => {
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(0)
  useEffect(() => {
    setStart(end)
    setEnd(num)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [num])
  return (
    <CountUp
      start={start}
      end={end}
      decimals={end < 0 ? 4 : end > Math.pow(10, max) ? 0 : decimal}
      duration={1}
      separator=","
    />
  )
}
export default CountUpAni
