import { createReducer } from '@reduxjs/toolkit'
import { SashimiInfo, TokenMarket, updateSashimiInfo, updateTokenMarkets } from './actions'

export interface OverviewState {
  tokenMarkets: TokenMarket
  sashimiInfo: SashimiInfo
}

const initialState: OverviewState = {
  tokenMarkets: {
    now: {},
    oneDayAgo: {}
  },
  sashimiInfo: {
    sashimiOfTotalSupply: '0',
    sashimiOfBurned: '0',
    sashimiOfTreasury: '0'
  }
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateTokenMarkets, (state, action) => {
      state.tokenMarkets = {
        now: {
          ...state.tokenMarkets.now,
          ...action.payload.now
        },
        oneDayAgo: {
          ...state.tokenMarkets.oneDayAgo,
          ...action.payload.oneDayAgo
        }
      }
    })
    .addCase(updateSashimiInfo, (state, action) => {
      state.sashimiInfo = {
        ...state.sashimiInfo,
        ...action.payload
      }
    })
)
