import React, { useState } from 'react'
import styled from 'styled-components'
import { Route, NavLink, Switch } from 'react-router-dom'
import Swap from './components/swap'
import AddLiquidity from './components/AddLiquidity'
import RemoveLiquidity from './components/RemoveLiquidity'
// import { PairData } from '../../../../../state/exchange/entities/pair_entity'
import { isEthAddress } from '../../../../../utils'
// import { useHistory } from 'react-router-dom'
// import { usePairByAddress, usePairs } from '../../../../../state/global/hooks'
import { useSwapState } from '../../../../../state/swap/hooks'
// import {
//   RedirectDuplicateTokenIds,
//   RedirectToAddLiquidity
// } from '../AddLiquidity/redirects'
interface DisPlay {
  display: string
}
interface Title {
  text: string
  routerInfo?: string
}

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
  height: 51px;
  a,
  span {
    font-size: 18px;
    line-height: 51px;
    color: #000;
    background-color: #fbfbfb;
    flex: 1;
    font-weight: 600;
    text-align: center;
    &.active {
      color: #eb591a;
    }
  }
`

const Wrap = styled.div`
  flex: 1;
  min-width: 400px;
  /* box-sizing: border-box; */
  margin-top: ${({ display }: DisPlay) => (display === 'overView' ? '0px' : '72px')};
  /* width: 608px; */
  /* padding: 0 80px 0 110px; */
  border-left: ${({ display }: DisPlay) => (display === 'overView' ? 'none' : '1px solid #d8dadd')};
  overflow: hidden;
  @media screen and (max-width: 1300px) {
    border-left: none;
  }
  @media screen and (max-width: 576px) {
    min-width: 300px;
  }
  .box {
    /* padding: 0 80px 0 110px; */
    padding: ${({ display }: DisPlay) => (display === 'overView' ? '0px' : '0 50px 0 60px')};
    @media screen and (max-width: 576px) {
      padding: 0;
    }
  }
`

const NavRoute: React.FC<DisPlay> = pair => {
  // const history = useHistory()
  // let pairAddress: string = (history.location.state as string) || '0x490ccb3c835597ff31e525262235487f9426312b';
  // const pairs = usePairs();
  // if (!pairAddress) {
  //   const urlPair = history.location.pathname.split('/').slice(3);
  //   if (urlPair.length === 2 && urlPair.every((id)=> isAddress(id) || id.toUpperCase() === 'ETH')) {
  //     const pair = pairs.find((pair) => `${isEthAddress(pair.token0.id)}${isEthAddress(pair.token1.id)}`.toUpperCase() === urlPair.join("").toUpperCase() );
  //     pairAddress = pair?.id || '';
  //   } else {
  //     pairAddress = ''
  //   }
  // }
  const swapState = useSwapState()
  const [activeCount, setCount] = useState<number>(0)
  const currencyIdA: string = swapState?.INPUT?.currencyId ?? ''
  const currencyIdB: string = swapState?.OUTPUT?.currencyId ?? ''
  const PAGEFROM = pair.display === 'overView' ? '/home' : '/exchange'
  const currency = currencyIdA && currencyIdB ? `${isEthAddress(currencyIdA)}/${isEthAddress(currencyIdB)}` : ''
  const titleArr: Title[] = [
    { text: 'Trade', routerInfo: `${PAGEFROM}/swap/${currency}` },
    { text: '+Liquidity', routerInfo: `${PAGEFROM}/add/${currency}` },
    { text: '-Liquidity', routerInfo: `${PAGEFROM}/remove/${currency}` },
  ]
  return (
    <Wrap display={pair.display}>
      <div className="box">
        <Nav>
          {titleArr.map((item: Title, index) => {
            return (
              <NavLink
                key={index}
                to={item.routerInfo ?? '/pairs'}
                className={index === activeCount ? 'active' : ''}
                onClick={() => {
                  setCount(index)
                }}
              >
                {item.text}
              </NavLink>
            )
          })}
        </Nav>
        <Switch>
          <Route exact path={`${PAGEFROM}/swap`} render={props => <Swap {...props} PAGEFROM={PAGEFROM} />} />
          <Route
            exact
            path={`${PAGEFROM}/swap/:currencyIdA/:currencyIdB`}
            render={props => <Swap {...props} PAGEFROM={PAGEFROM} />}
          />
          <Route exact path={`${PAGEFROM}/add`} render={props => <AddLiquidity {...props} PAGEFROM={PAGEFROM} />} />
          <Route
            exact
            path={`${PAGEFROM}/add/:currencyIdA/:currencyIdB`}
            render={props => <AddLiquidity {...props} PAGEFROM={PAGEFROM} />}
          />
          <Route
            exact
            path={`${PAGEFROM}/remove`}
            render={props => <RemoveLiquidity {...props} PAGEFROM={PAGEFROM} />}
          />
          <Route
            exact
            path={`${PAGEFROM}/remove/:currencyIdA/:currencyIdB`}
            render={props => <RemoveLiquidity {...props} PAGEFROM={PAGEFROM} />}
          />
        </Switch>
      </div>
    </Wrap>
  )
}
export default NavRoute
