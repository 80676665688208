import React from 'react'
import { Button } from 'antd'
import { useMedia } from 'react-use'
import styled from 'styled-components'
import MetaMask from '../../assets/images/metamask.png'
import UnlockWallet from '../../components/UnlockWallet'
const AccountButton: React.FC = () => {
  // const below576 = useMedia('(max-width:576px)')
  const below576 = useMedia('(max-width:768px)')
  return (
    <>
      {!below576 && (<ButtonWrap>
        <Button
          className="wallet-btn"
          title="primary"
          style={{
            height: '46px',
            width: '268px',
            backgroundColor: '#fff',
            color: '#EB591A',
            borderRadius: '10px',
            borderColor: '#fff',
            boxShadow: '0px 5px 10px 0px rgba(232, 97, 54, 0.1)',
            fontWeight: 600
          }}
        >
          <img style={{ marginRight: '14px' }} src={MetaMask} alt="MetaMask" /> Connect With Metamask
        </Button>
      </ButtonWrap>)}

      {below576 && (<UnlockWallet icon={false} height={36} width={120}/>)}
    </>
  )
}

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 106px;
  background-color: #f9f9f9;
  img {
    width: 20px;
  }
  .wallet-btn {
    &:hover {
      span {
        color: #fb966a;
      }
    }
  }
`
export default AccountButton
