import React from 'react'
import styled from 'styled-components'
import Card from '../../../../components/Card'
import CardContent from '../../../../components/CardContent'
import NavRoute from './NavRoute'
import { useHistory } from 'react-router-dom'
const TradeSettiong: React.FC = () => {
  const history = useHistory()
  history.push({
    pathname:'/home/swap',
  })
  return (
    <CardWrap>
      <CardContent>
        <NavRoute display='overView'/>
      </CardContent>
    </CardWrap>
  )
}

const CardWrap = styled(Card)`
  flex: 1;
  padding-bottom: 8px;
  padding-top: 8px;
`

export default TradeSettiong
