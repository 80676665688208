/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

const CardContent: React.FC = ({ children }) => <StyledCardContent>{children}</StyledCardContent>

const StyledCardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 30px;
  @media screen and (max-width: 576px) {
    padding: 0 20px;
  }
`

export default CardContent
