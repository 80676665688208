import React, { useEffect, useState } from 'react'
import { useExchangeContract } from '../hooks/useContract'
import { EXCHANGE_DELEGATE_ADDRESS } from '../config/config'
import { TTime } from '../components'
import { getTimeType } from '../utils'
import { useBlockNumber } from '../../../state/application/hooks'

type TState = {
  countdown: {
    startTime: number | string | null,
    endTime: number | string | null,
    depositTimeLimit: number | string | null,
  }
  redeemTimeType?: string | null
  stakeTimeType?: string | null
}

const defaultValue: TState = {
  countdown: {
    startTime: null,
    endTime: null,
    depositTimeLimit: null,
  }
};
export const CompensationContext = React.createContext(defaultValue);

export const CompensationProvider: React.FC = ( { children } ) => {
  const block = useBlockNumber();

  const exchangeContract = useExchangeContract(EXCHANGE_DELEGATE_ADDRESS);
  const [startTime, setStartTime] = useState<TTime>(null);
  const [endTime, setEndTime] = useState<TTime>(null);
  const [depositTimeLimit, setDepositTimeLimit] = useState<TTime>(null);

  const [redeemTimeType, setRedeemTimeType] = useState<string>();
  const [stakeTimeType, setStakeTimeType] = useState<string>();

  useEffect(() => {
    if (!exchangeContract) {
      return;
    }
    const fetchTime = async () => {
      const [startTime, endTime, depositTimespan] = await Promise.all([
        exchangeContract.startTime(),
        exchangeContract.endTime(),
        exchangeContract.depositTimespan(),

      ]);
      const startTimeFormat = startTime.toNumber() *  1000;
      const endTimeFormat = endTime.toNumber() * 1000;
      const depositTimeLimitFormat = depositTimespan.toNumber() * 1000;
      // const startTimeFormat = 1643582490543;
      // const endTimeFormat = 1643592490543;
      setStartTime(startTimeFormat);
      setEndTime(endTimeFormat);
      setDepositTimeLimit(depositTimeLimitFormat);

      const redeemTimeType = getTimeType(startTimeFormat, endTimeFormat);
      const stakeTimeType = getTimeType(startTimeFormat, startTimeFormat + depositTimeLimitFormat);
      setRedeemTimeType(redeemTimeType);
      setStakeTimeType(stakeTimeType);
    };
    fetchTime();
  }, [exchangeContract]);

  useEffect(() => {
    if (!startTime || !endTime || !depositTimeLimit) {
      return;
    }
    const redeemTimeType = getTimeType(startTime, endTime);
    const stakeTimeType = getTimeType(startTime, +startTime + +depositTimeLimit);
    setRedeemTimeType(redeemTimeType);
    setStakeTimeType(stakeTimeType);
  }, [startTime, endTime, depositTimeLimit, block]);

  return <CompensationContext.Provider value={{
    countdown: {
      startTime: startTime,
      endTime: endTime,
      depositTimeLimit
    },
    stakeTimeType,
    redeemTimeType
  }}>
    {children}
  </CompensationContext.Provider>
};
