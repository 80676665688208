import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SubgraphClient as client, GraphCommand } from '../../api/apollo'
import { TokenEntity } from './entities/token_entity'
import { updateTokens } from './actions'
import { getBlocksFromTimestamps } from '../common/hooks'
import { DocumentNode } from 'graphql'

/**
 * 获取 Token Data 列表
 */
export async function getTokensData(timestamp?: number): Promise<{ [key: string]: TokenEntity }> {
  let graphCommand: DocumentNode

  if (!timestamp) {
    graphCommand = GraphCommand.queryTokens()
  } else {
    const [{ number: historyBlock }] = await getBlocksFromTimestamps([timestamp])
    graphCommand = GraphCommand.queryHistoryTokenData(historyBlock)
  }

  const tokens: TokenEntity[] = await client
    .query({
      query: graphCommand,
      fetchPolicy: 'cache-first'
    })
    .then<TokenEntity[]>(result => result.data.tokens)

  return tokens.reduce((obj: { [key: string]: TokenEntity }, cur: TokenEntity) => {
    if (cur.symbol.toLowerCase() === 'weth') cur.symbol = 'ETH'
    return { ...obj, [cur.id]: cur }
  }, {})
}

export default function Updater(): null {
  const dispatch = useDispatch()

  useEffect(() => {
    getTokensData().then(tokens => dispatch(updateTokens(tokens)))
  }, [dispatch])

  return null
}
