// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { createReducer, nanoid } from '@reduxjs/toolkit'
import { updatePoolInfo, FarmPoolInfo, updateApys, FarmPoolApyInfo } from './actions'

export interface FarmPoolState {
  pools: {
    [key: number]: FarmPoolInfo
  }
  apys: {
    [key: number]: FarmPoolApyInfo
  }
  sashimiPerBlock: string //每个块产出量
  totalAllocPoint: number //池子总份额
}

const initialState: FarmPoolState = { pools: {}, apys: {}, sashimiPerBlock: '0', totalAllocPoint: -1 }

export default createReducer(initialState, builder =>
  builder
    .addCase(updatePoolInfo, (state, { payload }) => {
      state.sashimiPerBlock = payload.sashimiPerBlock
      state.totalAllocPoint = payload.totalAllocPoint
      state.pools = payload.pools
      // console.log('1111---farm-pool updatePoolInfo-----------------,', payload)
    })
    .addCase(updateApys, (state, { payload }) => {
      state.apys = payload
      // console.log('111---farm-pool updateApys----------', payload)
    })
)
