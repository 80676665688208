import { JSBI, Pair, Percent } from '@uniswap/sdk'
// import { darken } from 'polished'
import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components'
import { useTotalSupply } from '../../data/TotalSupply'

import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { ExternalLink, TYPE } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonPrimary, ButtonSecondary, ButtonEmpty } from '../Button'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed } from '../Row'
import { Dots } from '../swap/styleds'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

interface PositionCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
}

export function MinimalPositionCard({ pair, showUnwrapped = false }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
        ]
      : [undefined, undefined]

  return (
    <>
      {userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, JSBI.BigInt(0)) ? (
        // <GreyCard border={border}>
        <AutoColumn gap="12px">
          <FixedHeightRow>
            <RowFixed>
              <Text fontWeight={500} fontSize={16}>
                Your position
              </Text>
            </RowFixed>
          </FixedHeightRow>
          <FixedHeightRow onClick={() => setShowMore(!showMore)}>
            <RowFixed>
              <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
              <Text fontWeight={500} fontSize={20}>
                {currency0.symbol}/{currency1.symbol}
              </Text>
            </RowFixed>
            <RowFixed>
              <Text fontWeight={500} fontSize={20}>
                {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
              </Text>
            </RowFixed>
          </FixedHeightRow>
          <AutoColumn gap="4px">
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                Your pool share:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                {poolTokenPercentage ? poolTokenPercentage.toFixed(6) + '%' : '-'}
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                {currency0.symbol}:
              </Text>
              {token0Deposited ? (
                <RowFixed>
                  <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                    {token0Deposited?.toSignificant(6)}
                  </Text>
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                {currency1.symbol}:
              </Text>
              {token1Deposited ? (
                <RowFixed>
                  <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                    {token1Deposited?.toSignificant(6)}
                  </Text>
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>
          </AutoColumn>
        </AutoColumn>
      ) : (
        // </GreyCard>
        // <LightCard>
        <TYPE.subHeader style={{ textAlign: 'center' }}>
          <span role="img" aria-label="wizard-icon">
            ⭐️
          </span>{' '}
          By adding liquidity you&apos;ll earn 0.3% of all trades on this pair proportional to your share of the pool.
          Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
        </TYPE.subHeader>
        // </LightCard>
      )}
    </>
  )
}

export default function FullPositionCard({ pair }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
        ]
      : [undefined, undefined]

  // const backgroundColor = useColor(pair?.token0)

  return (
    // <StyledPositionCard border={border} bgColor={backgroundColor}>
    //   <CardNoise />
    <AutoColumn gap="12px">
      <FixedHeightRow>
        <RowFixed>
          <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
          <Text fontWeight={500} fontSize={20}>
            {!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}/${currency1.symbol}`}
          </Text>
        </RowFixed>

        <RowFixed gap="8px">
          <ButtonEmpty padding="6px 8px" borderRadius="12px" width="fit-content" onClick={() => setShowMore(!showMore)}>
            {showMore ? (
              <>
                {' '}
                Manage
                <ChevronUp size="20" style={{ marginLeft: '10px' }} />
              </>
            ) : (
              <>
                Manage
                <ChevronDown size="20" style={{ marginLeft: '10px' }} />
              </>
            )}
          </ButtonEmpty>
        </RowFixed>
      </FixedHeightRow>

      {showMore && (
        <AutoColumn gap="8px">
          <FixedHeightRow>
            <Text fontSize={16} fontWeight={500}>
              Your pool tokens:
            </Text>
            <Text fontSize={16} fontWeight={500}>
              {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
            </Text>
          </FixedHeightRow>
          <FixedHeightRow>
            <RowFixed>
              <Text fontSize={16} fontWeight={500}>
                Pooled {currency0.symbol}:
              </Text>
            </RowFixed>
            {token0Deposited ? (
              <RowFixed>
                <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                  {token0Deposited?.toSignificant(6)}
                </Text>
                <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} />
              </RowFixed>
            ) : (
              '-'
            )}
          </FixedHeightRow>

          <FixedHeightRow>
            <RowFixed>
              <Text fontSize={16} fontWeight={500}>
                Pooled {currency1.symbol}:
              </Text>
            </RowFixed>
            {token1Deposited ? (
              <RowFixed>
                <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                  {token1Deposited?.toSignificant(6)}
                </Text>
                <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} />
              </RowFixed>
            ) : (
              '-'
            )}
          </FixedHeightRow>

          <FixedHeightRow>
            <Text fontSize={16} fontWeight={500}>
              Your pool share:
            </Text>
            <Text fontSize={16} fontWeight={500}>
              {poolTokenPercentage ? poolTokenPercentage.toFixed(2) + '%' : '-'}
            </Text>
          </FixedHeightRow>

          <ButtonSecondary padding="8px" borderRadius="8px">
            <ExternalLink
              style={{ width: '100%', textAlign: 'center' }}
              href={`https://uniswap.info/account/${account}`}
            >
              View accrued fees and analytics<span style={{ fontSize: '11px' }}>↗</span>
            </ExternalLink>
          </ButtonSecondary>
          {/*<AutoRow justify="center" marginTop={'10px'}>*/}
          {/*  <ExternalLink href={`https://uniswap.info/pair/${pair.liquidityToken.address}`}>*/}
          {/*    View pool information ↗*/}
          {/*  </ExternalLink>*/}
          {/*</AutoRow>*/}

          <RowBetween marginTop="10px">
            <ButtonPrimary
              padding="8px"
              borderRadius="8px"
              as={Link}
              to={`/exchange/add/${currencyId(currency0)}/${currencyId(currency1)}`}
              width="48%"
            >
              Add
            </ButtonPrimary>
            <ButtonPrimary
              padding="8px"
              borderRadius="8px"
              as={Link}
              width="48%"
              to={`/exchange/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
            >
              Remove
            </ButtonPrimary>
          </RowBetween>
        </AutoColumn>
      )}
    </AutoColumn>
    // </StyledPositionCard>
  )
}
