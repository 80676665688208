import React from 'react'
import { Row, Col } from 'antd';
import defi from '../../../assets/images/logo.svg'
import '../Compensation.less';

const LeftCard: React.FC = () => {
  return (
    <Row justify="center" className="left-container">
      <Col>
        <div>
          <img className="big-logo" src={defi} alt="" />
        </div>
        <div className="big-title">SASHIMI Distribution Plan</div>
        <div className="description">Deposit LP Token to claim SASHIMI</div>
      </Col>
    </Row>
  )
}

export default LeftCard;
