import React from 'react'
import styled from 'styled-components'
import { Route, NavLink, Switch } from 'react-router-dom'
import Swap from '../swap'
import AddLiquidity from '../AddLiquidity'
import RemoveLiquidity from '../RemoveLiquidity'
import { PairData } from '../../../../state/exchange/entities/pair_entity'
import { isEthAddress } from '../../../../utils'
// import {
//   RedirectDuplicateTokenIds,
//   RedirectToAddLiquidity
// } from '../AddLiquidity/redirects'
const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
  height: 46px;
  a {
    font-size: 18px;
    line-height: 46px;
    color: #eb591a;
    background-color: #fbfbfb;
    flex: 1;
    font-weight: 600;
    text-align: center;
    &.active {
      color: #fff;
      background-color: #eb591a;
    }
  }
`

const Wrap = styled.div`
  flex: 1;
  min-width: 400px;
  /* box-sizing: border-box; */
  margin-top: 72px;
  /* width: 608px; */
  /* padding: 0 80px 0 110px; */
  border-left: 1px solid #d8dadd;
  overflow: hidden;
  @media screen and (max-width: 1300px) {
    border-left: none;
  }
  @media screen and (max-width: 576px) {
    min-width: 300px;
  }
  .box {
    /* padding: 0 80px 0 110px; */
    padding: 0 50px 0 60px;
    @media screen and (max-width: 576px) {
      padding: 0;
    }
  }
`
const NavRoute: React.FC<PairData> = pair => {
  const currencyIdA: string = pair?.token0?.id
  const currencyIdB: string = pair?.token1?.id
  return (
    <Wrap>
      <div className="box">
        <Nav>
          <NavLink
            to={`/exchange/swap/${isEthAddress(currencyIdA)}/${isEthAddress(currencyIdB)}`}
            activeClassName="active"
            isActive={(match, location) => location.pathname.includes('/exchange/swap/')}
          >
            Trade
          </NavLink>
          <NavLink
            to={`/exchange/add/${isEthAddress(currencyIdA)}/${isEthAddress(currencyIdB)}`}
            activeClassName="active"
            isActive={(match, location) => location.pathname.includes('/exchange/add/')}
          >
            +Liquidity
          </NavLink>
          <NavLink
            to={`/exchange/remove/${isEthAddress(currencyIdA)}/${isEthAddress(currencyIdB)}`}
            activeClassName="active"
            isActive={(match, location) => location.pathname.includes('/exchange/remove/')}
          >
            -Liquidity
          </NavLink>
        </Nav>
        <Switch>
          <Route exact path="/exchange/swap" component={Swap} />
          <Route exact path="/exchange/swap/:inputCurrency/:outputCurrency" component={Swap} />
          <Route exact path="/exchange/add" component={AddLiquidity} />
          <Route exact path="/exchange/add/:currencyIdA/:currencyIdB" component={AddLiquidity} />
          <Route exact path="/exchange/remove" component={RemoveLiquidity} />
          <Route exact path="/exchange/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
        </Switch>
      </div>
    </Wrap>
  )
}
export default NavRoute
