/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/interface-name-prefix */
import React from 'react';
import styled from 'styled-components';
import { Progress, Row, Col, Button, Statistic } from 'antd'
import BigNumber from 'bignumber.js'
import { useMedia } from 'react-use'
import { useSelector } from 'react-redux';
import { SAFE_MAX } from './common/constants'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useCompound, } from './utils'
import { IGlobalDetail } from './types'
import { AppState } from '../../state'
import Logo from '../../assets/images/logo.svg'

interface IPersonalSourceProps {
  personalSource: IGlobalDetail
}

const LendingHeader: React.FC<IPersonalSourceProps> = ({
  personalSource
}) => {
  const below768 = useMedia('(max-width:768px)')
  const wallet = useSelector<AppState, AppState['wallet']>(state => state.wallet);
  const { balance: originBalance, account, connected } = wallet;
  const balance = (account && connected) ? new BigNumber(originBalance) : new BigNumber(0);
  const compound = useCompound();
  const addTransaction = useTransactionAdder();

  const {
    totalBorrowLimit,
    totalBorrowBalance,
    totalSupplyBalance,
    netApy,
    totalBorrowLimitUsedPercent
  } = personalSource;

  function formatNetRate(netRate: BigNumber) {
    return (
      <Statistic
        title="Net APY"
        value={new BigNumber(netRate).toFixed(2)}
        suffix="%"
        valueStyle={{ fontSize: "16px" }}
      />
    );
  }
  function formatProgress(limit: BigNumber, symbol: string) {
    return `${symbol} ${new BigNumber(limit).toFixed(2, BigNumber.ROUND_CEIL)}`;
  }
  async function handleCollect() {
    const tx = await compound.claimSashimi();
    addTransaction(tx)
  }

  function camelNumber(num: number | BigNumber, precision = 0, prefix?: string) {
    const reg = /(\d)(?=(?:\d{3})+$)/g;
    const [int, decimal] = num.toFixed(precision).split('.')
    return (<>
      <StyledInt>{`${prefix || ''}${int.replace(reg, '$1,')}`}</StyledInt><StyledDecimal>{`.${decimal}`}</StyledDecimal>
    </>)
  }

  return <StyledContainer justify= {below768 ? 'start': 'center' }>
    <Col xl={3} sm={10} xs={10}>
      <Progress
        width={below768 ? 120 : 100}
        type='circle'
        strokeColor="#d45d02"
        percent={parseFloat(totalBorrowLimitUsedPercent.toFixed(2))}
        format={() => formatNetRate(netApy)}
      />
    </Col>
    <StyledColumn xl={7} sm={14} xs={14} style={{ display: 'flex' }}>
      {below768 ? <Statistic
        title='Supply Balance'
        value={totalSupplyBalance.toFixed(6)}
        prefix='$'
      /> : <div><StyledLabel>Supply Balance:</StyledLabel> {camelNumber(totalSupplyBalance, 6, '$')}</div>}

      {
        below768 ? <Statistic
          title='Borrow Balance'
          value={totalBorrowBalance.toFixed(6)}
          prefix='$'
        /> : <div><StyledLabel>Borrow Balance:</StyledLabel> {camelNumber(totalBorrowBalance, 6, '$')} </div>
      }
    </StyledColumn>
    <BorrowColumn xl={12} sm={24} xs={24} style={{ display: 'flex' }}>
      <DetailRow>
        <Col><StyledLabel>Borrow Limit:</StyledLabel> {totalBorrowLimitUsedPercent.toFixed(2)}%</Col>
        <Col span={1}></Col>
        <Col span={below768 ? 10 : 13}><Progress
          {...totalBorrowLimitUsedPercent.gte(SAFE_MAX * 100) ? { strokeColor: "#D34A64", status: "exception" } : { strokeColor: "#d45d02", status: "normal" }}
          // strokeColor={totalBorrowLimitUsedPercent.gte(95) ? "#D34A64" : "#d45d02"}
          width={100}
          percent={parseFloat(totalBorrowLimitUsedPercent.toFixed(2))}
          format={() => formatProgress(totalBorrowLimit, '$')}
        // status={totalBorrowLimitUsedPercent.gte(SAFE_MAX * 100) ? 'exception' : 'normal'}
        /></Col>
      </DetailRow>
      <DetailRow>
        <SashimiStyledContainer>
          <StyledEarn>
            <StyledLogo>
              <StyledImg src={Logo} alt="logo" />
            </StyledLogo>
            <div style={{ height: "30px", lineHeight: "30px"}}>
              <StyledLabel>Sashimi Earned: {camelNumber(balance, 6)}</StyledLabel>
            </div>
          </StyledEarn>
          <Button
            type="primary"
            disabled={balance.eq(0)}
            onClick={handleCollect}
          >
            Collect
          </Button>
        </SashimiStyledContainer>
      </DetailRow>
    </BorrowColumn>
  </StyledContainer>
}
const StyledContainer = styled(Row)`
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  @media (max-width: 768px) {
    padding: 10px 5px;
    width: 100%
  }
`;

const StyledColumn = styled(Col)`
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
  @media (max-width: 768px) {
    padding: 0;
  }
`
const BorrowColumn = styled(StyledColumn)`
  padding: 15px 0px 10px;
`
const DetailRow = styled(Row)`
  @media (max-width: 768px) {
    padding: 15px 0 0;
  }
`

const StyledLabel = styled.span`
  font-size: 14px;
  color: rgba(0,0,0,.45);
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
const StyledInt = styled.span`
  font-weight: 500;
  color: rgba(0,0,0,.85);
  font-size: 20px
  @media (max-width: 768px) {
    font-size: 15px;
  }
`
const StyledDecimal = styled.span`
  color: rgba(0,0,0,.85);
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 13px;
  }
`
const SashimiStyledContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-flow: row nowrap;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
  // @media (max-width: 576px) {
  //   flex-flow: column nowrap;
  // }
`;
const StyledLogo = styled.div`
  background-color: rgb(240, 233, 231);
  font-size: 20px;
  height: 35px;
  width: 35px;
  border-radius: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  box-shadow: rgb(226, 214, 207) 4px 4px 8px inset, rgb(247, 244, 242) -6px -6px 12px inset;
`;
const StyledImg = styled.img`
  height: 20px;
`;

const StyledEarn = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: auto;
  }
`;

// const WarningText = styled.span<{
//   color?: string
// }>`
//   color: ${({ color }) => (color ? color : 'inherit')}
// `
export default LendingHeader