import dayjs from 'dayjs'
import { calculateDHM, getTimeType } from '../utils'
import React from 'react'

export function Times (
  startTime: string | number | null,
  endTime: string | number | null,
  timeNow: number,
  ): React.FunctionComponentElement<any> | string {
  const timeType = getTimeType(startTime, endTime);

  if (timeType === 'UNKNOWN' || !startTime || !endTime) {
    return '- d: - h: - m';
  }
  if (timeType === 'ENDED') {
    // return '结束时间：' + dayjs(endTime).format('YYYY-MM-DD HH:mm:ss');
    return 'Ended ' + dayjs(endTime).format('YYYY-MM-DD HH:mm:ss');
  }
  if (timeType === 'TO_START') {
    const time = +startTime - timeNow;
    const {day, hour, minute} = calculateDHM(time);
    return (<span className="number-pink">
      {/*距开始时间：<span>{day}</span> d: <span>{hour}</span> h: <span>{minute}</span> m*/}
      <span>{day}</span> d: <span>{hour}</span> h: <span>{minute}</span> m to start
    </span>);
  }

  const time = +endTime - timeNow;
  const {day, hour, minute} = calculateDHM(time);
  return <span className="number-green">
    {/*距结束时间：<span>{day}</span> d: <span>{hour}</span> h: <span>{minute}</span> m*/}
    <span>{day}</span> d: <span>{hour}</span> h: <span>{minute}</span> m to end {/*{second}*/}
  </span>;
}

export function TimesStart (
  startTime: string | number | null,
  endTime: string | number | null,
  timeNow: number,
): React.FunctionComponentElement<any> | string {
  const timeType = getTimeType(startTime, endTime);

  if (timeType === 'UNKNOWN' || !startTime || !endTime) {
    return '- d: - h: - m';
  }

  if (timeType === 'TO_START') {
    const time = +startTime - timeNow;
    const {day, hour, minute} = calculateDHM(time);
    return (<span className="number-pink">
      {/*距开始时间：<span>{day}</span> d: <span>{hour}</span> h: <span>{minute}</span> m*/}
      <span>{day}</span> d: <span>{hour}</span> h: <span>{minute}</span> m to start
    </span>);
  }

  const time = timeNow - +startTime;
  const {day, hour, minute} = calculateDHM(time);
  return <span className="number-blue">
    Started <span>{day}</span> d: <span>{hour}</span> h: <span>{minute}</span> m
  </span>;
}
