import React from 'react'
import { Image } from 'antd'
import ImgFallBack from '../../assets/images/fallback.png'
import styled from 'styled-components'

interface CoinLogoProps {
  size?: number
  url: string[]
  preview?: boolean
}
const CoinLogo: React.FC<CoinLogoProps> = ({ size, url, preview = true }: CoinLogoProps) => {
  return (
    <LogoWrap size={size ? size : 20}>
      {url.map((item, index) => {
        return (
          <Image className="img" preview={preview} key={index} style={{ zIndex: url.length - index }} src={item} fallback={ImgFallBack} />
        )
      })}
    </LogoWrap>
  )
}

interface LogoWrapProps {
  size?: any
}
const LogoWrap = styled.div<LogoWrapProps>`
  display: flex;
  white-space: nowrap;
  .img {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    position: relative;
    margin-left: -${props => props.size / 4}px;
    box-shadow: rgba(0, 0, 0, 0.075) 0px 6px 10px;
    &:first-child {
      margin-left: 0;
    }
    &.ant-image-error {
      /* background-color: #fff; */
      /* padding: 3px; */
    }
  }
`

export default CoinLogo
