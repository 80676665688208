import { BlockClient as blockClient, BlockCommand, HealthClient as healthClient, HealthCommand } from '../../api/apollo'
import { ApolloClient } from 'apollo-client'

/**
 * 分批次查询
 * @param query
 * @param localClient
 * @param vars
 * @param timestamps
 * @param skipCount
 */
export async function splitQuery(
  query: any,
  localClient: ApolloClient<any>,
  vars: any[],
  timestamps: any[],
  skipCount = 100
): Promise<any> {
  let fetchedData = {}
  let allFound = false
  let skip = 0

  while (!allFound) {
    let end = timestamps.length
    if (skip + skipCount < timestamps.length) {
      end = skip + skipCount
    }
    const sliced = timestamps.slice(skip, end)
    const result = await localClient.query({
      query: query(...vars, sliced),
      fetchPolicy: 'cache-first'
    })
    fetchedData = {
      ...fetchedData,
      ...result.data
    }
    if (Object.keys(result.data).length < skipCount || skip + skipCount > timestamps.length) {
      allFound = true
    } else {
      skip += skipCount
    }
  }

  return fetchedData
}

/**
 * 根据时间戳获取对应的区块信息
 * @param timestamps
 * @param skipCount
 */
export async function getBlocksFromTimestamps(
  timestamps: number[],
  skipCount = 500
): Promise<{ timestamp: string; number: number }[]> {
  if (timestamps?.length === 0) {
    return []
  }

  const fetchedData = await splitQuery(BlockCommand.queryBlockByTimestamps, blockClient, [], timestamps, skipCount)

  const blocks = []
  if (fetchedData) {
    for (const t in fetchedData) {
      if (fetchedData[t].length > 0) {
        blocks.push({
          timestamp: t.split('t')[1],
          number: fetchedData[t][0]['number']
        })
      }
    }
  }
  return blocks
}

/**
 * 获取最新的区块
 */
export async function getLatestBlock(): Promise<number> {
  const res = await healthClient.query({
    query: HealthCommand.queryHealth()
  })
  return res.data.indexingStatusForCurrentVersion.chains[0].latestBlock.number
}
