import React from 'react';
import { Switch, Row, Col } from 'antd'
import { useSelector } from 'react-redux'
import BigNumber from 'bignumber.js'
import { nFormatter } from './common/utils';
import SymbolCoinIcon from './components/SymbolCoinIcon'
import { ISupplyRecord } from './types'
import { AppState } from '../../state';
import CustomTooltip from './components/CustomTooltip'

type renderType = 'supplying' | 'supplyingMarkets'

interface InnerCardProps {
  supplyRecord: ISupplyRecord
  switchCallback?: (entered: any, event: any) => void
  renderType: renderType
  handleCardClick: (e: React.MouseEvent<Element, MouseEvent>) => void
  willRemovedToken?: boolean
}

function stopEvent(_: any, event: any) {
  event.stopPropagation();
  event.preventDefault();
}

const SupplyInnerCard: React.FC<InnerCardProps> = ({ supplyRecord, switchCallback, renderType, handleCardClick, willRemovedToken = false }) => {
  const { connected } = useSelector<AppState, AppState['wallet']>(state => state.wallet)
  const { symbol, supplyRate, supplyBalance, entered, sashimiApyOfSupply, supplyBalanceInTokenUnit = new BigNumber(0), walletBalance, marketType, underlyingAddress } = supplyRecord;
  const hiddenSwitch = renderType === 'supplyingMarkets'  &&  ['USDT'].includes(symbol.toUpperCase());

  return <div className="item" onClick={(e) => handleCardClick(e)}
    data-symbol={symbol}
    data-markettype={marketType}
  > 
    <div className="coin-info">
      <SymbolCoinIcon size={25} tokenSymbol={symbol} warningHint={willRemovedToken} tokenAddress={underlyingAddress}/>
      {switchCallback && !hiddenSwitch &&
        <div className="extra-info" onClick={(e) => stopEvent(1, e)}>
          <Switch
            onClick={stopEvent}
            onChange={switchCallback}
            checked={entered}
            disabled={renderType === 'supplyingMarkets' && !connected}
            data-symbol={symbol}
          ></Switch>
          <CustomTooltip title={willRemovedToken ? 'Only "withdraw" and "repay" are enabled, please withdraw.' : 'After turning on the switch, the asset will become a mortgage asset and obtain a borrow limit'} type={willRemovedToken ? 'warning' : 'normal'}/>
        </div>
      }
    </div>
    <Row justify='space-between' className="num-info">
      <Col span={12}>
        <p>APY</p>
        <h2>{supplyRate.toFixed(4)}%</h2>
      </Col>
      <Col span={12}>
        <p>Farming APY</p>
        <h2>{sashimiApyOfSupply.toFixed(4)}%</h2>
      </Col>
    </Row>
    <Row justify='space-between' className="num-info">
      {renderType === 'supplying' ? <>
        <Col span={12}>
          <p>Balance</p>
          <h2>$ {new BigNumber(supplyBalance || 0).toFixed(2)}</h2>
        </Col>
        <Col span={12}>
          <p style={{ visibility: 'hidden' }}>just placeholders</p>
          <h2>{nFormatter(supplyBalanceInTokenUnit.toNumber(), 4)} {symbol}</h2>
        </Col>
      </> : (<Col>
        <p>Wallet</p>
        <h2>{`${new BigNumber(walletBalance || 0).toFixed(4)} ${symbol}`}</h2>
      </Col>)
      }
    </Row>
  </div>
}

export default SupplyInnerCard