import { createAction } from '@reduxjs/toolkit'

export enum LpSourceType {
  LPBAR_FARM,
  VAULT_FARM,
  SASWAP_FARM
  // UNISWAP_FARM
}
export interface FarmPoolApyInfo {
  valueInEth: number //eth估值
  lpStakeAmount: string //池子中lp数量
  apy1: number //自身年化
  apy2: number //lp年化
  lpStakePercent: string
}

export interface FarmPoolInfo {
  pid: number
  name: string //@显示名称
  lpAddress: string //lp合约地址
  sashimiPerBlock: number //每个block产生的sashimi
  lpDecimal: number //lp的精度
  lpSymbol: string //@lp显示名称
  lpCoinUrls: string[] //@lp对应的coin urls, 临时使用
  icon: string //@对应的coin
  lpSourceType: LpSourceType //@标志lp来源
  isInvestment: boolean //@是否在isInvestment
  isHide: boolean //@隐藏
  apyInfo: any,
  advanceLine: boolean  //预上线标识
}

export const updatePoolInfo = createAction<{
  totalAllocPoint: number
  sashimiPerBlock: string
  pools: { [key: number]: FarmPoolInfo }
}>('farm-pool/updatePoolInfo')

export const updateApys = createAction<{ [key: number]: FarmPoolApyInfo }>('farm-pool/updateApys')
