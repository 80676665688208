/**
 * @file user market entrance
 * @author atom-yang
 */
import React, { useMemo, useState, useEffect } from 'react';
import {
  If,
  Then,
  Else
} from 'react-if';
import {
  Divider,
  Modal,
  Alert, message
} from 'antd';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import ListItem from '../ListItem';
import { MarketInfoDefaultProps } from '../../common/constants';
import { useCompound } from '../../utils';
import { addTransaction } from '../../../../state/transactions/actions';
import { IBaseModalProps, IGlobalDetail } from '../../types'
import { useRefreshMarkets } from '../../hooks'

interface IExitMarketProps extends IBaseModalProps { }

function getRemovedLimit(marketInfo: {
  supplyBalance: BigNumber
  collateralFactor: BigNumber
}, limitInfo: IGlobalDetail) {
  const {
    supplyBalance,
    collateralFactor
  } = marketInfo;
  const {
    totalBorrowBalance,
    totalBorrowLimit
  } = limitInfo;
  const marketLimit = new BigNumber(supplyBalance).times(collateralFactor);
  const afterLimit = new BigNumber(totalBorrowLimit).minus(marketLimit);
  const usedPercent = +afterLimit.eq(0) ? new BigNumber(0) : new BigNumber(totalBorrowBalance)
    .dividedBy(afterLimit)
    .times(100);
  const needLiquidate = afterLimit.lt(totalBorrowBalance);
  return {
    afterLimit,
    usedPercent,
    needLiquidate
  };
}

const ExitMarketModal: React.FC<IExitMarketProps> = props => {
  const {
    symbol,
    visible,
    limitInfo,
    supplyBalance,
    collateralFactor,
    onCancel,
    onConfirm,
  } = props;
  const compound = useCompound();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hash, setHash] = useState('');
  const { complete } = useRefreshMarkets(hash);

  useEffect(() => {
    if (complete) {
      setLoading(false)
      onConfirm()
    }
  }, [complete, onConfirm])

  const {
    afterLimit,
    needLiquidate,
    usedPercent
  } = useMemo(() => getRemovedLimit({
    supplyBalance,
    collateralFactor
  }, limitInfo), [
    supplyBalance,
    collateralFactor,
    limitInfo
  ]);
  const {
    totalBorrowLimitUsedPercent,
    totalBorrowLimit
  } = limitInfo;
  async function handleConfirm() {
    if (!needLiquidate) {
      try {
        setLoading(true)
        const tx = await compound.exitMarket(symbol);
        setHash(tx.hash);
        dispatch(addTransaction(tx));
        // onConfirm();
      } catch (e) {
        setLoading(false)
        console.log(e);
        message.error((e.error || e).message || 'Send Transaction failed');
      }
    } else {
      onCancel();
    }
  }
  return (
    <Modal
      visible={visible}
      title={needLiquidate ? 'Collateral Required' : 'Disable As Collateral'}
      // okText={needLiquidate ? 'DISMISS' : <>DISABLE <SymbolStyle>{symbol}</SymbolStyle></>}
      okText={needLiquidate ? 'DISMISS' : `DISABLE ${symbol}`}
      okButtonProps={{ loading }}
      destroyOnClose
      maskClosable={!loading}
      onOk={handleConfirm}
      onCancel={onCancel}
    >
      <If condition={!!needLiquidate}>
        <Then>
          <Alert
            type="info"
            message="Notice"
            description="The asset needs to be used to support the borrowed asset,
            either to repay the borrowed asset or to provide another asset as collateral."
          />
        </Then>
        <Else>
          <Alert
            type="warning"
            message="Notice"
            description="The asset will no longer be used to increase your borrowing limit
            and can not be mortgaged in liquidation."
          />
        </Else>
      </If>
      <Divider />
      <ListItem
        title="Borrow Limit"
        desc={`$${totalBorrowLimit.toFixed(2)} -> $${afterLimit.toFixed(2)}`}
      />
      <Divider />
      <ListItem
        title="Borrow Limit Used"
        desc={`${totalBorrowLimitUsedPercent.toFixed(2)}% -> ${needLiquidate
          ? 'Liquidation' : `${usedPercent.toFixed(2)}%`}`}
      />
    </Modal>
  );
};

ExitMarketModal.defaultProps = {
  ...MarketInfoDefaultProps
};

// const SymbolStyle = styled.span`
//   color: #00FFFF;
// `
export default React.memo(ExitMarketModal);
