import React from 'react'
import { useMedia } from 'react-use'
import { Button } from 'antd'
import { useWalletModalToggle } from '../../state/application/hooks'
const UnlockWallet = ({ icon = true, width = 200, height = 46 }) => {
  const toggleWalletModal = useWalletModalToggle()
  // const below576 = useMedia('(max-width:576px)')
  const below576 = useMedia('(max-width:768px)')
  return (
    <Button
      onClick={toggleWalletModal}
      type="primary"
      style={{
        width: `${width}px`,
        marginRight: below576 ? '5px': 'auto',
        height: `${height}px`,
        borderRadius: `${!below576 ? 10 : 2}px`,
        boxShadow: '0px 5px 10px 0px rgba(232, 97, 54, 0.3)'
      }}
    >
      {icon ? (
        <span role="img" aria-label="lock">
          🔒{' '}
        </span>
      ) : null}{' '}
      Unlock Wallet
    </Button>
  )
}
export default UnlockWallet
