import { useEffect } from 'react'
import { useHistory } from 'react-router'
const checkHashRout = (hash: string) => {
  if (!hash.includes('#/')) hash = hash.replace('#', '#/')
  const hashS = hash.split('/')
  const path = hashS[1]
  if (path === 'add' || path === 'remove') {
    return `/exchange/${path}/${hashS[2]}/${hashS[3]}`
  } else if (path.includes('swap')) {
    const i = path.indexOf('?')
    if (i !== -1) {
      const params = path.slice(i + 1).split('&')
      let name = ''
      params.forEach(i => {
        const v = i.split('=')[1]
        name = name + `/${v}`
      })
      return `/exchange/swap${name}`
    }
  }
  return undefined
}
export const useDistribution = () => {
  const history = useHistory()
  useEffect(() => {
    const pathname: string = history.location.pathname as string
    if (pathname.includes('app')) {
      try {
        const hash: string = history.location.hash as string
        if (hash.includes('#')) {
          const pathname = checkHashRout(hash)
          if (pathname) history.replace({ pathname })
        }
      } catch (error) {}
    }
  }, [history])
}
