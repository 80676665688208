import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateSashimiInfo, updateTokenMarkets } from './actions'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useDebounce from '../../hooks/useDebounce'
import { TokenEntity } from '../exchange/entities/token_entity'
import { useTokens } from '../exchange/hooks'
import { getTokensData } from '../exchange/updater'
import { Contract } from 'ethers'
import { useSashimiContract } from '../../hooks/useContract'
import { BigNumber as EthBigNumber } from '@ethersproject/bignumber/lib/bignumber'
import BigNumber from 'bignumber.js'
import { useBlockNumber } from '../application/hooks'

dayjs.extend(utc)

function convertEthBigNumberToBigNumber(value: EthBigNumber[]): BigNumber[] {
  return value.map(item => item.toString()).map(item => new BigNumber(item))
}

export default function Updater(): null {
  const dispatch = useDispatch()
  const sashimiContract: Contract | null = useSashimiContract()
  const block = useBlockNumber()

  useEffect(() => {
    if (!sashimiContract) return

    Promise.all([
      sashimiContract.functions.totalSupply(),
      sashimiContract.functions.balanceOf('0x1DaeD74ed1dD7C9Dabbe51361ac90A69d851234D'), // Todo: zbinnny masterChef address
      sashimiContract.functions.balanceOf('0x000000000000000000000000000000000000dead'), // Todo: zbinnny burned address
      sashimiContract.functions.balanceOf('0x84ee348617563944ffd4a23843e086a7dc0224f3') // Todo: zbinnny timeLock address
    ])
      .then(convertEthBigNumberToBigNumber)
      .then(([totalSupply, stakedBalance, burnedSashimi, treasuryBalance]) => {
        dispatch(
          updateSashimiInfo({
            sashimiOfTotalSupply: totalSupply.minus(stakedBalance).toString(),
            sashimiOfBurned: burnedSashimi.toString(),
            sashimiOfTreasury: treasuryBalance.toString()
          })
        )
      })
  }, [dispatch, sashimiContract, block])

  const tokens: { [key: string]: TokenEntity } = useTokens()
  const debounceTokens = useDebounce(tokens, 100)

  useEffect(() => {
    if (!tokens || Object.keys(tokens).length === 0) return
    dispatch(
      updateTokenMarkets({
        now: tokens,
        oneDayAgo: {}
      })
    )

    const utcCurrentTime = dayjs()
    const utcOneDayAgo = utcCurrentTime
      .subtract(1, 'day')
      .startOf('minute')
      .unix()
    getTokensData(utcOneDayAgo).then(data => {
      dispatch(
        updateTokenMarkets({
          now: {},
          oneDayAgo: data
        })
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, debounceTokens])

  return null
}
