import { createReducer } from '@reduxjs/toolkit'
import { initialGlobalChartData, initialGlobalStatisticsData, initialPairs, updateEtherPrice, initialTVLChartData } from './actions'
import { PairData } from '../exchange/entities/pair_entity'

export interface GlobalState {
  etherPrice: {
    now: number
    oneDayAgo: number
    change: number
  }
  statistics: any
  chartData: any[]
  pairs: { [key: string]: PairData }
  TVLChartData:any[]
}

const initialState: GlobalState = {
  etherPrice: {
    now: 0,
    oneDayAgo: 0,
    change: 0
  },
  statistics: [],
  chartData: [],
  pairs: {},
  TVLChartData:[]
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateEtherPrice, (state, { payload }) => {
      state.etherPrice = payload
    })
    .addCase(initialGlobalStatisticsData, (state, action) => {
      state.statistics = action.payload
    })
    .addCase(initialGlobalChartData, (state, action) => {
      state.chartData = action.payload
    })
    .addCase(initialPairs, (state, action) => {
      state.pairs = action.payload
    })
    .addCase(initialTVLChartData, (state, action) => {
      state.TVLChartData = action.payload
    })
)
