import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../index'
import { useEffect } from 'react'
import { LpSourceType } from '../farm-pool/actions'
import { useContract, useMulticallContract, useSashimiRouterContract, useWETHContract } from '../../hooks/useContract'
import LpBarAbi from '../../constants/sashimi/LPBar.json'
import { LpBarApyInfo, LpBarInfo, updateLpBarApys, updateLpBarsInfo } from './actions'
import { BigNumber as BN } from 'bignumber.js'

export function useFetchLpBarInfo() {
  const dispatch = useDispatch()
  const farmPools = useSelector<AppState, AppState['poolInfos']['pools']>(state => state.poolInfos.pools)
  const multicallContract = useMulticallContract()
  //0x0000000000000000000000000000000000000001 无用地址，临时用于构造合约对象
  const lpBarContract = useContract('0x0000000000000000000000000000000000000001', LpBarAbi, false)
  useEffect(() => {
    async function fetchLpBarInfo() {
      if (!multicallContract || !lpBarContract) return
      const lpBarChunk: any[] = []
      let poolEmpty = true
      Object.values(farmPools).forEach(pool => {
        poolEmpty = false
        if (pool.lpSourceType === LpSourceType.LPBAR_FARM) {
          lpBarChunk.push({
            address: pool.lpAddress,
            callData: lpBarContract.interface.encodeFunctionData('lp', [])
          })
        }
      })
      if (poolEmpty) return
      const lpBarsResult = await multicallContract.aggregate(lpBarChunk.map((obj: any) => [obj.address, obj.callData]))
      const lpBarMap: { [key: string]: LpBarInfo } = {}
      for (let index = 0; index < lpBarChunk.length; index++) {
        const obj = lpBarChunk[index]
        const lpAddress = lpBarContract.interface.decodeFunctionResult('lp', lpBarsResult[1][index])
        lpBarMap[obj.address] = {
          lpBarIdAddr: obj.address,
          lpAddress: lpAddress[0]
        }
      }
      dispatch(updateLpBarsInfo(lpBarMap))
    }
    fetchLpBarInfo()
    // dispatch(updateLpBarsInfo({ xx: 0 }))
  }, [dispatch, farmPools, multicallContract, lpBarContract])
}

export function useFetchLpBarApy() {
  const lpBarsInfo = useSelector<AppState, AppState['lpBar']['lpBarsInfo']>(state => state.lpBar.lpBarsInfo)
  const dispatch = useDispatch()
  const multicallContract = useMulticallContract()
  const sashimiRouterContract = useSashimiRouterContract()
  const ethContract = useWETHContract()
  const lpBarContract = useContract('0x0000000000000000000000000000000000000001', LpBarAbi, false)
  useEffect(() => {
    async function fetchLpBarApys() {
      if (!ethContract || !lpBarContract || !multicallContract || !sashimiRouterContract) return

      let lpBarEmpty = true
      const lpQueryChunk: any[] = []
      Object.values(lpBarsInfo).forEach(value => {
        lpBarEmpty = false
        lpQueryChunk.push(
          {
            // lpBar的发行总量 = farm-pool中的总量
            address: value.lpBarIdAddr,
            callData: lpBarContract.interface.encodeFunctionData('totalSupply', [])
          },
          {
            //lp精度
            address: value.lpAddress,
            callData: ethContract.interface.encodeFunctionData('decimals', [])
          },
          {
            //lp总发行
            address: value.lpAddress,
            callData: lpBarContract.interface.encodeFunctionData('totalSupply', [])
          },
          {
            //lp池子中eth价值
            address: ethContract.address,
            callData: ethContract.interface.encodeFunctionData('balanceOf', [value.lpAddress])
          }
        )
      })
      if (lpBarEmpty) return
      const chunkLength = lpQueryChunk.length
      //uni价格查询
      const uniAddress = '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984' //TODO danie
      const uniEthPairAddr = '0xd3d2e2692501a5c9ca623199d38826e513033a17' //TODO danie
      lpQueryChunk.push(
        {
          address: uniAddress,
          callData: ethContract.interface.encodeFunctionData('decimals', [])
        },
        {
          address: uniAddress,
          callData: ethContract.interface.encodeFunctionData('balanceOf', [uniEthPairAddr])
        },
        {
          address: ethContract.address,
          callData: ethContract.interface.encodeFunctionData('balanceOf', [uniEthPairAddr])
        }
      )
      const lpBarResult = await multicallContract.aggregate(lpQueryChunk.map((obj: any) => [obj.address, obj.callData]))
      const rData = lpBarResult[1]

      // [uni]的精度
      const decimalsOfUniLp: number = ethContract.interface.decodeFunctionResult('decimals', rData[chunkLength])[0]
      // lp 地址持有的 uni 数量
      const uniLpOwnedUniBalance = ethContract.interface.decodeFunctionResult('balanceOf', rData[chunkLength + 1])[0]
      // lp 地址持有的 eth
      const uniLpOwnedEthBalance = ethContract.interface.decodeFunctionResult('balanceOf', rData[chunkLength + 2])[0]

      const uniPrice: BN = new BN(uniLpOwnedEthBalance.toString())
        .dividedBy(new BN(1e18))
        .dividedBy(new BN(uniLpOwnedUniBalance.toString()).dividedBy(new BN(10).pow(decimalsOfUniLp)))

      const lpBarMap: { [key: string]: LpBarApyInfo } = {}
      const oneChunkLength = 4
      for (let index = 0; index < chunkLength / oneChunkLength; index++) {
        const realIndex: number = index * oneChunkLength
        const lpBar = lpBarsInfo[lpQueryChunk[realIndex].address]

        const decimalsOfLp = new BN(
          ethContract.interface.decodeFunctionResult('decimals', rData[realIndex + 1])[0].toString()
        )

        const totalSupplyOfLpBar = new BN(
          lpBarContract.interface.decodeFunctionResult('totalSupply', rData[realIndex])[0].toString()
        ).dividedBy(new BN(10).pow(decimalsOfLp))

        // [uni lp] 的总发行量
        const totalSupplyOfLp = new BN(
          lpBarContract.interface.decodeFunctionResult('totalSupply', rData[realIndex + 2])[0].toString()
        ).div(new BN(10).pow(decimalsOfLp))

        // [uni lp] 的价值: uniLp池子持有的Eth数量 * 2
        const ethBalanceOfLp = new BN(
          ethContract.interface.decodeFunctionResult('balanceOf', rData[realIndex + 3])[0].toString()
        )
          .div(new BN(10).pow(18))
          .times(2)

        const valueInEth = totalSupplyOfLpBar.dividedBy(totalSupplyOfLp).multipliedBy(ethBalanceOfLp)
        const blockPerYear = new BN(2336000) // 233600: Eth每年产生的块数
        const uniLpPerBlock = new BN(13.5) // 13.5: 每块产生的 uni 数量
        const roi: BN = new BN(uniPrice.toString())
          .times(blockPerYear)
          .times(uniLpPerBlock)
          .dividedBy(new BN(ethBalanceOfLp.toString()))
          .times(100)
        lpBarMap[lpBar.lpBarIdAddr] = {
          valueInEth: valueInEth.toNumber(),
          totalSupply: totalSupplyOfLpBar.toString(),
          apy: roi.toNumber()
        }
      }

      dispatch(updateLpBarApys(lpBarMap))
    }
    fetchLpBarApys()
  }, [dispatch, lpBarsInfo, ethContract, lpBarContract, multicallContract, sashimiRouterContract])
}
