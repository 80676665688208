
import React, { useMemo, useState, useEffect } from 'react'
import { Card, Row } from 'antd'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { useMedia } from 'react-use'
import { SearchOutlined } from '@ant-design/icons'
import SupplyInnerCard from './SupplyInnerCard'
import BorrowInnerCard from './BorrowInnerCard'
import { ISupplyRecord } from './types'
import Unfold from '../../assets/svg/unfold.svg'
import Fold from '../../assets/svg/fold.svg'
import { judgeWillRemoveToken } from './common/utils';
import './index.less';

interface ILendingCardProps {
  title: string
  account: string | undefined
  supplyRecords?: ISupplyRecord[]
  borrowRecords?: ISupplyRecord[]
  switchEntered?: (entered: any, event: any) => void
  handleCardClick: (e: any) => void
  totalBorrowLimit?: BigNumber
}

function judgeRenderArray(dataArray: ISupplyRecord[] | undefined) {
  return dataArray && dataArray.length ? true : false
}

function renderTextElement(text: string) {
  return text ? <TextElement>{text}</TextElement> : null
}

function getTokenSerialNumber(fAddress: string, nAddress: string) {
  const [fOrder, nOrder] = [fAddress, nAddress].map(item => judgeWillRemoveToken(item) ? 99 : 0)
  return fOrder - nOrder
}

const LendingCards: React.FC<ILendingCardProps> = ({
  title, supplyRecords, account,
  borrowRecords, handleCardClick,
  switchEntered, totalBorrowLimit
}) => {
  const below576 = useMedia('(max-width:576px)')
  const [searchInfo, setSearchInfo] = useState<string>('');
  const [unfold, setUnfold] = useState<boolean>(true);
  const [recordStatus, setRecordStatus] = useState(false);

  // const getUserList = (originArray: ISupplyRecord[] | undefined) => {
  //   return originArray ? originArray.filter((item: ISupplyRecord) => {
  //     const symbol: string = item.symbol.toLocaleLowerCase()
  //     return symbol.indexOf(searchInfo) !== -1
  //   }) : []
  // }

  const renderUserList = useMemo(() => {
    if (!searchInfo) {
      return supplyRecords || borrowRecords
    }
    if (recordStatus && supplyRecords) {
      return supplyRecords.filter((item: ISupplyRecord) => {
        const symbol: string = item.symbol.toLocaleLowerCase()
        return symbol.indexOf(searchInfo) !== -1
      })
    }
    if (recordStatus && borrowRecords) {
      return borrowRecords.filter((item: ISupplyRecord) => {
        const symbol: string = item.symbol.toLocaleLowerCase()
        return symbol.indexOf(searchInfo) !== -1
      })
    }
    return []
  }, [supplyRecords, searchInfo, borrowRecords, recordStatus])

  const handleUnfold = (): void => {
    setUnfold(!unfold)
  }

  function judgeTextElement() {
    let text: string = ''
    if (recordStatus) {
      text = searchInfo ? judgeRenderArray(renderUserList) ? '' : 'No More... ' : '';
    } else {
      text = supplyRecords ? 'Not yet supplyed' : 'Not yet borrowed';
    }
    return renderTextElement(text)
  }

  const headStyle = below576 ? { padding: '0 10px' } : {};
  const bodyStyle = below576 ? { padding: 0 } : {};

  useEffect(() => {
    const res = judgeRenderArray(supplyRecords || borrowRecords);
    setRecordStatus(res)
  }, [supplyRecords, borrowRecords])

  useEffect(() => {
    setSearchInfo('')
    setUnfold(true)
  }, [account])

  const renderExtraElement = (): React.ReactNode => {
    return <Row style={{ display: "flex" }}>
      <InputSearchWrap>
        <InputSearch
          type="text"
          placeholder="Search token"
          maxLength={15}
          value={searchInfo}
          onChange={event => {
            const val = event.currentTarget.value.toLocaleLowerCase().replace(/\s/g, '');
            setUnfold(true);
            setSearchInfo(val)
          }}
        />
        <SearchOutlined style={{ color: '#9DA2AB' }} className="search-icon" />
      </InputSearchWrap>
      <StyledImg src={unfold ? Fold : Unfold} alt="icon" onClick={handleUnfold} />
    </Row>
  }

  return <Card
    className='lending-card-container'
    title={title}
    extra={renderExtraElement()}
    style={{ borderRadius: "20px", marginTop: "20px" }}
    headStyle={{ borderBottomStyle: unfold ? 'solid' : 'hidden', ...headStyle }}
    bodyStyle={{ display: unfold ? 'block' : "none", ...bodyStyle }}
  >
    {judgeTextElement() ||
      <ListWrap>
        {
          recordStatus && supplyRecords ? renderUserList?.sort((a, b) => getTokenSerialNumber(a.underlyingAddress, b.underlyingAddress)).map((item) => (
            <SupplyInnerCard
              key={item.symbol}
              supplyRecord={item}
              switchCallback={switchEntered}
              renderType={title === 'Supplying' ? 'supplying' : 'supplyingMarkets'}
              handleCardClick={handleCardClick}
              willRemovedToken={judgeWillRemoveToken(item.underlyingAddress)}
            />
          )) : null
        }
        {
          recordStatus && borrowRecords ? renderUserList?.sort((a, b) => getTokenSerialNumber(a.underlyingAddress, b.underlyingAddress)).map((item) => (
            <BorrowInnerCard
              key={item.symbol}
              supplyRecord={item}
              renderType={title === 'Borrowing' ? 'borrowing' : 'borrowingMarkets'}
              handleCardClick={handleCardClick}
              limit={totalBorrowLimit || new BigNumber(0)}
              willRemovedToken={judgeWillRemoveToken(item.underlyingAddress)}
            />
          )) : null
        }
      </ListWrap>
    }
  </Card>
}

const InputSearchWrap = styled.div`
  position: relative;
  .search-icon {
    position: absolute;
    top: 8px;
    right: 20px;
  }
  @media screen and (max-width: 576px) {
    flex: 1;
  }
`
const InputSearch = styled.input`
  box-sizing: border-box;
  width: 360px;
  border: none;
  outline: none;
  line-height: 20px;
  padding: 5px 0;
  text-indent: 26px;
  border-radius: 20px;
  background-color: #f3f3f4;
  @media screen and (max-width: 576px) {
    text-indent: 15px;
    width: 100%;
    flex: 1;
  }
  &::placeholder {
    color: #b2b2b2;
    font-size: 14px;
  }
`
const StyledImg = styled.img`
  height: 31px;
  width: 35px;
  color: rgba(0,0,0,.45);
  margin-left: 15px;
  @media screen and (max-width: 576px) {
    margin-left: 8px;
  }
`;
const ListWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 34px 34px;
  @media screen and (max-width: 576px) {
    padding: 16px;
  }
  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 24px 24px;
  }
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 14px 14px;
  }

  .item {
    box-sizing: border-box;
    word-break: break-word;
    padding: 20px 15px 15px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.25);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  }
  .coin-info {
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #f2f2f2;
    .main-info {
      display: flex;
      div {
        align-self: center;
      }
      h2 {
        color: #333;
        font-size: 18px;
        margin-left: 10px;
      }
    }
    
    .extra-info {
      display: flex;
      min-width: 72px;
      @media screen and (max-width: 576px) {
        margin-left: 16px;
      }
      p {
        margin-top: 2px;
        color: #818690;
        line-height: 20px;
      }
    }
  }

  .num-info {
    margin-top: 10px;
    padding: 0 8px;
    &:first-child {
      margin-top: 20px;
    }
    p {
      color: #818690;
      line-height: 20px;
    }
    h2 {
      color: #eb591a;
      font-size: 18px;
      @media screen and (max-width: 576px) {
        font-size: 16px;
      }
    }
  }

  .btn-group {
    margin-top: 34px;
    display: flex;
    justify-content: space-around;
  }
`
const TextElement = styled.div`
  text-align: center;
  color: rgba(0,0,0, 0.45);
  font-size: 14px;
`

export default LendingCards