import React from 'react'
import styled from 'styled-components'
import { PairData, PairUtils } from '../../../../state/exchange/entities/pair_entity'
import CoinLogo from '../../../../components/CoinLogo'
import BalanceUtils from '../../../../fuck/formatBalance'
import { useEtherPrice } from '../../../../state/global/hooks'

const PairTitle: React.FC<PairData> = pair => {
  const formattedSymbol0: string =
    pair?.token0?.symbol.length > 6 ? pair?.token0?.symbol.slice(0, 5) + '...' : pair?.token0?.symbol
  const formattedSymbol1: string =
    pair?.token1?.symbol.length > 6 ? pair?.token1?.symbol.slice(0, 5) + '...' : pair?.token1?.symbol
  // rates
  const token0Rate = pair.reserve0 && pair.reserve1 ? BalanceUtils.formattedNum(+pair.reserve1 / +pair.reserve0) : '-'
  const token1Rate = pair.reserve0 && pair.reserve1 ? BalanceUtils.formattedNum(+pair.reserve0 / +pair.reserve1) : '-'
  const ethPrice = useEtherPrice()

  const token0USD =
    pair.token0?.derivedETH && ethPrice.now
      ? BalanceUtils.formattedNum(parseFloat(pair.token0.derivedETH) * parseFloat(`${ethPrice.now}`), true)
      : ''

  const token1USD =
    pair.token1?.derivedETH && ethPrice.now
      ? BalanceUtils.formattedNum(parseFloat(pair.token1.derivedETH) * parseFloat(`${ethPrice.now}`), true)
      : ''

  return (
    <>
      <TradePairName>
        <div className="logoTitle">
          <CoinLogo size={30} url={PairUtils.getPairLogo(pair)} />
          <h2>{PairUtils.getPairString(pair)} Pair</h2>
        </div>

        <TokenPrice>
          <div>
            {pair.token0 && pair.token1
              ? `1 ${formattedSymbol0} = ${token0Rate} ${formattedSymbol1} ${
                  parseFloat(pair?.token0?.derivedETH ?? '0') ? '(' + token0USD + ')' : ''
                }`
              : '-'}
          </div>
          <div>
            {pair.token0 && pair.token1
              ? `1 ${formattedSymbol1} = ${token1Rate} ${formattedSymbol0}  ${
                  parseFloat(pair.token1?.derivedETH ?? '0') ? '(' + token1USD + ')' : ''
                }`
              : '-'}
          </div>
        </TokenPrice>
      </TradePairName>
    </>
  )
}

const TokenPrice = styled.div`
  color: #666666;
  font-size: 12px;
  margin-left: 30px;
  display: flex;
  div {
    margin-right: 10px;
    white-space: nowrap;
  }
  @media screen and (max-width: 1200px) {
    margin-left: 10px;
    margin-top: 10px;
  }
  @media screen and (max-width: 576px) {
    display: block;
  }
`

const TradePairName = styled.div`
  padding-left: 30px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  .logoTitle {
    display: flex;
    align-items: center;
    /* flex: 1; */
  }
  h2 {
    margin-left: 10px;
    font-size: 20px;
    color: #121212;
  }
  @media screen and (max-width: 1200px) {
    display: block;
  }
`

export default PairTitle
