import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd';
// import { useActiveWeb3React } from '../../hooks'
import './Compensation.less';
import LeftCard from './components/LeftCard'
import RightCard from './components/RightCard'
// import { useTokenContract } from './hooks/useContract'
// import { LP_BLOCK_TAG, SASHIMI_ADDRESS } from './config/config'
import { CompensationProvider } from './context/useCompensation'

const Compensation: React.FC = () => {
  return (
    <CompensationProvider>
      <Row className="Compensation" justify="center" align="middle">
        <Col sm={12} xs={24}>
          <LeftCard/>
        </Col>
        <Col sm={12} xs={24}>
          <RightCard/>
        </Col>
      </Row>
    </CompensationProvider>
  )
}

export default Compensation;
