import React from 'react'
import styled from 'styled-components'
// import { WifiOutlined } from '@ant-design/icons'
// import useBlock from '../../hooks/useBlock'
import Polling from './Polling'
import { DiscorIcon, TelegramIcon, ENIcon, SafetyIcon } from '../Icons'
import { TwitterOutlined, GithubOutlined } from '@ant-design/icons'

const Footer: React.FC = () => {
  // const block = useBlock()
  return (
    <FooterWrap>
      <h2>Follow SashimiSwap</h2>
      <LinkGroup>
        <a href="https://discord.com/invite/mSEc2uv" target="_blank" rel="noopener noreferrer">
          <DiscorIcon style={{ color: '#fff', fontSize: '18px' }} />
        </a>
        <a href="https://t.me/joinchat/KABj-Bz6CVzyi23HK2rjzA" target="_balnk" rel="noopener noreferrer">
          <TelegramIcon style={{ color: '#fff', fontSize: '16px' }} />
        </a>
        <a href="https://twitter.com/SASHIMISASHIMI5" target="_blank" rel="noopener noreferrer">
          <TwitterOutlined style={{ color: '#fff', fontSize: '18px' }} />
        </a>
        <a href="https://github.com/SashimiProject/sashimiswap" target="_blank" rel="noopener noreferrer">
          <GithubOutlined style={{ color: '#fff', fontSize: '18px' }} />
        </a>
        <a href="https://sashimi.cool/files/sashimi_audit_report.pdf" target="_blank" rel="noopener noreferrer">
          <SafetyIcon style={{ color: '#fff', fontSize: '18px' }} />
        </a>
      </LinkGroup>
      <BlockHightWrap>
        <Polling></Polling>
        <Lauguage>
          <ENIcon style={{ color: '#9DA2AB', fontSize: '18px' }} />
        </Lauguage>
      </BlockHightWrap>
    </FooterWrap>
  )
}
const FooterWrap = styled.footer`
  height: 150px;
  box-sizing: border-box;
  padding: 20px 0 24px 30px;
  border-top: 1px solid #efefef;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 14px;
    line-height: 20px;
  }
`
const LinkGroup = styled.div`
  margin-top: 14px;
  display: felx;
  align-items: center;
  justify-content: flex-start;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    background-color: #9da2ab;
    border-radius: 50%;
    margin-right: 16px;
    &:hover {
      background-color: #eb591a;
    }
  }
`
const BlockHightWrap = styled.div`
  margin-top: 30px;
  display: flex;
`

const Lauguage = styled.div`
  margin-left: 10px;
`

// `
export default Footer
