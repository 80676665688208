import { createAction } from '@reduxjs/toolkit'

export enum VaultStrategyType {
  VAULT_STRATEGY_UNI,
  VAULT_STRATEGY_DF
}

export interface VaultInfo {
  readonly vaultIdAddr: string //vault地址,用于唯一标识id
  readonly strategyType: VaultStrategyType
  readonly tokenName: string
  readonly lpAddress: string
  decimals: number
  controller: string
  icon: string[]
  apyIcon: string[]
}

export interface VaultApyInfo {
  vaultIdAddr: string
  valueInEth: number
  totalSupply: number
  apy: number // 自身年化
  apy2: number // 农场年化
}

export const updateVaultsInfo = createAction<{ [key: string]: VaultInfo }>('vault/initialVaults')
export const updateVaultsApy = createAction<{ [key: string]: VaultApyInfo }>('vault/updateVaultsApy')
