import { Tag } from 'antd'
import circleFilledGrey from '../../../assets/svg/circle_filled_grey.svg'
import circleFilledRed from '../../../assets/svg/circle_filled_red.svg'
import circleFilledGreen from '../../../assets/svg/circle_filled_green.svg'
import circleFilledBlue from '../../../assets/svg/circle_filled_blue.svg'
import React from 'react'
import { getTimeType } from '../utils'

export function Tags(
  startTime: string | number | null,
  endTime: string | number | null): React.FunctionComponentElement<any> | string {
  const timeType = getTimeType(startTime, endTime);

  if (timeType === 'UNKNOWN' || !startTime || !endTime) {
    return '';
  }
  if (timeType === 'ENDED') {
    return <Tag className="tag tag-grey">
      <img src={circleFilledGrey} alt="circleFilledRed"/> Ended
    </Tag>;
  }
  if (timeType === 'TO_START') {
    return <Tag className="tag tag-pink">
      <img src={circleFilledRed} alt="circleFilledRed"/> Coming soon
    </Tag>;
  }

  return  <Tag className="tag tag-green">
    <img src={circleFilledGreen} alt="circleFilledRed"/> Ending soon
  </Tag>;
}


export function TagsStart(
  startTime: string | number | null,
  endTime: string | number | null): React.FunctionComponentElement<any> | string {
  const timeType = getTimeType(startTime, endTime);

  if (timeType === 'UNKNOWN' || !startTime || !endTime) {
    return '';
  }
  // if (timeType === 'ENDED') {
  //   return <Tag className="tag tag-grey">
  //     <img src={circleFilledGrey} alt="circleFilledRed"/> Ended
  //   </Tag>;
  // }
  if (timeType === 'TO_START') {
    return <Tag className="tag tag-pink">
      <img src={circleFilledRed} alt="circleFilledRed"/> Coming soon
    </Tag>;
  }

  return  <Tag className="tag tag-blue">
    <img src={circleFilledBlue} alt="circleFilledBlue"/> Started
  </Tag>;
}
