import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
`;

interface IWarningTips {
  content: string
}


const WarningTips: React.FC<IWarningTips> = ({ content }) => (
  <StyledContainer>
    {content}
  </StyledContainer>
);

export default WarningTips;
