import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Card from '../../components/Card'
import CardContent from '../../components/CardContent'
import MainContainer from '../../components/MainContainer'
import Charts from './components/Chart'
import Records from './components/Records'
import NavRoute from './components/children'
import { PairData } from '../../state/exchange/entities/pair_entity'
import { PairTransactionsUpdaterByAddress } from '../../state/exchange/updater/pair_transactions'
import { Updater as PairChartUpdater } from '../../state/pair-chart/updater'
import { usePairs, usePairByAddress } from '../../state/global/hooks'
import { isEthAddress, isAddress } from '../../utils';
import PairTitle from './components/PairTitle';
const Exchange: React.FC = () => {
  const history = useHistory()
  // const pairAddress: string = (history.location.state as string) || '0x490ccb3c835597ff31e525262235487f9426312b'
  let pairAddress: string = (history.location.state as string);
  const pairs = usePairs();
  if (!pairAddress) {
    const urlPair = history.location.pathname.split('/').slice(3);
    if (urlPair.length === 2 && urlPair.every((id)=> isAddress(id) || id.toUpperCase() === 'ETH')) {
      const pair = pairs.find((pair) => `${isEthAddress(pair.token0.id)}${isEthAddress(pair.token1.id)}`.toUpperCase() === urlPair.join("").toUpperCase() );
      pairAddress = pair?.id || '0x490ccb3c835597ff31e525262235487f9426312b'; 
    } else {
      history.replace({ pathname:'/pairs' })
    }
  }
  // if (!pairAddress) history.push('/pairs')
  const pairData: PairData = usePairByAddress(pairAddress)
  return (
    <MainContainer>
      <PairTransactionsUpdaterByAddress address={pairAddress} />
      <PairChartUpdater defaultAddress={pairAddress} />
      <CardContainer>
      <PairTitle {...pairData} />
        <CardContent>
          <Container>
            <Charts {...pairData} />
            <NavRoute {...pairData} />
          </Container>
        </CardContent>
      </CardContainer>
      <RecordsContainer>
        <CardContent>
          <Records pairAddress={pairAddress} />
        </CardContent>
      </RecordsContainer>
    </MainContainer>
  )
}
const CardContainer = styled(Card)`
  min-height: 510px;
  padding-bottom: 68px;
`
const Container = styled.div`
  /* display: flex;
  flex-wrap: wrap; */
  overflow: hidden;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  @media screen and (max-width: 1550px) {
    grid-template-columns: 580px 1fr;
  }
  @media screen and (max-width: 1400px) {
    grid-template-columns: 500px 1fr;
  }
  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
`
const RecordsContainer = styled(Card)`
  margin-top: 30px;
`
export default Exchange
