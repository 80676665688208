import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Spin, message } from 'antd'
import BigNumber from 'bignumber.js'
import MainContainer from '../../components/MainContainer'
import Card from '../../components/Card'
import CardTitle from '../../components/CardTitle'
import CardContent from '../../components/CardContent'
import MinHeight from '../../components/MinHeight'
import EntranceModal from './components/EntranceModal';
import SupplyModal from './components/SupplyModal';
import BorrowModal from './components/BorrowModal';
import ExitMarketModal from './components/ExitMarketModal';
import LendingHeader from './LendingHeader'
import LendingCards from './LendingCards'
import { useSelector, useDispatch } from 'react-redux'
import useInterval from 'react-use/lib/useInterval';
import { useActiveWeb3React } from '../../hooks'
import { getAllMarkets, getGlobalDetail, getUserInfo, } from './utils';
import { IGlobalDetail, IMarketInfo, ISupplyRecord } from './types';
import { AppState } from '../../state';
import { batchAddMarket } from '../../state/markets/actions';
import { useSashimiBalance } from './hooks'
import { judgeWillRemoveToken } from './common/utils'
// import { useGetAllMarkets } from './hooks/useGetAllMarkets';

const MODAL_ACTIONS = {
  SUPPLY: 'SUPPLY',
  BORROW: 'BORROW',
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE'
};

const MARKET_TYPE_MODAL_ACTIONS = {
  supply: 'SUPPLY',
  'left_supply': 'SUPPLY',
  borrow: 'BORROW',
  'left_borrow': 'BORROW'
};

function splitMarkets(markets: IMarketInfo[], allMarkets: IMarketInfo[]): {
  leftBorrowMarkets: ISupplyRecord[]
  leftSupplyMarkets: ISupplyRecord[]
  supplyMarkets: ISupplyRecord[]
  borrowMarkets: ISupplyRecord[]
} {
  if (markets.length === 0) {
    return {
      leftBorrowMarkets: allMarkets.map(v => ({
        ...v,
        marketType: 'left_borrow'
      })),
      leftSupplyMarkets: allMarkets.map(v => ({
        ...v,
        marketType: 'left_supply'
      })),
      supplyMarkets: [],
      borrowMarkets: []
    };
  }
  const leftMarkets = markets.filter(v => {
    const {
      borrowBalanceInTokenUnit,
      supplyBalanceInTokenUnit,
      tokenDecimals
    } = v;
    const lowThreshold = new BigNumber(1).dividedBy(`1e${tokenDecimals}`);
    return borrowBalanceInTokenUnit.lt(lowThreshold) && supplyBalanceInTokenUnit.lt(lowThreshold);
  });
  const supplyMarkets: ISupplyRecord[] = markets.filter(v => {
    const {
      supplyBalanceInTokenUnit,
      tokenDecimals
    } = v;
    const lowThreshold = new BigNumber(1).dividedBy(`1e${tokenDecimals}`);
    return supplyBalanceInTokenUnit.gte(lowThreshold);
  }).map(v => ({
    ...v,
    marketType: 'supply'
  }));
  const borrowMarkets: ISupplyRecord[] = markets.filter(v => {
    const {
      borrowBalanceInTokenUnit,
      tokenDecimals
    } = v;
    const lowThreshold = new BigNumber(1).dividedBy(`1e${tokenDecimals}`);
    return borrowBalanceInTokenUnit.gte(lowThreshold);
  }).map(v => ({
    ...v,
    marketType: 'borrow'
  }));
  return {
    leftBorrowMarkets: leftMarkets.map(v => ({
      ...v,
      marketType: 'left_borrow'
    })),
    leftSupplyMarkets: leftMarkets.map(v => ({
      ...v,
      marketType: 'left_supply'
    })),
    supplyMarkets,
    borrowMarkets
  };
}


const Lending: React.FC = () => {
  const dispatch = useDispatch();
  const { library } = useActiveWeb3React();
  useSashimiBalance();
  const wallet = useSelector<AppState, AppState['wallet']>(state => state.wallet);
  const allMarketsMap = useSelector<AppState, AppState['market']>(state => state.market);
  const txModalOpen = useSelector<AppState, AppState['transactions']['latestTxHash']>(state => state.transactions.latestTxHash);
  const { chainId, account, connected, loginSettled } = wallet;
  const [marketsWithUserInfo, setMarketsWithUserInfo] = useState<IMarketInfo[]>([]);
  const [currentMarket, setCurrentMarket] = useState<IMarketInfo>({} as IMarketInfo);
  const allMarkets: IMarketInfo[] = useMemo(() => Object.values((allMarketsMap || {})[chainId ?? 1] || {}), [allMarketsMap, chainId]);
  const [loading, setLoading] = useState(true);
  const [modalAction, setModalAction] = useState({
    action: '',
    symbol: ''
  });

  useEffect(() => {
    if (loginSettled) {
      setLoading(true);
      getAllMarkets(wallet.chainId, library?.provider).then(markets => {
        dispatch(batchAddMarket({
          chainId,
          markets: markets || []
        }));
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [chainId, loginSettled, account, dispatch, wallet.chainId, library]);

  useEffect(() => {
    if (allMarkets.length > 0 && account && connected && !txModalOpen) {
      getUserInfo({ chainId: wallet.chainId, account, connected }, allMarkets, library?.provider).then(res => {
        setMarketsWithUserInfo(res);
      });
    } else {
      setMarketsWithUserInfo([]);
    }
  }, [allMarkets, account, connected, txModalOpen, library, wallet.chainId]);

  useEffect(() => {
    if (modalAction.symbol && modalAction.action) {
      if (!connected) {
        message.error('You need to connect to your wallet to continue.');
        return;
      }
      setCurrentMarket((marketsWithUserInfo.length > 0 ? marketsWithUserInfo : allMarkets)
        .filter(v => v.symbol === modalAction.symbol)[0]);
    } else {
      setCurrentMarket({} as IMarketInfo);
    }
  }, [
    modalAction, allMarkets, connected, marketsWithUserInfo
  ]);

  useInterval(() => {
    getAllMarkets(chainId, library?.provider).then(markets => {
      dispatch(batchAddMarket({
        chainId,
        markets: markets || []
      }));
    });
  }, chainId && loginSettled && connected ? 60000 : null);

  const globalDetail: IGlobalDetail = useMemo(
    () => getGlobalDetail(connected && marketsWithUserInfo.length > 0 ? marketsWithUserInfo : allMarkets),
    [
      marketsWithUserInfo,
      connected,
      allMarkets
    ]
  );
  const segmentedMarkets = useMemo(() => splitMarkets(marketsWithUserInfo, allMarkets), [
    marketsWithUserInfo,
    allMarkets
  ]);
  const switchEntered = useCallback((entered, event) => {
    const marketSymbol = event.currentTarget.dataset.symbol;
    setModalAction({
      action: entered ? MODAL_ACTIONS.ENABLE : MODAL_ACTIONS.DISABLE,
      symbol: marketSymbol
    });
  }, []);

  function handleCancel() {
    setModalAction({
      action: '',
      symbol: ''
    });
  }
  function handleModalConfirm() {
    setModalAction({
      action: '',
      symbol: ''
    });
  }

  function handleCardClick(e: any) {
    const { symbol, markettype } = e.currentTarget.dataset;
    setModalAction({
      action: MARKET_TYPE_MODAL_ACTIONS[markettype as keyof typeof MARKET_TYPE_MODAL_ACTIONS],
      symbol: symbol
    });
  }

  const supplyDataSource = [segmentedMarkets.supplyMarkets, segmentedMarkets.leftSupplyMarkets]
  const borrowDataSource = [segmentedMarkets.borrowMarkets, segmentedMarkets.leftBorrowMarkets]

  return (
    <MainContainer>
      <Card>
        <Spin spinning={loading}>
          <MinHeight>
            <LendingHeader personalSource={globalDetail} />
            <CardContent>
              {/* <CardTitle text={'Supply'} borderBottom='1px dashed #ddd' /> */}
              <CardTitle text={<>
                <span>{'Supply'}</span>
                <span className="warning-token-message">{`YFI will be removed`}</span>
              </>} borderBottom='1px dashed #ddd' />
              {
                ['Supplying', 'All markets'].map((title, i) => (
                  <LendingCards
                    account={account}
                    key={title}
                    title={title}
                    supplyRecords={supplyDataSource[i]}
                    switchEntered={switchEntered}
                    handleCardClick={handleCardClick}
                  >
                  </LendingCards>
                ))
              }
            </CardContent>
            <CardContent>
              {/* <CardTitle text={'Borrow'} borderBottom='1px dashed #ddd' /> */}
              <CardTitle text={<>
                <span>{'Borrow'}</span>
                <span className="warning-token-message">{`YFI will be removed`}</span>
              </>} borderBottom='1px dashed #ddd' />
              {
                ['Borrowing', 'All markets'].map((title, i) => (
                  <LendingCards
                    account={account}
                    key={title}
                    title={title}
                    borrowRecords={borrowDataSource[i]}
                    handleCardClick={handleCardClick}
                    totalBorrowLimit={globalDetail.totalBorrowLimit}
                  >
                  </LendingCards>
                ))
              }
            </CardContent>
          </MinHeight>
        </Spin>
      </Card>
      {modalAction.action === MODAL_ACTIONS.ENABLE && currentMarket.symbol
        ? (
          <EntranceModal
            visible={modalAction.action === MODAL_ACTIONS.ENABLE}
            onCancel={handleCancel}
            onConfirm={handleModalConfirm}
            limitInfo={globalDetail}
            {...currentMarket}
          />
        )
        : null}
      {modalAction.action === MODAL_ACTIONS.DISABLE && currentMarket.symbol
        ? (
          <ExitMarketModal
            visible={modalAction.action === MODAL_ACTIONS.DISABLE}
            onCancel={handleCancel}
            onConfirm={handleModalConfirm}
            limitInfo={globalDetail}
            {...currentMarket}
          />
        )
        : null}
      {modalAction.action === MODAL_ACTIONS.SUPPLY && currentMarket.symbol
        ? (
          <SupplyModal
            visible={modalAction.action === MODAL_ACTIONS.SUPPLY}
            limitInfo={globalDetail}
            onCancel={handleCancel}
            onConfirm={handleModalConfirm}
            {...currentMarket}
            willRomoveToken={judgeWillRemoveToken(currentMarket.underlyingAddress)}
          />
        )
        : null}
      {modalAction.action === MODAL_ACTIONS.BORROW && currentMarket.symbol
        ? (
          <BorrowModal
            visible={modalAction.action === MODAL_ACTIONS.BORROW}
            limitInfo={globalDetail}
            onCancel={handleCancel}
            onConfirm={handleModalConfirm}
            {...currentMarket}
            willRomoveToken={judgeWillRemoveToken(currentMarket.underlyingAddress)}
          />
        )
        : null}
    </MainContainer>
  )
}

export default Lending
