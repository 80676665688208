import { createAction } from '@reduxjs/toolkit'
interface IWeb3 {
  chainId: number
  account: string
}


export const loginSettled = createAction<{chainId: number}>('wallet/loginSettled')

export const walletConnected = createAction<IWeb3>('wallet/walletConnected')

export const chainIdChanged = createAction<{chainId: number}>('wallet/chainIdChanged')

export const accountChanged = createAction<{account: string}>('wallet/accountChanged')

export const networkIdChanged = createAction<{networkId: number}>('wallet/networkIdChanged')

export const closeConnected = createAction('wallet/closeConnected')

export const updateBalance = createAction<number>('wallet/updateBalance')
