import React from 'react'
import styled from 'styled-components'
import { useWindowSize } from 'react-use'
const MinHeight = ({ children }: { children: any }) => {
  const { height } = useWindowSize()
  return <StyleMinHeight height={height}>{children}</StyleMinHeight>
  // return <StyleMinHeight style={{ height: height }}> {children}</StyleMinHeight>
}

// const StyleMinHeight = styled.div`
//   min-height: ${props => props.height - 62}px;
// `
const StyleMinHeight = styled.div<{ height?: number }>`
  min-height: ${({ height }) => (height ? height - 62 + 'px' : '10px')};
`
export default MinHeight
