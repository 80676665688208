/**
 * @file list item
 * @author atom-yang
 */
import React from 'react';
import styled from 'styled-components';
import '../../common/index.less';

interface IListItem {
  title: string
  desc: React.ReactNode
}

const CenterDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;



const ListItem:React.FC<IListItem> = props => {
  const {
    title,
    desc
  } = props;
  return (
    <CenterDiv>
      <span className="sub-title">
        {title}
      </span>
      <span className="small-tip float-right">{desc}</span>
    </CenterDiv>
  );
};

export default ListItem;
