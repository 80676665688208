/**
 * @file supply modal
 * @author atom-yang
 */
import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Divider,
  Modal,
  Tabs,
  Row,
  Col,
  Button,
  Input,
  message
} from 'antd';
import BigNumber from 'bignumber.js';
import {
  If,
  Then,
  Else
} from 'react-if';
import Compound from '@sashimiswap/compound-js';
import ListItem from '../ListItem';
import SymbolCoinIcon from '../SymbolCoinIcon'
import {
  MarketInfoDefaultProps,
  SAFE_MAX
} from '../../common/constants';
import './index.less';
import EnableTips from '../EnableTips';
import { useCompound } from '../../utils';
import { addTransaction } from '../../../../state/transactions/actions';
import { approveToken } from '../../common/utils';
import { IGlobalDetail, IBaseModalProps } from '../../types';
import { AppState } from '../../../../state';
import { useActiveWeb3React } from '../../../../hooks'
import { useRefreshMarkets } from '../../hooks'
import WarningTips from '../WarningTips';

interface ISupplyProps extends IBaseModalProps {
  willRomoveToken: boolean
}

interface IFunWithdraw {
  (supplyBalance: BigNumber,
    supplyBalanceInTokenUnit: BigNumber,
    collateralFactor: BigNumber,
    limitInfo: IGlobalDetail,
    price: BigNumber,
    underlyingAmount: BigNumber): BigNumber
}

const SUPPLY_TABS = {
  SUPPLY: 'SUPPLY',
  WITHDRAW: 'WITHDRAW'
};

function getOkProps(
  loading: boolean,
  walletBalance: BigNumber,
  currentTab: string,
  entered: boolean,
  enable: boolean,
  supplyTokenBalanceInTokenUnit: BigNumber
) {
  let disabled = new BigNumber(walletBalance).lte(0);
  let okText = disabled ? 'NO FUNDS AVAILABLE' : SUPPLY_TABS.SUPPLY;
  let maxText = 'MAX';
  if (currentTab === SUPPLY_TABS.WITHDRAW) {
    okText = SUPPLY_TABS.WITHDRAW;
    maxText = 'SAFE MAX';
    disabled = new BigNumber(supplyTokenBalanceInTokenUnit).lte(0);
  }
  if (currentTab === SUPPLY_TABS.WITHDRAW && !entered) {
    maxText = 'MAX';
  }
  if (currentTab === SUPPLY_TABS.SUPPLY && !enable) {
    okText = 'ENABLE';
    disabled = false;
  }
  return {
    loading,
    disabled,
    okText,
    maxText
  };
}

function getNewLimit(value: string, action: string, limitInfo: IGlobalDetail, price: BigNumber, collateralFactor: BigNumber, entered: boolean) {
  const {
    totalBorrowLimit,
    totalBorrowBalance,
    totalBorrowLimitUsedPercent
  } = limitInfo;
  if (!entered) {
    return {
      newBorrowLimit: totalBorrowLimit,
      newUsed: totalBorrowLimitUsedPercent
    };
  }
  const coef = action === SUPPLY_TABS.SUPPLY ? 1 : -1;
  const newAdd = new BigNumber(value || 0)
    .times(price)
    .times(collateralFactor)
    .times(coef);
  let newBorrowLimit = newAdd.plus(totalBorrowLimit);
  newBorrowLimit = newBorrowLimit.lt(0) ? new BigNumber(0) : newBorrowLimit;
  return {
    newBorrowLimit,
    newUsed: newBorrowLimit.eq(0)
      ? new BigNumber(0)
      : new BigNumber(totalBorrowBalance)
        .dividedBy(newBorrowLimit)
        .times(100)
  };
}

const getSafeWithdraw: IFunWithdraw = function (
  supplyBalance,
  supplyBalanceInTokenUnit,
  collateralFactor,
  limitInfo,
  price,
  underlyingAmount
) {
  const {
    totalBorrowLimit,
    totalBorrowBalance,
    totalBorrowLimitUsedPercent
  } = limitInfo;
  if (totalBorrowLimitUsedPercent.gte(SAFE_MAX * 100)) {
    return new BigNumber(0);
  }
  if (totalBorrowLimitUsedPercent.eq(0)) {
    return supplyBalanceInTokenUnit.gt(underlyingAmount) ? underlyingAmount : supplyBalanceInTokenUnit;
  }
  const newLimit = new BigNumber(totalBorrowBalance).dividedBy(SAFE_MAX);
  const diff = new BigNumber(totalBorrowLimit).minus(newLimit).dividedBy(price).dividedBy(collateralFactor);
  const result = diff.gt(supplyBalanceInTokenUnit) ? supplyBalanceInTokenUnit : diff;
  return result.gt(underlyingAmount) ? underlyingAmount : result;
}

const getMaxWithdraw: IFunWithdraw = function (
  supplyBalance,
  supplyBalanceInTokenUnit,
  collateralFactor,
  limitInfo,
  price,
  underlyingAmount
) {
  const {
    totalBorrowLimit,
    totalBorrowBalance
  } = limitInfo;
  const newTotal = new BigNumber(totalBorrowBalance);
  const diff = new BigNumber(totalBorrowLimit).minus(newTotal).dividedBy(price).dividedBy(collateralFactor);
  const result = diff.gt(supplyBalanceInTokenUnit) ? supplyBalanceInTokenUnit : diff;
  return result.gt(underlyingAmount) ? underlyingAmount : result;
}

const SupplyModal: React.FC<ISupplyProps> = props => {
  const {
    visible,
    symbol,
    enabled,
    entered,
    collateralFactor,
    underlyingAddress,
    cTokenAddress,
    price,
    supplyRate,
    limitInfo,
    walletBalance,
    cTokenBalance,
    supplyBalance,
    supplyBalanceInTokenUnit,
    tokenDecimals,
    onCancel,
    onConfirm,
    distributionSupplyAPY,
    underlyingAmount,
    willRomoveToken
  } = props;
  const compound = useCompound();
  const dispatch = useDispatch();
  const { library } = useActiveWeb3React();
  const {
    totalBorrowLimit,
    totalBorrowLimitUsedPercent
  } = limitInfo;
  const wallet = useSelector<AppState, AppState['wallet']>(state => state.wallet);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>(SUPPLY_TABS.SUPPLY);
  const [input, setInput] = useState<string>('0');
  const [hash, setHash] = useState('');
  const { complete } = useRefreshMarkets(hash);
  const okProps = useMemo(() => getOkProps(
    loading,
    walletBalance,
    currentTab,
    !!entered,
    !!enabled,
    supplyBalanceInTokenUnit
  ), [
    loading,
    walletBalance,
    currentTab,
    enabled,
    entered,
    supplyBalanceInTokenUnit
  ]);

  const newLimit = useMemo(() => getNewLimit(
    input,
    currentTab,
    limitInfo,
    collateralFactor,
    price,
    !!entered
  ), [
    input,
    currentTab,
    limitInfo,
    collateralFactor,
    price,
    entered
  ]);

  async function handleConfirm() {
    try {
      const { chainId, account, connected } = wallet;
      if (!account || !connected) {
        message.error('You need to connect to your wallet to continue.');
        return;
      }
      if (currentTab === SUPPLY_TABS.SUPPLY && !enabled) {
        // modify xjz
        // const { currentProvider } = web3 || defaultWeb3;
        const currentProvider = library?.provider;
        const network = Compound.util.getNetNameWithChainId(chainId);
        setLoading(true);
        const tx = await approveToken(underlyingAddress, cTokenAddress, symbol, network, currentProvider);
        setHash(tx.hash)
        dispatch(addTransaction(tx));
        // setLoading(false);
        // onConfirm();
        return;
      }
      if (+input <= 0) {
        message.error('Input needs to be larger than 0');
        return;
      }
      let max: BigNumber | string = walletBalance;
      const inputNumber = new BigNumber(input).times(`1e${tokenDecimals}`).toFixed(0);
      if (currentTab === 'WITHDRAW') {
        max = getMaxWithdraw(
          supplyBalance,
          supplyBalanceInTokenUnit,
          collateralFactor,
          limitInfo,
          price,
          underlyingAmount
        ).toFixed(tokenDecimals);
        if (!entered) {
          max = supplyBalanceInTokenUnit;
        }
        if (new BigNumber(input).gt(max)) {
          message.error('Input is too large to withdraw.');
          return;
        }
        setLoading(true);
        const redeemAll = supplyBalanceInTokenUnit.lte(input);
        let tx;
        if (redeemAll) {
          tx = await compound.redeem(`sl${symbol}`, cTokenBalance.toFixed(0), {
            mantissa: true
          });
        } else {
          tx = await compound.redeem(symbol, inputNumber, {
            mantissa: true
          });
        }
        dispatch(addTransaction(tx));
        setHash(tx.hash)
        // setLoading(false);
        // onConfirm();
      } else {
        if (new BigNumber(input).gt(max)) {
          message.error('Input is too large to supply.');
          return;
        }
        setLoading(true);
        const tx = await compound.supply(symbol, inputNumber, true, {
          mantissa: true
        });
        dispatch(addTransaction(tx));
        setHash(tx.hash)
        // setLoading(false);
        // onConfirm();
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      message.error((e && e.error).message || 'Send Transaction failed');
    }
  }

  function handleValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    try {
      if (Number.isNaN(parseFloat(value)) || +value === 0) {
        // modify xjz
        setInput(value);
      } else {
        setInput(value);
      }
    } catch (e) {
      console.error(e);
      setInput(value);
    }
  }

  function handleValueBlur() {
    const n = new BigNumber(input || 0);
    const dp = (String(input).split('.')[1] || '').length;
    setInput(n.toFixed(Math.min(dp, tokenDecimals), BigNumber.ROUND_FLOOR));
  }

  function handleMax() {
    if (currentTab === SUPPLY_TABS.SUPPLY) {
      setInput(new BigNumber(walletBalance).toFixed(tokenDecimals, BigNumber.ROUND_FLOOR));
    } else if (currentTab === SUPPLY_TABS.WITHDRAW && !entered) {
      const min = supplyBalanceInTokenUnit.gt(underlyingAmount) ? underlyingAmount : supplyBalanceInTokenUnit;
      setInput(min.toFixed(tokenDecimals, BigNumber.ROUND_FLOOR));
    } else {
      setInput(getSafeWithdraw(
        supplyBalance,
        supplyBalanceInTokenUnit,
        collateralFactor,
        limitInfo,
        price,
        underlyingAmount
      ).toFixed(tokenDecimals, BigNumber.ROUND_FLOOR));
    }
  }

  function handleTabChange(value: string) {
    setCurrentTab(value);
    setInput('0');
  }

  useEffect(() => {
    if (complete) {
      onConfirm()
    }
  }, [complete, onConfirm])

  return (
    <Modal
      className="supply-modal"
      visible={visible}
      // title={symbol}
      title={<SymbolCoinIcon size={20} tokenSymbol={symbol} tokenAddress={underlyingAddress} />}
      okText={okProps.okText}
      okButtonProps={{
        ...okProps,
        ...currentTab === SUPPLY_TABS.SUPPLY && willRomoveToken ? { disabled: true } : {}
      }}
      maskClosable={!okProps.loading}
      destroyOnClose
      onOk={handleConfirm}
      onCancel={onCancel}
    >
      <Row gutter={32}>
        <If condition={currentTab === SUPPLY_TABS.SUPPLY && (willRomoveToken || !enabled)}>
          <Then>
            {symbol === 'SASHIMI'
              ? <>&nbsp;&nbsp;&nbsp;<WarningTips content={` ${symbol} will suspend the Supply function`} /></>
              : willRomoveToken
                ? <WarningTips content={`${symbol} token will be removed, "supply" is disabled, please withdraw`} />
                : <EnableTips symbol={symbol} />}
          </Then>
          <Else>
            <>
              <Col span={16}>
                <Input
                  type="number"
                  min={0}
                  step={0.1}
                  value={input}
                  onChange={handleValueChange}
                  onBlur={handleValueBlur}
                />
              </Col>
              <Col span={8}>
                <Button type="link" onClick={handleMax}>{okProps.maxText}</Button>
              </Col>
            </>
          </Else>
        </If>
      </Row>
      <div className="supply-modal-list gap-top-large">
        <div>
          <Tabs
            activeKey={currentTab}
            onChange={handleTabChange}
            type="card"
          >
            <Tabs.TabPane
              key={SUPPLY_TABS.SUPPLY}
              tab={SUPPLY_TABS.SUPPLY}
            />
            <Tabs.TabPane
              key={SUPPLY_TABS.WITHDRAW}
              tab={SUPPLY_TABS.WITHDRAW}
            />
          </Tabs>
        </div>
        <If condition={currentTab === SUPPLY_TABS.SUPPLY}>
          <Then>
            <ListItem
              title="Wallet Balance"
              desc={`${walletBalance.toFixed(2)} ${symbol}`}
            />
          </Then>
          <Else>
            <ListItem
              title="Current Supplying"
              desc={`${supplyBalanceInTokenUnit.toFixed(2, BigNumber.ROUND_FLOOR)} ${symbol}`}
            />
          </Else>
        </If>
        <Divider />
        <ListItem title="Supply APY" desc={`${supplyRate.toFixed(2)}%`} />
        <Divider />
        <ListItem title="Farming APY" desc={`${distributionSupplyAPY.toFixed(2)}%`} />
        <Divider />
        <ListItem
          title="Borrow Limit"
          desc={`$${totalBorrowLimit.toFixed(2)} -> $${newLimit.newBorrowLimit.toFixed(2)}`}
        />
        <Divider />
        <ListItem
          title="Borrow Limit Used"
          desc={`${totalBorrowLimitUsedPercent.toFixed(2)}%
           -> ${newLimit.newUsed.toFixed(2)}%`}
        />
      </div>
    </Modal>
  );
};

SupplyModal.defaultProps = {
  ...MarketInfoDefaultProps
};

export default React.memo(SupplyModal);
