import BigNumber from 'bignumber.js'
import Numeral from 'numeral'

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

export default class BalanceUtils {
  /**
   *
   * @param balance
   * @param decimals
   */
  public static getBalanceNumber(balance: BigNumber, decimals = 18): number {
    return balance?.dividedBy(new BigNumber(10).pow(decimals)).toNumber()
  }

  public static numberToBalance(dec: number, decimals = 18): BigNumber {
    return new BigNumber(dec).multipliedBy(new BigNumber(10).pow(decimals))
  }

  public static getFullDisplayBalance(balance: BigNumber, decimals = 18): string {
    return balance?.dividedBy(new BigNumber(10).pow(decimals)).toFixed()
  }

  public static getDisplayBalance(balance: BigNumber, decimals = 18): string {
    const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
    if (displayBalance.lt(1)) {
      return displayBalance.toPrecision(4)
    } else {
      return displayBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }

  public static toK(num: number | string): string {
    return Numeral(num).format('0.[00]a')
  }

  /**
   * 格式化金额显示字符串
   * @param value
   * @param usd
   * @param acceptNegatives
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static formattedNum(value: number | string, usd = false, acceptNegatives = false): string {
    const CurrencySymbol: string = usd ? '$' : ''

    if (!value || (typeof value === 'number' && isNaN(value))) {
      return CurrencySymbol + '0'
    }

    const num = typeof value === 'string' ? parseFloat(value) : value

    if (num > 500000000) {
      return CurrencySymbol + BalanceUtils.toK(num.toFixed(0))
    }

    if (num === 0) {
      return CurrencySymbol + '0'
    }

    if (num < 0.0001 && num > 0) {
      return `< ${CurrencySymbol}0.0001`
    }

    if (num > 1000) {
      return CurrencySymbol + Number(num.toFixed(0)).toLocaleString()
    }

    if (usd) {
      if (num < 0.1) {
        return CurrencySymbol + Number(num.toFixed(4))
      } else {
        const usdString = priceFormatter.format(num)
        return CurrencySymbol + usdString.slice(1, usdString.length)
      }
    }

    return Number(num).toFixed(5)
  }
}
