export function calculateDHM(time: number): {
  day: number
  hour: number
  minute: number
  second: number
} {
  const secondADay = 86400000;
  const secondAHour = 3600000;
  const secondAMinute = 60000;
  return {
    day: Math.floor(time / secondADay),
    hour: Math.floor((time % secondADay) / secondAHour),
    minute: Math.floor((time % secondAHour) / secondAMinute),
    second: Math.floor(time % secondAMinute),
  };
}

export type TimeType = 'UNKNOWN' | 'ENDED' | 'TO_START' | 'TO_END'

export function getTimeType (
  startTime: string | number | null,
  endTime: string | number | null):  TimeType {
  const timeNow = new Date().getTime();

  if (!startTime || !endTime) {
    return 'UNKNOWN';
  }
  if (timeNow > +endTime) {
    return 'ENDED';
  }
  if (timeNow < +startTime) {
    return 'TO_START';
  }
  return 'TO_END';
}
