import ethers from 'ethers'
import VAULT_ABI from '../../constants/sashimi/vault/vaultABI.json'
import ERC20_ABI from '../../constants/abis/erc20.json'
const provider = new ethers.providers.JsonRpcProvider({
  url: 'https://eth-mainnet.alchemyapi.io/v2/HEuY-pJweb1B6BoFXdeA0XuUY5IKXRwN'
})

export const svDAI = new ethers.Contract('0xe6cB949e11bEB2a7b48f4cb5bFD8724501cfdA91', VAULT_ABI, provider)

export const svUSDC = new ethers.Contract('0x4221330F24b26f20457f7c7c925CFc46b1541CA6', VAULT_ABI, provider)
export const svUSDT = new ethers.Contract('0x722669a18852c659110D4a1E44f9647Fc44EdCc2', VAULT_ABI, provider)
export const sashimiBar = new ethers.Contract('0xc28e27870558cf22add83540d2126da2e4b464c2', ERC20_ABI, provider)
