import * as main from './online'
import * as kovan from './kovan';
import { TConfig } from '../components'

// deprecated
// factory 0xf028f723ed1d0fe01cc59973c49298aa95c57472
// 新factory 0xc03efb3e3ddfb0c0f3c148a2afe10ccfa1c5819e
// 老 router 0xe4fe6a45f354e845f954cddee6084603cedb9410
// 新 router:  0xf32c441ff6708cb6956109e4176737c406e90eaf

// Todo: 这个无效
const CHAIN_ENV = process.env.REACT_APP_CHAIN_ID === '1'
  ? 'main' : 'kovan';
// const CHAIN_ENV = 'kovan'
console.log('CHAIN_ENV: ', process.env.REACT_APP_CHAIN_ID);
const output: {
  [key: string]: TConfig
} = {
  main: main,
  kovan: kovan
}

export const {
  SHARDING_DAO_ADDRESS,
  // SASHIMI_ADDRESS,
  EXCHANGE_DELEGATE_ADDRESS,
  LP_BLOCK_TAG,
  POOLS_INFO,
  LP_DECIMAL_TENTH_POWER
} = output[CHAIN_ENV];
