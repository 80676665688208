import { createReducer } from '@reduxjs/toolkit'
import { updateTokens, updateTransactionsByPairAddress } from './actions'
import { TokenEntity } from './entities/token_entity'
import { PairTransactionEntity } from './entities/pair_transaction_entity'

export interface ExchangeState {
  tokens: { [key: string]: TokenEntity }
  pairTransaction: { [key: string]: PairTransactionEntity }
}

const initialState: ExchangeState = {
  tokens: {},
  pairTransaction: {}
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateTokens, (state, action) => {
      state.tokens = action.payload
    })
    .addCase(updateTransactionsByPairAddress, (state, action) => {
      const { address, data } = action.payload
      state.pairTransaction[address] = data
    })
)
