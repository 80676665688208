import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Card from '../../../../components/Card'
import CardContent from '../../../../components/CardContent'
import CardTitle from '../../../../components/CardTitle'
import AnnouncementIcon from '../../../../assets/images/announcement_icon.png'
import { Button } from 'antd'
import api from '../../../../api/index'
// import { useActiveWeb3React } from '../../../../hooks'
const ButtonStyle = {
  width: '268px',
  height: '46px',
  borderRadius: '10px',
  fontSize: '16px',
  boxShadow: '0px 5px 10px 0px rgba(232, 97, 54, 0.3)'
}
interface APIPros {
  [key: string]: any
}
const API: APIPros = api
const Announcement: React.FC = () => {
  const cms = API?.cms
  // const { chainId } = useActiveWeb3React()
  const [announceList, setANList] = useState([])
  useEffect(() => {
    try {
      cms.getAnnounces({
        // data: {
        //   chain: chainId
        // },
        success: (result: any) => {
          result = result.sort((a: any, b: any) =>
            new Date(a.date).getTime() > new Date(b.date).getTime() ? -1 : 1
          )
          result.forEach((item: any) => {
            const timeStamp = new Date().getTime() - new Date(item.date).getTime()
            const days = Math.floor(timeStamp / 1000 / 3600 / 24)
            const hours = Math.floor(timeStamp / 1000 / 3600)
            const minutes = Math.floor(timeStamp / 1000 / 60)
            item.updateAgo = days ? `${days} days ago` : hours ? `${hours} hours ago` : `${minutes} minutes ago`
          })
          setANList(result.slice(0, 3))
        }
      })
    } catch (e) {
      console.log('fetchAPY error: ', e)
    }
  }, [cms])
  return (
    <Cardwrap>
      <CardContent>
        <CardTitle text="Sashimi Announcement" icon={<img src={AnnouncementIcon} style={{ width: '20px' }} alt="" />} />
        <List>
          {announceList.map((item: any) => (
            <li key={item.id}>
              <a href={item.url} target={item.title}>
                <div>
                  <p className="accounce-item-title">{item.title}</p>
                  <p>
                    <span>{item.publisher || ''}</span>
                    <span>{item.updateAgo}</span>
                  </p>
                </div>
                {item.images[0] ? <img src={`https://cms.sashimi.com${item.images[0].url}`} /> : <></>}
              </a>
            </li>
          ))}
        </List>
        <ButtonWrap>
          <Button
            onClick={() => window.open('https://medium.com/@SASHIMISASHIMI5', '_blank')}
            type="primary"
            style={ButtonStyle}
          >
            Read more from Medium
          </Button>
        </ButtonWrap>
      </CardContent>
    </Cardwrap>
  )
}

const Cardwrap = styled(Card)`
  margin-left: 20px;
  flex: 1;
  @media screen and (max-width: 576px) {
    margin-top: 16px;
    margin-left: 0;
  }
`

const List = styled.div`
  width: 100%;
  li {
    padding: 15px 0 18px;
    border-bottom: 1px dashed #ececec;
    display: felx;
    flex-direction: column;
    line-height: 18px;
    font-size: 12px;
    align-items: center;
    list-style: none;
    p,
    span {
      &:hover {
        color: #fb966a;
      }
    }
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      span {
        color: #9da2ab;
      }
      div {
        flex: 1;
        p:last-child {
          margin-top: 10px;
          font-size: 12px;
          line-height: 12px;
          span:last-child {
            margin-left: 8px;
          }
        }
      }
      img {
        height: 38px;
        width: 38px;
        border-radius: 4px;
      }
    }
    p {
      width: 100%;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #292935;
      line-height: 14px;
    }
    /* span {
      display: block;
      margin-top: 6px;
    } */
  }
`

const ButtonWrap = styled.div`
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export default Announcement
