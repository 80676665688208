import { createReducer } from '@reduxjs/toolkit';
import {
  addMarket,
  batchAddMarket,
  clearMarkets,
  removeMarket
} from './actions';
import { CHAIN_IDS } from '../../pages/Lending/common/constants'

export const initialState = {
  [CHAIN_IDS.MAINNET]: {},
  [CHAIN_IDS.KOVAN]: {}
};

export default createReducer(initialState, builder => {
  builder
    .addCase(addMarket, (state, { payload }) => {
      const {
        chainId,
        market
      } = payload;
      return {
        ...state,
        [chainId]: {
          ...state[chainId],
          [market.cTokenAddress]: {
            ...market
          }
        }
      };
    })
    .addCase(batchAddMarket, (state, { payload }) => {
      const {
        chainId,
        markets
      } = payload;
      return {
        ...state,
        [chainId]: {
          ...state[chainId],
          ...markets.reduce((acc, m) => ({
            ...acc,
            [m.cTokenAddress]: m
          }), {})
        }
      };
    })
    .addCase(clearMarkets, (state, { payload }) => {
      const {
        chainId
      } = payload;
      const pre = {
        ...state
      };
      delete pre[chainId];
      return pre;
    })
    .addCase(removeMarket, (state, { payload: { address, chainId } }) => {
      const pre: any = {
        ...state[chainId]
      };
      delete pre[address];
      return {
        ...state,
        [chainId]: pre
      };
    });
});
