export default {
  getList: '/api/farms/getList', // 包含 Normal Farms, Double Farms, Staking, Investment, Vault 等列表数据
  getInvestmentAPY: '/api/farms/getInvestmentAPY', // 投资收益率
  getFarmAPY: '/api/farms/getFarmAPY', //
  getLendingAPY: '/api/farms/getLendingAPY',
  getDoubleFarmAPY: '/api/farms/getDoubleFarmAPY',
  getUNIV2APY: '/api/farms/getUNIV2APY',
  getStakingAPY: '/api/farms/getStakingAPY',
  getInvestmentDayBonus: '/api/farms/getInvestmentDayBonus',
  getInvestmentTotalBonus: '/api/farms/getInvestmentTotalBonus',
  getTopTokenHolders: '/api/farms/getTopTokenHolders',
  getTVLChartData: '/tvl/getData'
}
