import React, { useEffect, useState, useMemo } from 'react'
import Card from '../../components/Card'
import CardContent from '../../components/CardContent'
import CardTitle from '../../components/CardTitle'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import BalanceUtils from '../../fuck/formatBalance'
import CoinLogo from '../../components/CoinLogo'
import { LogoUtils } from '../../fuck/logoUtils'
import { useMedia } from 'react-use'
import { useTokens } from '../../state/tokens/hooks'
import { formattedPercent } from '../../utils'
import { TYPE } from '../../theme'
import { OVERVIEW_TOKEN_BLACKLIST } from '../../constants'

function TokenItem(payload: { [key: string]: any }) {
  const below1300 = useMedia('(max-width:1300px)')
  const below1450 = useMedia('(max-width:1450px)')
  const below576 = useMedia('(max-width:576px)')
  const history = useHistory()
  const tokenData = payload.TokenData
  const index: number = payload.index
  const liquidity = BalanceUtils.formattedNum(tokenData?.totalLiquidityUSD, true)
  const volume = BalanceUtils.formattedNum(tokenData?.oneDayVolumeUSD, true)
  const apy = formattedPercent(tokenData.priceChangeUSD).value
  return (
    <ListItem
      key={tokenData?.id && index}
      onClick={() => {
        history.push({
          pathname: `/token/${tokenData?.id}`,
          state: tokenData.id
        })
      }}
    >
      <span className="name">
        <div className="img-wrap">
          <CoinLogo key={`${tokenData?.id}img`} size={below576 ? 14 : 24} url={[LogoUtils.getTokenLogo(tokenData?.id)]} />
        </div>
        <span>
          <strong className="ellipsis">
            {
            tokenData.name.length > 8 ? below576 ? `${tokenData.name.slice(0, 8)}...` : below1450? `${tokenData.name.slice(0, 10)}...`:`${tokenData.name.slice(0, 20)}...` : tokenData.name
            }
          </strong>
        </span>
      </span>
      <span className="item">{tokenData.symbol}</span>
      <span className="item">{liquidity}</span>
      <span className="item">{volume}</span>
      {!below1300 && <span className="item">{BalanceUtils.formattedNum(tokenData.priceUSD, true)}</span>}
      {!below1300 && <span className={`item ${tokenData.priceChangeUSD < 0 ? 'red' : 'green'}`}>{apy}</span>}
    </ListItem>
  )
}
const SortFiled = {
  LIQ: 'totalLiquidityUSD',
  VOL: 'oneDayVolumeUSD',
  SYMBOL: 'symbol',
  NAME: 'name',
  PRICE: 'priceUSD',
  CHANGE: 'priceChangeUSD'
}
function TokensPage() {
  const below1300 = useMedia('(max-width:1300px)')
  const below576 = useMedia('(max-width:576px)')
  const [searchInfo, setSearchInfo] = useState<string>('')
  const ITEMS_PER_PAGE = 10
  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(SortFiled.LIQ)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const tokens = useTokens()
  useEffect(() => {
    setMaxPage(1) // edit this to do modular
    setPage(1)
  }, [tokens])

  const formattedTokens = useMemo(() => {
    return (
      tokens &&
      Object.keys(tokens)
        .filter(key => {
          return !OVERVIEW_TOKEN_BLACKLIST.includes(key)
        })
        .map(key => tokens[key])
    )
  }, [tokens])

  const filteredList = useMemo(() => {
    return (
      formattedTokens &&
      formattedTokens
        .sort((a: any, b: any) => {
          if (sortedColumn === SortFiled.SYMBOL || sortedColumn === SortFiled.NAME) {
            return a[sortedColumn] > b[sortedColumn] ? (sortDirection ? -1 : 1) * 1 : (sortDirection ? -1 : 1) * -1
          }
          return parseFloat(a[sortedColumn]) > parseFloat(b[sortedColumn])
            ? (sortDirection ? -1 : 1) * 1
            : (sortDirection ? -1 : 1) * -1
        })
        .filter((item: any) => {
          const token: string = item?.symbol.toLocaleLowerCase()
          return token.indexOf(searchInfo) !== -1
        })
      // .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
    )
  }, [formattedTokens, sortDirection, sortedColumn, searchInfo])
  useEffect(() => {
    let tokenLen
    if (searchInfo) {
      tokenLen = filteredList.length
    } else {
      tokenLen = formattedTokens.length
    }
    if (tokens && formattedTokens) {
      let extraPages = 1
      if (tokenLen % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(tokenLen / ITEMS_PER_PAGE) + extraPages)
    }
  }, [tokens, formattedTokens, ITEMS_PER_PAGE, filteredList, searchInfo])
  return (
    <Card>
      <CardContent>
        <CardTitle text={`${below576 ? '' : 'Trading tokens on Sashimiswap'}`}>
          <InputSearchWrap>
            <InputSearch
              type="text"
              placeholder="Find a token"
              maxLength={15}
              onKeyUp={event => {
                const val = event.currentTarget.value.toLocaleLowerCase().replace(/\s/g, '')
                setSearchInfo(val)
              }}
            />
            <SearchOutlined style={{ color: '#9DA2AB' }} className="search-icon" />
          </InputSearchWrap>
        </CardTitle>
        <ListItem className="title">
          <span
            className="item name"
            onClick={() => {
              setSortedColumn(SortFiled.NAME)
              setSortDirection(sortedColumn !== SortFiled.NAME ? true : !sortDirection)
            }}
          >
            Name {sortedColumn === SortFiled.NAME ? (!sortDirection ? '↑' : '↓') : ''}
          </span>
          <span
            className="item"
            onClick={() => {
              setSortedColumn(SortFiled.SYMBOL)
              setSortDirection(sortedColumn !== SortFiled.SYMBOL ? true : !sortDirection)
            }}
          >
            Symbol {sortedColumn === SortFiled.SYMBOL ? (!sortDirection ? '↑' : '↓') : ''}
          </span>

          <span
            className="item"
            onClick={() => {
              setSortedColumn(SortFiled.LIQ)
              setSortDirection(sortedColumn !== SortFiled.LIQ ? true : !sortDirection)
            }}
          >
            Liquidity {sortedColumn === SortFiled.LIQ ? (!sortDirection ? '↑' : '↓') : ''}
          </span>
          <span
            className="item"
            onClick={() => {
              setSortedColumn(SortFiled.VOL)
              setSortDirection(sortedColumn !== SortFiled.VOL ? true : !sortDirection)
            }}
          >
            Volume（24hrs）{sortedColumn === SortFiled.VOL ? (!sortDirection ? '↑' : '↓') : ''}
          </span>
          {!below1300 && (
            <span
              className="item"
              onClick={() => {
                setSortedColumn(SortFiled.PRICE)
                setSortDirection(sortedColumn !== SortFiled.PRICE ? true : !sortDirection)
              }}
            >
              Price {sortedColumn === SortFiled.PRICE ? (!sortDirection ? '↑' : '↓') : ''}
            </span>
          )}
          {!below1300 && (
            <span
              className="item"
              onClick={() => {
                setSortedColumn(SortFiled.CHANGE)
                setSortDirection(sortedColumn !== SortFiled.CHANGE ? true : !sortDirection)
              }}
            >
              Price Change (24hrs)
              {sortedColumn === SortFiled.CHANGE ? (!sortDirection ? '↑' : '↓') : ''}
            </span>
          )}
        </ListItem>
        {filteredList &&
          filteredList.slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE).map((item: any, index: number) => {
            return <TokenItem TokenData={item} index={index} key={'TokenItem' + index} />
          })}
      </CardContent>
      <ButtonWrap>
        <div onClick={() => setPage(page === 1 ? page : page - 1)}>
          <Arrow faded={page === 1 ? true : false}>←</Arrow>
        </div>
        <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
        <div onClick={() => setPage(page === maxPage ? page : page + 1)}>
          <Arrow faded={page === maxPage ? true : false}>→</Arrow>
        </div>
      </ButtonWrap>
    </Card>
  )
}
const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f2;
  color: #333;
  cursor: pointer;
  /* display:grid;
    grid-template-columns:1.5fr 1fr 1fr 1fr 1fr 1fr; */
  &:not(.title):hover {
    background-color: rgba(252, 133, 95, 0.06);
  }
  &.title {
    .item {
      &:first-child {
        cursor: default;
      }
      &:not(:first-child):hover {
        color: #333;
      }
    }
  }
  &.title {
    color: #9da2ab;
    border-bottom: none;
    white-space: nowrap;
  }
  .jump {
    width: 16px;
  }

  .item {
    flex: 1;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-weight: 500;
    &:first-child {
      display: flex;
      justify-self: flex-start;
      align-self: center;
    }
    &.green {
      color: #06b478;
    }
    &.red {
      color: #ff5d5d;
    }
  }
  .name {
    flex: 1.4;
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    span {
      display: flex;
      align-items: center;
      color: #333;
    }
    .img-wrap {
      margin-right: 18px;
      @media screen and (max-width: 576px) {
        margin-right: 8px;
      }
    }
  }
  strong {
    margin-right: 18px;
    @media screen and (max-width: 576px) {
      width: 80px;
      margin-right: 8px;
    }
  }
`
const InputSearchWrap = styled.div`
  position: relative;
  .search-icon {
    position: absolute;
    top: 11px;
    right: 20px;
  }
  @media screen and (max-width: 576px) {
    flex: 1;
  }
`
const InputSearch = styled.input`
  box-sizing: border-box;
  width: 360px;
  border: none;
  outline: none;
  line-height: 20px;
  padding: 10px 0;
  text-indent: 26px;
  border-radius: 20px;
  background-color: #f3f3f4;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
  &::placeholder {
    color: #b2b2b2;
    font-size: 14px;
  }
`
const ButtonWrap = styled.div`
  padding: 22px 0 34px 0;
  display: flex;
  justify-content: center;
`
const Arrow = styled.div`
  opacity: ${({ faded }: { faded: boolean }) => (faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`
export default TokensPage
