import { createReducer } from '@reduxjs/toolkit'
import { updateVaultsInfo, updateVaultsApy, VaultInfo, VaultApyInfo } from './actions'

export interface VaultState {
  vaults: {
    [key: string]: VaultInfo
  }
  apys: {
    [key: string]: VaultApyInfo
  }
}

const initialState: VaultState = {
  vaults: {},
  apys: {}
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateVaultsInfo, (state, { payload }) => {
      // console.log('3333----------vault updateVaultsInfo-------------------', payload)
      state.vaults = payload
    })
    .addCase(updateVaultsApy, (state, action) => {
      // console.log('3333----------vault updateVaultsApy-------------------', action.payload)
      state.apys = action.payload
    })
)
