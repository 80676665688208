import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { PairTransactionEntity } from '../entities/pair_transaction_entity'
import { GraphCommand, SubgraphClient as client } from '../../../api/apollo'
import { updateTransactionsByPairAddress } from '../actions'

/**
 * 获取交易对交易记录
 * @param pairAddress
 */
async function getPairTransactions(pairAddress: string): Promise<any> {
  try {
    return await client
      .query({
        query: GraphCommand.queryPairTransactions(),
        variables: {
          allPairs: [pairAddress]
        },
        fetchPolicy: 'no-cache'
      })
      .then<PairTransactionEntity>(result => result.data)
  } catch (e) {
    console.log(e)
  }
}

interface PairProps {
  address?: string
}

export const PairTransactionsUpdaterByAddress: React.FC<PairProps> = ({ address }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (!address) return
    getPairTransactions(address).then(result =>
      dispatch(
        updateTransactionsByPairAddress({
          address: address,
          data: result
        })
      )
    )
  }, [address, dispatch])

  return null
}
