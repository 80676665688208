import { createAction } from '@reduxjs/toolkit'
import { TokenEntity } from '../exchange/entities/token_entity'

export type TokenMarket = {
  now: { [key: string]: TokenEntity }
  oneDayAgo: { [key: string]: TokenEntity }
}

export interface SashimiInfo {
  sashimiOfTotalSupply: string
  sashimiOfBurned: string
  sashimiOfTreasury: string
}

export const updateSashimiInfo = createAction<SashimiInfo>('overview/updateSashimiInfo')
export const updateTokenMarkets = createAction<TokenMarket>('overview/updateTokenMarkets')
