import React, { useState } from 'react'
import { Row, Col } from 'antd'
import '../Compensation.less';
import Information from './Information'
import StakePanel from './StakePanel'
import RedeemPanel from './RedeemPanel'
import { TPanelInfo } from './index'

const RightCard: React.FC = () => {
  const [panelInfo, setPanelInfo] = useState<TPanelInfo>({
    left: true,
    information: true,
    // information: false,
    right: false
    // left: false,
    // information: false,
    // right: true
  });

  return (
    <Row className="right-container" justify="center" align="middle">
      <Col className="right-card">
        <Row className="header-buttons">
          <Col span={12}
               className={`header-btn left-btn ${panelInfo.left ? 'btn-active' : null}`}
               onClick={() => {
                 setPanelInfo({
                   ...panelInfo,
                   right: false,
                   left: true
                 });
               }}
          >Deposit LP Token</Col>
          <Col span={12}
               className={`header-btn right-btn ${panelInfo.right ? 'btn-active' : null}`}
               onClick={() => {
                 setPanelInfo({
                   ...panelInfo,
                   left: false,
                   right: true
                 });
               }}
          >Claim SASHIMI</Col>
        </Row>

        <div className="right-content-changeable">
          <Information panelInfo={panelInfo} setPanelInfo={setPanelInfo}/>
          <StakePanel panelInfo={panelInfo} setPanelInfo={setPanelInfo}/>
          <RedeemPanel panelInfo={panelInfo} setPanelInfo={setPanelInfo}/>
        </div>
      </Col>
    </Row>
  )
}

export default RightCard;
