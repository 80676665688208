import { TPoolsInfo, TConfig } from '../components'

const SASHIMI_ADDRESS = '0x89d5f2e52d36b85e72e25e8fe46d709d445c03fc';
// const LP_BLOCK_TAG = 29454670;
const LP_BLOCK_TAG = 13905777;

const LP_DECIMAL = 18;
const LP_DECIMAL_TENTH_POWER = 10 ** LP_DECIMAL;

const POOLS_INFO: TPoolsInfo = [
  {
    lpName: "SNX-ETH",
    lpToken: "0x4e8efbb0627d6816fe93a94430ed4b1e831fe4a1",
    pid: 6,
  },
  {
    lpName: "LAD-ETH",
    lpToken: "0x5c392ea8661f049f231269392bc57c7163a9fb17",
    shdPid: 5,
  },
  {
    lpName: "GAT-ETH",
    lpToken: "0xd16d65266a65f6b149de2849a1a36fe54693ad48",
    pid: 31,
  },
  {
    lpName: "LEND-ETH",
    lpToken: "0x6ab539bffd571aee39d6eaed50ba79d3627e2055",
    pid: 4
  },
  {
    lpName: "CKA-ETH",
    lpToken: "0x51482033417fc9cb7c591fa3d6bdec49f22ef441",
    shdPid: 2
  },
  {
    lpName: "EMSS-ETH",
    lpToken: "0x865d169f7a1d4e1d23a69717c26f26b99b1aa5b9",
    shdPid: 8
  },
  {
    lpName: "FMEC-ETH",
    lpToken: "0xf1515753ea6650ad8838acc0096f92b20136d286",
    shdPid: 7
  },
  {
    lpName: "MXC-ETH",
    lpToken: "0xbd61299162735bc01c56ea295776bff4a03e4a46",
    pid: 25
  },
  {
    lpName: "REN-ETH",
    lpToken: "0xf066f1dd4d7c0392eb7633b2c777644e09f0d3fb",
    pid: 20
  },
  {
    lpName: "BBPA-ETH",
    lpToken: "0xacd5c3eb9ed26b0c5d1b315d909dbbfbe233337a",
    shdPid: 3
  },
  {
    lpName: "AIU-ETH",
    lpToken: "0x851fca659738a13e90ff0d1dbc74bc1355bf4449",
    shdPid: 4
  },
  {
    lpName: "BIR-ETH",
    lpToken: "0x4a2cf6aaaf8679f123e09ab564cb28faac8c85f0",
    shdPid: 1
  },
  {
    lpName: "DAI-ETH",
    lpToken: "0x51214310ac356b26df2a9caf3895398e533c4fa9",
    pid: 2,
  },
  {
    lpName: "KNC-ETH",
    lpToken: "0xbde0cdab0a66378a400d2f5e3fde0de29a11ed56",
    pid: 19
  },
  {
    lpName: "LINK-ETH",
    lpToken: "0xde1621bc3e8be5927e8a8be068cea62f74f876ce",
    pid: 5
  },
  {
    lpName: "COMP-ETH",
    lpToken: "0x8767cc91765cb624777005cffebde6b2cf6481fb",
    pid: 46
  },
  {
    lpName: "USDC-ETH",
    lpToken: "0x64a9d29305b9847ceee21558d3ce1f8e85ee4496",
    pid: 1
  },
  {
    lpName: "SASHIMI-ETH",
    lpToken: "0x3fa4b0b3053413684d0b658689ede7907bb4d69d",
    pid: 9
  },
  {
    lpName: "ELF-ETH",
    lpToken: "0x28e240e04113877bf99354e1e4f43a79e59c535a",
    pid: 7
  },
  {
    lpName: "ETH-USDT",
    lpToken: "0x490ccb3c835597ff31e525262235487f9426312b",
    pid: 0
  },
  {
    lpName: "UNI-ETH",
    lpToken: "0xe24b430952bdb793b0e6753d14c4fec9b8a41813",
    pid: 33
  },
];

const EXCHANGE_DELEGATE_ADDRESS = '0x7eaeba417967614c08cd1f406909cdcde65f10f5';
const SHARDING_DAO_ADDRESS = '0x627Af3B7bB61CB5E96a1F31277eb387DeFe737A1';

export {
  SASHIMI_ADDRESS,
  EXCHANGE_DELEGATE_ADDRESS,
  LP_BLOCK_TAG,
  POOLS_INFO,
  LP_DECIMAL_TENTH_POWER,
  SHARDING_DAO_ADDRESS
};
