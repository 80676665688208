import { BigNumber } from 'bignumber.js';
import { createAction } from '@reduxjs/toolkit';

interface IMarket {
  borrowRate: BigNumber
  cTokenAddress: string
  collateralFactor: BigNumber
  distributionBorrowAPY: BigNumber
  distributionSupplyAPY: BigNumber
  liquidity: BigNumber
  marketTotalBorrow: BigNumber
  marketTotalBorrowInTokenUnit: BigNumber
  marketTotalSupply: BigNumber
  marketTotalSupplyInTokenUnit: BigNumber
  price: BigNumber
  sashimiApyOfBorrow: BigNumber
  sashimiApyOfSupply: BigNumber
  sashimiSpeed: BigNumber
  supplyRate: BigNumber
  symbol: string
  tokenDecimals: number
  underlyingAddress: string
  underlyingAmount: BigNumber
}

export const addMarket = createAction<{chainId: number, market: IMarket}>(
  'markets/addMarket'
);

export const batchAddMarket = createAction<{chainId: number, markets: IMarket[]}>(
  'markets/batchAddMarket'
);

export const clearMarkets = createAction<{chainId: number}>(
  'markets/clearMarkets'
);

export const removeMarket = createAction<{chainId: number, address: string}>(
  'markets/removeMarket'
);
