/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveWeb3React } from '../../hooks'
import {
  useContract,
  useMasterChefContract,
  useMulticallContract,
  useSashimiContract,
  useSashimiRouterContract,
  useVaultContract,
  useWETHContract
} from '../../hooks/useContract'
import BigNumber from 'bignumber.js'
import { FarmPoolApyInfo, FarmPoolInfo, LpSourceType, updateApys, updatePoolInfo } from './actions'
import { AddressZero } from '@ethersproject/constants'
import useIsWindowVisible from '../../hooks/useIsWindowVisible'
import { supportedPools } from '../../constants/sashimi/constants'
import { AppState } from '../index'
import { useBlockNumber } from '../application/hooks'
import LpBarAbi from '../../constants/sashimi/LPBar.json'
import { abi as STAKING_REWARDS_ABI } from '@uniswap/liquidity-staker/build/StakingRewards.json'
import { LpBarApyInfo } from '../lpBar/actions'
import { VaultApyInfo } from '../vault/actions'
import { useWeb3React } from '@web3-react/core'
import { useEtherPrice } from "../global/hooks";
import { getContract } from '../../utils'

// sashimi价格查询
const saAddr = '0xc28e27870558cf22add83540d2126da2e4b464c2' //TODO danie
// const saAddr = '0x4986843fde2b0dae6bdc54c8e16567614ea8786f' //kovan
const saEthPair = '0x3fa4b0b3053413684d0b658689ede7907bb4d69d' //TODO danie
const masterChefAddress = '0x1DaeD74ed1dD7C9Dabbe51361ac90A69d851234D' //TODO danie

export function useUpdatePoolInfoApi() {
  console.log('you should implement!!! fetch info from server ....')
}

//从区块获取 farm-pool 基础不变信息， 可以直接使用服务器useUpdatePoolInfoApi代替
export function useUpdatePoolInfo() {
  const { library, chainId } = useActiveWeb3React()
  const dispatch = useDispatch()

  const windowVisible = useIsWindowVisible()
  const multicallContract = useMulticallContract()
  const masterChefContract = useMasterChefContract()
  const sashimiContract = useSashimiContract()
  // attach/detach listeners
  useEffect(() => {
    if (!library || !chainId || !windowVisible) return undefined
    async function fetchPoolsInfo() {
      if (!multicallContract || !masterChefContract || !sashimiContract) return
      try {
        const queryChunk: any = [
          {
            address: masterChefContract.address,
            callData: masterChefContract?.interface.encodeFunctionData('poolLength', [])
          },
          {
            address: masterChefContract.address,
            callData: masterChefContract?.interface.encodeFunctionData('totalAllocPoint', [])
          },
          {
            address: masterChefContract.address,
            callData: masterChefContract?.interface.encodeFunctionData('sashimiPerBlock', [])
          }
        ]
        //step: 1、获取池子总数， 2、每个块产生的sashimi, 3、所有池子份额总和
        const totalResult = await multicallContract.aggregate(queryChunk.map((obj: any) => [obj.address, obj.callData]))
        const plResult = masterChefContract.interface.decodeFunctionResult('poolLength', totalResult[1][0])
        const poolLength = new BigNumber(plResult[0].toString()).toNumber()
        const totalAllocPointRessult = masterChefContract.interface.decodeFunctionResult(
          'totalAllocPoint',
          totalResult[1][1]
        )
        const totalAllocPoint = new BigNumber(totalAllocPointRessult[0].toString())
        const sashimiPerBlockResult = masterChefContract.interface.decodeFunctionResult(
          'sashimiPerBlock',
          totalResult[1][2]
        )
        const sashimiTotalPerBlock = new BigNumber(sashimiPerBlockResult[0].toString()).dividedBy(
          new BigNumber(10).pow(18)
        )

        //step: 初始化配置信息， 不同于区块信息， 配置一些vo相关以及 一些特殊的区块上没有的池子
        //TODO danie 目前未实现即将开始的倒计时池子
        const poolMap: { [key: number]: FarmPoolInfo } = supportedPools.reduce(
          (obj: { [key: number]: FarmPoolInfo }, curr: any) => ({
            ...obj,
            [curr.pid]: {
              pid: curr.pid,
              name: curr.name ?? '',
              lpAddress: curr.lpAddresses ?? '',
              lpSymbol: curr.lpSymbol ?? '',
              lpCoinUrls: curr.lpCoinUrls ? curr.lpCoinUrls : [], //TODO danie 临时添加的属性，可以直接从fetch信息里取到。
              sashimiPerBlock: 0,
              advanceLine: true,
              icon: curr.icon ?? '',
              lpSourceType: curr.lpSourceType ?? LpSourceType.SASWAP_FARM, //默认sashimi
              isInvestment: curr.isInvestment ?? false,
              isHide: curr.hide ?? false
            }
          }),
          {}
        )

        //step: 从区块获取每个池子的信息, 暂时没有超过500个的池子，一次性取
        const poolChunk: any = []
        for (let poolIndex = 0; poolIndex < poolLength; poolIndex++) {
          poolChunk.push({
            pid: poolIndex,
            address: masterChefContract.address,
            callData: masterChefContract?.interface.encodeFunctionData('poolInfo', [poolIndex])
          })
        }
        const poolsResult = await multicallContract.aggregate(poolChunk.map((obj: any) => [obj.address, obj.callData]))
        const lpChunk: any[] = []
        for (let poolIndex = 0; poolIndex < poolLength; poolIndex++) {
          const poolR = masterChefContract.interface.decodeFunctionResult('poolInfo', poolsResult[1][poolIndex])
          // const accSashimiPerShare = poolR.accSashimiPerShare as BigNumber
          // const lastRewardBlock = poolR.lastRewardBlock as BigNumber
          const allocPoint = new BigNumber(poolR.allocPoint.toString())
          const lpToken = poolR.lpToken
          if (poolMap[poolIndex]) {
            const pool = poolMap[poolIndex]
            pool.advanceLine = false
            pool.sashimiPerBlock = allocPoint
              .dividedBy(totalAllocPoint)
              .multipliedBy(sashimiTotalPerBlock)
              .toNumber()
            pool.lpAddress = lpToken
          }
          if (lpToken !== AddressZero && lpToken !== '' && poolMap[poolIndex]) {
            lpChunk.push({
              pid: poolIndex,
              address: lpToken,
              callData: sashimiContract?.interface.encodeFunctionData('decimals', [])
            })
          }
        }

        //step 获取lp信息
        const lpResult = await multicallContract.aggregate(lpChunk.map((obj: any) => [obj.address, obj.callData]))
        for (let lpIndex = 0; lpIndex < lpChunk.length; lpIndex++) {
          const obj = lpChunk[lpIndex]
          const lpR1 = sashimiContract.interface.decodeFunctionResult('decimals', lpResult[1][lpIndex])
          const decimal = lpR1[0] as number
          if (poolMap[obj.pid]) {
            poolMap[obj.pid].lpDecimal = decimal
          }
        }

        dispatch(
          updatePoolInfo({
            sashimiPerBlock: sashimiTotalPerBlock.toString(),
            totalAllocPoint: totalAllocPoint.toNumber(),
            pools: poolMap
          })
        )
      } catch (error) {
        console.log('fetchPoolsInfo', error)
      }
    }
    fetchPoolsInfo()
    return undefined
    //
  }, [dispatch, supportedPools, chainId, library, windowVisible])
}

export function useFetchFarmApyInfo() {
  const {library} = useActiveWeb3React()
  const dispatch = useDispatch()
  const farmPools = useSelector<AppState, AppState['poolInfos']['pools']>(state => state.poolInfos.pools)
  const vaultAPys = useSelector<AppState, AppState['vault']['apys']>(state => state.vault.apys)
  const lpBarApys = useSelector<AppState, AppState['lpBar']['apys']>(state => state.lpBar.apys)
  const multicallContract = useMulticallContract()
  const ethContract = useWETHContract()
  const vaultContract = useVaultContract()
  const sashimiRouterContract = useSashimiRouterContract()
  const { now: etherPrice } = useEtherPrice()
  const hasEtherPrice = etherPrice > 0
  const farmsContractObj:any = {};

  useEffect(() => {
    async function fetchUpdateApys() {
      //TODO danie pool 非空处理,
      if (!library || !multicallContract || !vaultContract || !ethContract || !sashimiRouterContract || !vaultAPys || !lpBarApys)
        return
      if (Object.keys(vaultAPys).length === 0 || Object.keys(lpBarApys).length === 0) return

      // ============ 初始化数据 ========
      // 非 Sashimi Swap
      // Sashimi Swap
      const lpStakedAmountChunk: any[] = []
      const saSwapChunk: any[] = []
      let sashimiIndex = 0
      let index = 0
      const onePerSaChunk = 3
      Object.values(farmPools).forEach(pool => {
        if (pool.advanceLine || pool.isHide || pool.lpAddress === AddressZero) {
          return
        }
        if (pool.lpSourceType === LpSourceType.SASWAP_FARM) {
          saSwapChunk.push(
            {
              pid: pool.pid,
              address: sashimiRouterContract.address,
              callData: sashimiRouterContract.interface.encodeFunctionData('getTokenInPair', [pool.lpAddress, saAddr])
            },
            {
              pid: pool.pid,
              address: sashimiRouterContract.address,
              callData: sashimiRouterContract.interface.encodeFunctionData('getTokenInPair', [
                pool.lpAddress,
                ethContract.address
              ])
            },
            {
              pid: pool.pid,
              address: sashimiRouterContract.address,
              callData: sashimiRouterContract.interface.encodeFunctionData('getTokenInPair', [
                pool.lpAddress,
                '0xdAC17F958D2ee523a2206206994597C13D831ec7', // Todo: usdt address
              ])
            }
          )
          if (pool.lpAddress.toLocaleLowerCase() === saEthPair.toLocaleLowerCase()) {
            sashimiIndex = index
          }
          index += onePerSaChunk
        }
        //查询池子中lp的数量
        lpStakedAmountChunk.push({
          pid: pool.pid,
          address: pool.lpAddress,
          callData: vaultContract.interface.encodeFunctionData('balanceOf', [masterChefAddress])
        })
        farmsContractObj[pool.lpAddress] = getContract(pool.lpAddress, STAKING_REWARDS_ABI, library, undefined)
      })

      const poolApysMap: { [key: number]: FarmPoolApyInfo } = {}

      saSwapChunk.push({
        address: saAddr,
        callData: ethContract.interface.encodeFunctionData('decimals', [])
      })
      // ========== 请求数据 ==============
      const [saSwapInfoRes, lpStakedAmountRes] = await Promise.all([
        multicallContract.aggregate(saSwapChunk.map((obj: any) => [obj.address, obj.callData])),
        multicallContract.aggregate(lpStakedAmountChunk.map((obj: any) => [obj.address, obj.callData]))
      ])

      // [sashimi]的精度
      const decimalsOfSashimi: number = ethContract.interface.decodeFunctionResult(
        'decimals',
        saSwapInfoRes[1][saSwapChunk.length - 1]
      )[0]

      // lp 地址持有的 [sashimi] 数量
      const _lpOwnedSashimiBalance = sashimiRouterContract.interface.decodeFunctionResult(
        'getTokenInPair',
        saSwapInfoRes[1][sashimiIndex]
      )[0]
      // lp 地址持有的 eth
      const _lpOwnedEthBalance = sashimiRouterContract.interface.decodeFunctionResult(
        'getTokenInPair',
        saSwapInfoRes[1][sashimiIndex + 1]
      )[0]
      const sashimiPrice = new BigNumber(_lpOwnedEthBalance.toString())
        .dividedBy(new BigNumber(10).pow(18))
        .dividedBy(new BigNumber(_lpOwnedSashimiBalance.toString()).dividedBy(new BigNumber(10).pow(decimalsOfSashimi)))

      // 解析 [lpStakedAmountRes] 的数据
      if (lpStakedAmountRes) {
        for (let realIndex = 0; realIndex < lpStakedAmountChunk.length; realIndex++) {
          const pool = farmPools[lpStakedAmountChunk[realIndex].pid]

          let valueInEth = 0
          let apy2 = 0
          switch (pool.lpSourceType) {
            case LpSourceType.LPBAR_FARM:
              const lpBarApy: LpBarApyInfo = lpBarApys[pool.lpAddress]
              valueInEth = lpBarApy.valueInEth
              apy2 = lpBarApy.apy
              break
            case LpSourceType.SASWAP_FARM:
              break
            case LpSourceType.VAULT_FARM:
              const vaultApyInfo: VaultApyInfo = vaultAPys[pool.lpAddress]
              valueInEth = vaultApyInfo.valueInEth
              apy2 = vaultApyInfo.apy
              break
          }

          // 计算自身年化
          const _stakeAmount = vaultContract.interface.decodeFunctionResult(
            'balanceOf',
            lpStakedAmountRes[1][realIndex]
          )[0]
          const _totalAmount = await farmsContractObj[pool.lpAddress].functions.totalSupply();
          const stakeAmount = new BigNumber(_stakeAmount.toString()).dividedBy(new BigNumber(10).pow(pool.lpDecimal))
          const stakePercent = new BigNumber(_stakeAmount.toString()).div(new BigNumber(_totalAmount.toString()))
          poolApysMap[pool.pid] = {
            valueInEth: new BigNumber(valueInEth).times(stakePercent).toNumber(),
            lpStakeAmount: stakeAmount.toString(),
            lpStakePercent: stakePercent.toString(),
            apy1: 0,
            apy2: apy2
          }
          // apy = masterChef持有的lp数量 / lp的总发行量 * lp的总价值
          if (pool.lpSourceType !== LpSourceType.SASWAP_FARM) {
            const apy =
              valueInEth === 0
                ? new BigNumber(0)
                : sashimiPrice
                    .times(new BigNumber(pool.sashimiPerBlock))
                    .times(new BigNumber(2336000))
                    .dividedBy(new BigNumber(valueInEth).times(poolApysMap[pool.pid].lpStakePercent))
                    .times(100)

            poolApysMap[pool.pid].apy1 = apy.toNumber()
          }
        }
      }
      // 解析 [saSwapInfoRes] 的数据
      if (saSwapInfoRes) {
        const loop = Math.floor(saSwapChunk.length / onePerSaChunk)
        for (let index = 0; index < loop; index++) {
          const realIndex = index * onePerSaChunk
          const pool = farmPools[saSwapChunk[realIndex].pid]
          const _lpOwnedSashimiBalance = sashimiRouterContract.interface.decodeFunctionResult(
            'getTokenInPair',
            saSwapInfoRes[1][realIndex]
          )[0]
          const _lpOwnedEthBalance = sashimiRouterContract.interface.decodeFunctionResult(
            'getTokenInPair',
            saSwapInfoRes[1][realIndex + 1]
          )[0]
          const _lpOwnedUSDTBalance = sashimiRouterContract.interface.decodeFunctionResult(
            'getTokenInPair',
            saSwapInfoRes[1][realIndex + 2]
          )[0]

          let lockedValueInEth: BigNumber;
          if (!_lpOwnedSashimiBalance.eq(0)) {
            lockedValueInEth = new BigNumber(_lpOwnedSashimiBalance.toString())
                .multipliedBy(2)
                .dividedBy(new BigNumber(10).pow(decimalsOfSashimi))
                .multipliedBy(sashimiPrice)
          } else if (!_lpOwnedEthBalance.eq(0)) {
            lockedValueInEth = new BigNumber(_lpOwnedEthBalance.toString()).multipliedBy(2).dividedBy(new BigNumber(1e18))
          } else {
            lockedValueInEth = new BigNumber(_lpOwnedUSDTBalance.toString()).multipliedBy(2).dividedBy(new BigNumber(1e6))
                .dividedBy(etherPrice)
          }

          let apy: BigNumber
          if (lockedValueInEth.eq(0)) {
            apy = new BigNumber(0)
          } else if (lockedValueInEth.lt(new BigNumber(1).dividedBy(1e8))) {
            apy = new BigNumber("141838.63") // Todo: 141838.63 from https://sashimi.cool/farms
          } else {
            apy = sashimiPrice
                .times(new BigNumber(pool.sashimiPerBlock))
                .times(new BigNumber(2336000))
                .dividedBy(lockedValueInEth.times(poolApysMap[pool.pid].lpStakePercent))
                .times(100)
          }

          poolApysMap[pool.pid].valueInEth = lockedValueInEth.times(poolApysMap[pool.pid].lpStakePercent).toNumber()
          poolApysMap[pool.pid].apy1 = apy.toNumber()
        }
      }
      dispatch(updateApys(poolApysMap))
    }

    if (hasEtherPrice) {
      fetchUpdateApys().catch(console.log)
    }
  }, [dispatch, vaultAPys, lpBarApys, hasEtherPrice])
}

export function useAllPendingSashimi() {
  const farmPools = useSelector<AppState, AppState['poolInfos']['pools']>(state => state.poolInfos.pools)
  const { account } = useActiveWeb3React()
  const masterChefContract = useMasterChefContract()
  const multicallContract = useMulticallContract()
  const lpBarContract = useContract('0x0000000000000000000000000000000000000001', LpBarAbi, false)
  const block = useBlockNumber()

  const [pendingSashimi, setPendingSashimi] = useState<BigNumber>(new BigNumber(0))

  useEffect(() => {
    async function queryAllPendingSashimi() {
      if (!multicallContract || !lpBarContract || !masterChefContract || !account) return
      if (Object.keys(farmPools).length === 0) return

      const pendingSaQueryChunk: any[] = []
      pendingSaQueryChunk.push({
        address: saAddr,
        callData: lpBarContract.interface.encodeFunctionData('decimals', [])
      })
      Object.values(farmPools).forEach(pool => {
        if (pool.isHide || pool.lpAddress === AddressZero || pool.lpSourceType === LpSourceType.LPBAR_FARM) {
          return
        }

        pendingSaQueryChunk.push({
          pid: pool.pid,
          address: masterChefContract.address,
          callData: masterChefContract.interface.encodeFunctionData('pendingSashimi', [pool.pid, account])
        })
      })

      const queryRes = await multicallContract.aggregate(
        pendingSaQueryChunk.map((obj: any) => [obj.address, obj.callData])
      )

      const decimals = lpBarContract.interface.decodeFunctionResult('decimals', queryRes[1][0])[0]

      return pendingSaQueryChunk
        .slice(1, pendingSaQueryChunk.length) // 第一条数据是查精度的，所以过滤
        .map((value, index) => {
          const data = queryRes[1][index + 1]

          const pendingSashimi = masterChefContract.interface.decodeFunctionResult('pendingSashimi', data)
          return new BigNumber(pendingSashimi.toString()).dividedBy(`1e${decimals}`)
        })
        .reduce((a, b) => a.plus(b))
    }

    queryAllPendingSashimi()
      .then(data => {
        if (data) setPendingSashimi(data)
      }).catch((err) => {
        console.warn('request fail', err)
      })
  }, [account, multicallContract, masterChefContract, lpBarContract, block, farmPools])

  return pendingSashimi
}

export function useEarningSashimi(pid: number) {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { account } = useWeb3React()
  const block = useBlockNumber()
  const masterChefContract = useMasterChefContract()

  const fetchBalance = useCallback(async () => {
    if (!masterChefContract || !account) return new BigNumber(0)

    const amount = await masterChefContract.pendingSashimi(pid, account)
    return new BigNumber(amount.toString())
  }, [masterChefContract, pid, account])

  useEffect(() => {
    fetchBalance()
      .then(data => setBalance(data))
      .catch(console.error)
  }, [account, block, setBalance, fetchBalance])

  return balance
}
