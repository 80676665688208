import React, { useCallback, useEffect } from 'react'
import { initialChartData, isBarChart, isCandles, PairChartData, PairChartPeriod } from './actions'
import dayjs from 'dayjs'
import { useWeb3React } from '@web3-react/core'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../index'
import { useCharShowType, useChartPairAddress, useChartShowPeriod } from './hooks'
import { getBlocksFromTimestamps, splitQuery } from '../common/hooks'
import { GraphCommand, SubgraphClient as client } from '../../api/apollo'
import { ChartEntity } from '../exchange/entities/chart_entity'
import { useBlockNumber } from '../application/hooks'

/**
 * 蜡烛图
 * @param pairAddress
 * @param startTime
 * @param latestBlock
 */
async function getHourlyRateData(
  pairAddress: string,
  startTime: number,
  latestBlock: number
): Promise<{ timestamp: any; open: number; close: number }[][]> {
  try {
    const utcEndTime = dayjs.utc()
    let time: number = startTime

    // create an array of hour start times until we reach current hour
    const timestamps: number[] = []
    while (time <= utcEndTime.unix() - 3600) {
      timestamps.push(time)
      time += 3600
    }

    // backout if invalid timestamp format
    if (timestamps.length === 0) {
      return []
    }

    // once you have all the timestamps, get the blocks for each timestamp in a bulk query
    let blocks: { timestamp: string; number: number }[]

    blocks = await getBlocksFromTimestamps(timestamps, 100)

    // catch failing case
    if (!blocks || blocks?.length === 0) {
      return []
    }

    if (latestBlock) {
      blocks = blocks.filter(({ number }) => number <= latestBlock)
    }

    const result = await splitQuery(GraphCommand.queryHourlyPairRates, client, [pairAddress], blocks, 100)

    // format token ETH price results
    const values: any[] = []
    for (const row in result) {
      const timestamp = row.split('t')[1]
      if (timestamp) {
        values.push({
          timestamp,
          rate0: parseFloat(result[row]?.token0Price),
          rate1: parseFloat(result[row]?.token1Price)
        })
      }
    }

    const formattedHistoryRate0: {
      timestamp: number
      open: number
      close: number
    }[] = []
    const formattedHistoryRate1: {
      timestamp: number
      open: number
      close: number
    }[] = []

    // for each hour, construct the open and close price
    for (let i = 0; i < values.length - 1; i++) {
      formattedHistoryRate0.push({
        timestamp: values[i].timestamp,
        open: parseFloat(values[i].rate0),
        close: parseFloat(values[i + 1].rate0)
      })
      formattedHistoryRate1.push({
        timestamp: values[i].timestamp,
        open: parseFloat(values[i].rate1),
        close: parseFloat(values[i + 1].rate1)
      })
    }

    return [formattedHistoryRate0, formattedHistoryRate1]
  } catch (e) {
    console.log(e)
    return [[], []]
  }
}

/**
 * 获取交易对的图表数据: 成交量、流动性
 * @param pairAddress
 */
async function getPairChartData(pairAddress: string): Promise<ChartEntity[]> {
  let data: ChartEntity[] = []
  const utcEndTime = dayjs.utc()
  const utcStartTime = utcEndTime.subtract(1, 'year').startOf('minute')
  const startTime = utcStartTime.unix() - 1

  try {
    let allFound = false
    let skip = 0
    while (!allFound) {
      const pairDayDatas: ChartEntity[] = await client
        .query({
          query: GraphCommand.queryPairChart(),
          variables: {
            pairAddress: pairAddress,
            skip
          },
          fetchPolicy: 'cache-first'
        })
        .then<ChartEntity[]>(result => result.data.pairDayDatas)
      skip += 1000
      data = data.concat(pairDayDatas)
      if (pairDayDatas.length < 1000) {
        allFound = true
      }
    }
    //
    const dayIndexSet: Set<string> = new Set()
    const dayIndexArray: ChartEntity[] = []
    const oneDay = 24 * 60 * 60
    data.forEach((dayData, i) => {
      // add the day index to the set of days
      dayIndexSet.add((data[i].date / oneDay).toFixed(0))
      dayIndexArray.push(data[i])
    })

    if (data[0]) {
      // fill in empty days
      let timestamp = data[0].date ? data[0].date : startTime
      let latestLiquidityUSD = data[0].reserveUSD
      let index = 1
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay
        const currentDayIndex = (nextDay / oneDay).toFixed(0)
        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            dailyVolumeToken0: '',
            dailyVolumeToken1: '',
            id: '',
            date: nextDay,
            dailyVolumeUSD: '0',
            reserveUSD: latestLiquidityUSD
          })
        } else {
          latestLiquidityUSD = dayIndexArray[index].reserveUSD
          index = index + 1
        }
        timestamp = nextDay
      }
    }

    data = data.sort((a, b) => (a.date > b.date ? 1 : -1))
  } catch (e) {
    console.log(e)
  }

  return data
}

/**
 * 图表数据更新器
 * @constructor
 */
export const Updater: React.FC<{ defaultAddress: string }> = ({ defaultAddress }) => {
  const { library } = useWeb3React()
  const dispatch = useDispatch<AppDispatch>()

  const address = useChartPairAddress() ?? defaultAddress
  const showType = useCharShowType()
  const showTypeIsBarChart: boolean = isBarChart(showType)
  const showTypeIsCandles: boolean = isCandles(showType)
  const showPeriod = useChartShowPeriod()
  const block = useBlockNumber()

  // 更新条形图数据，从非条形图切换到条形图的时候，进行更新
  const updateBarChartCallback = useCallback(
    async (pairAddress: string): Promise<{ [key: string]: PairChartData } | undefined> => {
      if (!showTypeIsBarChart) return

      const barChart = await getPairChartData(pairAddress)

      return {
        [pairAddress]: {
          barChart: barChart,
          candles: {}
        }
      }
    },
    [showTypeIsBarChart]
  )
  useEffect(() => {
    if (!address || address === '') return
    updateBarChartCallback(address)
      .then(data => {
        if (data) dispatch(initialChartData(data))
      })
      .catch(console.error)
  }, [dispatch, updateBarChartCallback, address])

  // 更新蜡烛图数据
  const updateCandlesCallback = useCallback(
    async (pairAddress: string): Promise<{ [key: string]: PairChartData } | undefined> => {
      if (!library) return
      if (!showTypeIsCandles) return

      const latestBlock: number = block ?? (await library.getBlockNumber())

      const currentTime = dayjs.utc()
      let startTime: number
      if (showPeriod === PairChartPeriod.ALL) {
        startTime = 1589760000
      } else {
        const s = showPeriod === PairChartPeriod.WEEK ? 'week' : 'month'
        startTime = currentTime
          .subtract(1, s)
          .startOf('hour')
          .unix()
      }

      const data = await getHourlyRateData(pairAddress, startTime, latestBlock)
      return {
        [pairAddress]: {
          barChart: undefined,
          candles: {
            [showPeriod.toString()]: [data?.[0], data?.[1]]
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [library, showTypeIsCandles, showPeriod]
  )
  useEffect(() => {
    if (!address || address === '') return
    updateCandlesCallback(address)
      .then(data => {
        if (data) dispatch(initialChartData(data))
      })
      .catch(console.error)
  }, [dispatch, updateCandlesCallback, address])

  return null
}
