import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'

import application from './application/reducer'
import global from './global/reducer'
import { updateVersion } from './global/actions'
import user from './user/reducer'
import transactions from './transactions/reducer'
import swap from './swap/reducer'
import mint from './mint/reducer'
import lists from './lists/reducer'
import burn from './burn/reducer'
import multicall from './multicall/reducer'
import exchange from './exchange/reducer'
import vault from './vault/reducer'
import pairChart from './pair-chart/reducer'
import poolInfos from './farm-pool/reducer'
import overview from './overview/reducer'
import lpBar from './lpBar/reducer'
import market from './markets/reducer'
import wallet from './wallet/reducer'
import tokens from './tokens/reducer'

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists']

const store = configureStore({
  reducer: {
    application,
    user,
    transactions,
    swap,
    mint,
    burn,
    multicall,
    lists,
    global,
    exchange,
    pairChart,
    vault,
    poolInfos,
    overview,
    lpBar,
    market,
    wallet,
    tokens
  },
  middleware: [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS })
})

store.dispatch(updateVersion())

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
