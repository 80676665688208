import React, { useMemo } from 'react'
import styled from 'styled-components'
import Card from '../../../../components/Card'
import CardContent from '../../../../components/CardContent'
import CardTitle from '../../../../components/CardTitle'
import BalanceUtils from '../../../../fuck/formatBalance'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import { useGlobalStatisticsData, useVaultIncomeOf24HByContract } from '../../../../state/global/hooks'
import GlobalChart from '../../../../components/GlobalChart'
// import BigNumber from 'bignumber.js'
// import { useSelector } from 'react-redux'
// import { AppState } from '../../../../state'
// import { useEtherPrice } from '../../../../state/global/hooks'
// import { useVaultApys } from '../../../../state/vault/hooks'
// import { FarmPoolApyInfo } from '../../../../state/farm-pool/actions'
// import { VaultApyInfo } from '../../../../state/vault/actions'
import { useTVLChartData } from '../../../../state/global/hooks'
const Locked: React.FC = () => {
  // const ethPrice = useEtherPrice()
  // const apysList = useSelector<AppState, AppState['poolInfos']['apys']>(state => state.poolInfos.apys)
  // const vaultApys = useVaultApys()

  // const statisticsData = useGlobalStatisticsData()
  // const liquidity = BalanceUtils.formattedNum(statisticsData.totalLiquidityUSD, true)
  // const income: BigNumber = useVaultIncomeOf24HByContract()

  // const _income95 = income.multipliedBy(new BigNumber(0.95))
  // const _income45 = income.multipliedBy(new BigNumber(0.045))
  // const _income5 = income.minus(_income95).minus(_income45)
  // const _income5 = income.minus(_income95)
  // const totalIncome = BalanceUtils.formattedNum(BalanceUtils.getBalanceNumber(income), true)
  // const vaultIncome = BalanceUtils.formattedNum(BalanceUtils.getBalanceNumber(income), true)
  // const income95 = BalanceUtils.formattedNum(BalanceUtils.getBalanceNumber(_income95), true)
  // const income45 = BalanceUtils.formattedNum(BalanceUtils.getBalanceNumber(_income45), true)
  // const income5 = BalanceUtils.formattedNum(BalanceUtils.getBalanceNumber(_income5), true)
  // const lockedVal: number = useMemo(() => {
  //   const totalFarmEth = Object.values(apysList).reduce((prev: number, cur: FarmPoolApyInfo) => {
  //     return prev + cur.valueInEth
  //   }, 0)
  //   const totalVaultEth = Object.values(vaultApys).reduce((prev: number, cur: VaultApyInfo) => {
  //     return prev + cur.valueInEth
  //   }, 0)
  //   return (totalFarmEth + totalVaultEth) * ethPrice?.now
  // }, [apysList, vaultApys, ethPrice])
  const chartData = useTVLChartData()
  const lockedVal: number = useMemo(() => {
    const data = chartData?.[0] || []
    return data[data.length-1]?.totalValueLocked || 0
  }, [chartData])
  
  return (
    <CardWarp>
      <CardContent>
        <CardTitle text="Total Value Locked">
          <LockedNum>{BalanceUtils.formattedNum(lockedVal, true)}</LockedNum>
        </CardTitle>
        <ChartContainer>
          <GlobalChart display="liquidity" />
        </ChartContainer>
        {/* <TextAreas>
          <p>
            We created <strong>≈{totalIncome}</strong> of wealth in the last 24hr.
          </p>
          <p>
            From saVault: <strong>≈{vaultIncome}</strong>
          </p>
          <p className="indent">
            - 95% for farmers: <strong>≈{income95}</strong>
          </p>
          <p className="indent">
            - 0.5% withdrawal fee for treasury and dev fund: <strong>≈{income5}</strong>
          </p>
          <p className="indent">
            - 4.5% performance fee for treasury: <strong>≈{income45}</strong>
          </p>
          <p>
            From Investment: <strong>--</strong>
          </p>
          <p className="indent">
            - 25% for xSASHIMI: <strong>--</strong>
          </p>
          <p className="indent">
            - 70% for treasury: <strong>--</strong>
          </p>
          <p className="indent">
            - Burned: <strong>--</strong>
          </p>
        </TextAreas> */}
      </CardContent>
    </CardWarp>
  )
}
const CardWarp = styled(Card)`
width: 50%;
`
const ChartContainer = styled.div`
  margin-top: 20px;
  padding-bottom: 20px;
  /* height: 282px; */
  border-bottom: 1px solid #f5f5f5;
`
const LockedNum = styled.span`
  font-size: 16px;
  color: #eb591a;
`
// const TextAreas = styled.div`
//   margin-top: 32px;
//   line-height: 18px;
//   color: #5a5a5a;
//   padding-bottom: 30px;
//   p {
//     margin-bottom: 0;
//   }
//   strong {
//     color: #fc855f;
//   }
//   .indent {
//     text-indent: 1em;
//   }
// `
export default Locked
