import enUS from 'antd/lib/locale/en_US'
import zhCN from 'antd/lib/locale/zh_CN'
const LANGUAGE = 'LANGUAGE'
const LOCAL_LANGUAGE = [
  { language: 'zh_cn', title: '简体中文' },
  { language: 'en', title: 'English' }
]
const LOCAL_LANGUAGE_LIST = ['zh_cn', 'en']
const DEFAULT_LANGUAGE = LOCAL_LANGUAGE_LIST[1]

const getLocalLanguage = () => {
  let lang = navigator.language
  if (typeof lang === 'string') {
    lang = lang.substr(0, 2)
  }
  return lang
}
const getLanguage = () => {
  // let language = DEFAULT_LANGUAGE

  // //Get whether the language has been set locally
  // const v = localStorage.getItem(LANGUAGE)
  // if (v && LOCAL_LANGUAGE_LIST.includes(v)) {
  //   language = v
  //   //Get browser language
  // } else if (getLocalLanguage() === 'zh') {
  //   language = LOCAL_LANGUAGE_LIST[0]
  // }
  return DEFAULT_LANGUAGE
}
const ANTD_LOCAL = {
  "zh_cn": zhCN,
  en: enUS
}
export { LANGUAGE, LOCAL_LANGUAGE, LOCAL_LANGUAGE_LIST, getLocalLanguage, ANTD_LOCAL, DEFAULT_LANGUAGE, getLanguage }
