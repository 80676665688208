// import Web3 from 'web3';
import { createReducer } from '@reduxjs/toolkit';
import {
  accountChanged,
  chainIdChanged,
  closeConnected,
  networkIdChanged,
  walletConnected,
  updateBalance,
  loginSettled
} from './actions';

// const defaultChainId = store.get('chain_id') || 1;
const defaultChainId = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1');

interface IWalletState {
  connected: boolean
  account: string | undefined
  chainId: number
  loginSettled: boolean
  balance: number
}

const initialState:IWalletState = {
  connected: false,
  account: undefined,
  chainId: defaultChainId,
  loginSettled: false,
  balance: 0
  // modify xjz
  // defaultWeb3: new Web3(NETWORK_URL[defaultChainId])
};

export default createReducer(initialState, builder => {
  builder
    .addCase(loginSettled, (state, action) => ({
      ...state,
      loginSettled: true,
      chainId: action.payload.chainId
    }))
    .addCase(walletConnected, (state, action) => {
      const {
        chainId,
        account,
      } = action.payload;
      return {
        ...state,
        loginSettled: true,
        connected: true,
        account,
        chainId,
      };
    })
    .addCase(chainIdChanged, (state, action) => {
      const {
        chainId
      } = action.payload;
      return {
        ...state,
        chainId,
      };
    })
    .addCase(accountChanged, (state, action) => {
      const {
        account
      } = action.payload;
      return {
        ...state,
        account
      };
    })
    .addCase(networkIdChanged, (state, action) => {
      const {
        networkId
      } = action.payload;
      return {
        ...state,
        networkId
      };
    })
    .addCase(updateBalance, (state, action) => ({
      ...state,
      balance:  action.payload 
    }))
    .addCase(closeConnected, () => initialState);
});
