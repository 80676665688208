/**
 * @file user market entrance
 * @author atom-yang
 */
import React, { useState, useMemo, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { Modal, Typography, message, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MarketInfoDefaultProps } from '../../common/constants';
import ListItem from '../ListItem';
import { useRefreshMarkets } from '../../hooks'
import { useCompound } from '../../utils';
import { addTransaction } from '../../../../state/transactions/actions';
import { IGlobalDetail, IBaseModalProps } from '../../types';
import { AppState } from '../../../../state';

const {
  Paragraph
} = Typography;

interface IEntrance extends IBaseModalProps { }

function getAddLimit(marketInfo: {
  supplyBalance: BigNumber
  collateralFactor: BigNumber
}, limitInfo: IGlobalDetail) {
  const {
    supplyBalance,
    collateralFactor
  } = marketInfo;
  const {
    totalBorrowBalance,
    totalBorrowLimit
  } = limitInfo;
  const marketLimit = new BigNumber(supplyBalance).times(collateralFactor);
  const afterLimit = new BigNumber(totalBorrowLimit).plus(marketLimit);
  const usedPercent = +afterLimit.eq(0) ? new BigNumber(0) : new BigNumber(totalBorrowBalance)
    .dividedBy(afterLimit)
    .times(100);
  return {
    afterLimit,
    usedPercent
  };
}

const EntranceModal: React.FC<IEntrance> = props => {
  const {
    symbol,
    visible,
    limitInfo,
    supplyBalance,
    collateralFactor,
    onCancel,
    onConfirm,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [hash, setHash] = useState('');
  const { complete } = useRefreshMarkets(hash);
  const {
    connected
  } = useSelector<AppState, AppState['wallet']>(state => state.wallet);
  const compound = useCompound();
  async function handleConfirm() {
    try {
      if (!connected) {
        message.error('You need to connect to your wallet to continue.');
        return;
      }
      setLoading(true);
      const tx = await compound.enterMarkets(symbol);
      setHash(tx.hash)
      dispatch(addTransaction(tx));
      // onConfirm();
    } catch (e) {
      setLoading(false);
      message.error((e.error || e).message || 'Send Transaction failed');
    }
  }

  useEffect(() => {
    if (complete) {
      setLoading(false)
      onConfirm()
    }
  }, [complete, onConfirm])

  const {
    usedPercent,
    afterLimit
  } = useMemo(() => getAddLimit({
    supplyBalance,
    collateralFactor
  }, limitInfo), [collateralFactor, limitInfo, supplyBalance]);

  return (
    <Modal
      visible={visible}
      title="Collateral"
      // okText={<>
      //   Enable <SymbolStyle>{symbol}</SymbolStyle> As Collateral
      // </>}
      okText={`Enable ${symbol} As Collateral`}
      okButtonProps={{
        loading
      }}
      maskClosable={!loading}
      destroyOnClose
      onOk={handleConfirm}
      onCancel={onCancel}
    >
      <Paragraph>
        Each asset used as collateral will increase your borrowing limit.
        Please note that this may cause the asset to be mortgaged in the liquidation.
      </Paragraph>
      <Divider />
      <ListItem
        title="Borrow Limit"
        desc={`$${limitInfo.totalBorrowLimit.toFixed(2)} -> $${afterLimit.toFixed(2)}`}
      />
      <Divider />
      <ListItem
        title="Borrow Limit Used"
        desc={`${limitInfo.totalBorrowLimitUsedPercent.toFixed(2)}% -> ${usedPercent.toFixed(2)}%`}
      />
    </Modal>
  );
};

EntranceModal.defaultProps = {
  ...MarketInfoDefaultProps
};

export default React.memo(EntranceModal);

// const SymbolStyle = styled.span`
//   color: #00FFFF;
// `