import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'

interface ICustomTooltip {
  title: string
  type?: 'normal' | 'warning'
}


const CustomTooltip: React.FC<ICustomTooltip> = ({title, type = 'normal'}) => {
  const defaultStyle = {
    verticalAlign: 'middle', fontSize: '18px', alignSelf: 'center', paddingLeft: '10px'
  }
  return <Tooltip
    title={title}
  >
    {type === 'warning' ?  <InfoCircleOutlined  style={{...defaultStyle, color: '#d46b08' }}/> : <QuestionCircleOutlined style={{...defaultStyle, color: 'rgba(0,0,0, 0.45)'}}/>}
  </Tooltip>
}

export default CustomTooltip