import React from 'react'
import styled from 'styled-components'

// eslint-disable-next-line react/prop-types
const MainContainer: React.FC = ({ children }) => {
  return <Wrap>{children}</Wrap>
}

const Wrap = styled.div`
  box-sizing: border-box;
  padding: 30px 40px;
  min-height: 100%;
  @media screen and (max-width: 576px) {
    padding: 16px;
  }
`
export default MainContainer
