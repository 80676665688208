import {
  initialChartData,
  PairChartData,
  PairChartPeriod,
  PairChartShowType,
  updateChartType,
  updateChartPeriod,
  updatePairAddress
} from './actions'
import { createReducer } from '@reduxjs/toolkit'

export interface ChartState {
  pairAddress: string | undefined | null
  // 显示周期
  showPeriod: PairChartPeriod
  // 显示类型
  showType: PairChartShowType
  readonly byPair: {
    readonly [address: string]: PairChartData
  }
}

const initialState: ChartState = {
  pairAddress: null,
  showType: PairChartShowType.Liquidity,
  showPeriod: PairChartPeriod.WEEK,
  byPair: {}
}

export default createReducer(initialState, builder =>
  builder
    .addCase(initialChartData, (state, { payload }) => {
      Object.keys(payload).forEach((address: string) => {
        if (state.byPair[address]) {
          const { barChart, candles }: PairChartData = payload[address]

          if (barChart) {
            state.byPair[address].barChart = barChart
          }

          state.byPair[address].candles = {
            ...state.byPair[address].candles,
            ...candles
          }
        } else {
          state.byPair[address] = payload[address]
        }
      })
    })
    .addCase(updateChartType, (state, { payload: { showType } }) => {
      return {
        ...state,
        showType: showType
      }
    })
    .addCase(updateChartPeriod, (state, { payload: { period } }) => {
      return {
        ...state,
        showPeriod: period
      }
    })
    .addCase(updatePairAddress, (state, { payload: { pairAddress } }) => {
      return {
        ...state,
        pairAddress: pairAddress
      }
    })
)
