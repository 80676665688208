import { useFetchGlobalDataCallback } from './hooks'
import { useEffect } from 'react'
import { PairData } from '../exchange/entities/pair_entity'
import { GraphCommand, SubgraphClient as client } from '../../api/apollo'
import ExchangeUtils from '../../fuck/exchangeUtils'
import DateTimeUtils from '../../fuck/formatDateTime'
import { getBlocksFromTimestamps } from '../common/hooks'
import { useDispatch } from 'react-redux'
import { useBlockNumber } from '../application/hooks'
import { initialPairs, updateEtherPrice } from './actions'
import dayjs from 'dayjs'

/**
 * Gets the current price  of ETH, 24 hour price, and % change between them
 */
export async function getEtherPrice(): Promise<{
  now: number
  oneDayAgo: number
  change: number
}> {
  const utcCurrentTime = dayjs()
  const utcOneDayBack = utcCurrentTime
    .subtract(1, 'day')
    .startOf('minute')
    .unix()

  let ethPrice = 0
  let ethPriceOneDay = 0
  let priceChangeETH = 0

  try {
    const [{ number: oneDayBlock }] = await getBlocksFromTimestamps([utcOneDayBack])

    const result = await client.query({
      query: GraphCommand.queryEthPrice(),
      fetchPolicy: 'cache-first'
    })
    const resultOneDay = await client.query({
      query: GraphCommand.queryEthPrice(oneDayBlock),
      fetchPolicy: 'cache-first'
    })
    const currentPrice = result?.data?.bundles[0]?.ethPrice ?? '0'
    const oneDayBackPrice = resultOneDay?.data?.bundles[0]?.ethPrice ?? '0'
    priceChangeETH = ExchangeUtils.getPercentChange(currentPrice, oneDayBackPrice)
    ethPrice = parseFloat(currentPrice)
    ethPriceOneDay = parseFloat(oneDayBackPrice)
  } catch (e) {
    console.log(e)
  }

  return {
    now: ethPrice,
    oneDayAgo: ethPriceOneDay,
    change: priceChangeETH
  }
}

/**
 * 根据区块高度获取交易对数据
 * @param address
 * @param blockHeight
 */
async function getPairDataForBlockHeight(address: string, blockHeight: number): Promise<PairData | null> {
  const queryResult: PairData[] = await client
    .query({
      query: GraphCommand.queryPairData(address, blockHeight),
      fetchPolicy: 'cache-first'
    })
    .then(result => result.data.pairs)

  return queryResult && queryResult.length > 0 ? queryResult[0] : null
}

/**
 * 解析计算交易对数据
 * @param data
 * @param oneDayData
 * @param twoDayData
 * @param oneWeekData
 * @param ethPrice
 * @param oneDayBlock
 */
function parsePairData(
  data: PairData,
  oneDayData: PairData,
  twoDayData: PairData,
  oneWeekData: PairData,
  ethPrice: any,
  oneDayBlock: number
): PairData {
  // get volume changes
  const [oneDayVolumeUSD, volumeChangeUSD] = ExchangeUtils.get2DayPercentChange(
    data?.volumeUSD,
    oneDayData?.volumeUSD ? oneDayData.volumeUSD : 0,
    twoDayData?.volumeUSD ? twoDayData.volumeUSD : 0
  )
  const [oneDayVolumeUntracked, volumeChangeUntracked] = ExchangeUtils.get2DayPercentChange(
    data?.untrackedVolumeUSD,
    oneDayData?.untrackedVolumeUSD ? oneDayData?.untrackedVolumeUSD : '0',
    twoDayData?.untrackedVolumeUSD ? twoDayData?.untrackedVolumeUSD : '0'
  )

  const _volumeUSD: number = parseFloat(data?.volumeUSD)
  const _oneWeekVolumeUSD: number = parseFloat(oneWeekData?.volumeUSD)
  const oneWeekVolumeUSD = _volumeUSD - _oneWeekVolumeUSD

  // set volume properties
  data.oneDayVolumeUSD = oneDayVolumeUSD.toString()
  data.oneWeekVolumeUSD = oneWeekVolumeUSD.toString()
  data.volumeChangeUSD = volumeChangeUSD.toString()
  data.oneDayVolumeUntracked = oneDayVolumeUntracked.toString()
  data.volumeChangeUntracked = volumeChangeUntracked.toString()

  // set liquiditry properties
  data.trackedReserveUSD = (parseFloat(data.trackedReserveETH) * ethPrice).toString()
  data.liquidityChangeUSD = ExchangeUtils.getPercentChange(data.reserveUSD, oneDayData?.reserveUSD).toString()

  // format if pair hasnt existed for a day or a week
  if (!oneDayData && data && data.createdAtBlockNumber > oneDayBlock) {
    data.oneDayVolumeUSD = data.volumeUSD
  }
  if (!oneDayData && data) {
    data.oneDayVolumeUSD = data.volumeUSD
  }
  if (!oneWeekData && data) {
    data.oneWeekVolumeUSD = data.volumeUSD
  }
  if (data?.token0?.id === '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2') {
    //TODO danie
    data.token0 = {
      ...data.token0,
      name: 'Ether (Wrapped)',
      symbol: 'ETH'
    }
  }
  if (data?.token1?.id === '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2') {
    //TODO danie
    data.token1 = {
      ...data.token1,
      name: 'Ether (Wrapped)',
      symbol: 'ETH'
    }
  }

  return data
}

/**
 * 批量获取交易对数据
 * @param pairs
 * @param ethPrice
 */
async function getBulkPairData(pairs: string[], ethPrice: any): Promise<{ [key: string]: PairData }> {
  const [t1, t2, tWeek] = DateTimeUtils.getTimestampsForChanges()
  const [{ number: b1 }, { number: b2 }, { number: bWeek }] = await getBlocksFromTimestamps([t1, t2, tWeek])

  // 请求数据
  const [pairsData, oneDayData, twoDayData, oneWeekData] = await Promise.all([
    client
      .query({
        query: GraphCommand.bulkQueryPairs(),
        variables: {
          allPairs: pairs
        },
        fetchPolicy: 'cache-first'
      })
      .then(result => result.data.pairs),
    ...[b1, b2, bWeek].map(async block => {
      return client
        .query({
          query: GraphCommand.bulkQueryHistoryPairData(pairs, block),
          fetchPolicy: 'cache-first'
        })
        .then<PairData[]>(result => result.data.pairs)
        .then<{ [key: string]: PairData }>(pairsData =>
          pairsData.reduce((obj: { [key: string]: PairData }, cur: PairData) => ({ ...obj, [cur.id]: cur }), {})
        )
    })
  ])

  // 解析数据
  const pairDataList: PairData[] = await Promise.all(
    pairsData &&
      pairsData.map(async (pair: PairData) => {
        const oneDayHistory = oneDayData?.[pair.id] ?? (await getPairDataForBlockHeight(pair.id, b1))
        const twoDayHistory = twoDayData?.[pair.id] ?? (await getPairDataForBlockHeight(pair.id, b2))
        const oneWeekHistory = oneWeekData?.[pair.id] ?? (await getPairDataForBlockHeight(pair.id, bWeek))
        return parsePairData(pair, oneDayHistory, twoDayHistory, oneWeekHistory, ethPrice, b1)
      })
  )

  return pairDataList.reduce((obj: { [key: string]: PairData }, cur: PairData) => ({ ...obj, [cur.id]: cur }), {})
}

export default function Updater(): null {
  const dispatch = useDispatch()

  const block = useBlockNumber() ?? 0
  const needRefresh = block % 6 === 0

  // Eth 价格
  useEffect(() => {
    if (!needRefresh) return
    getEtherPrice()
      .then(data => dispatch(updateEtherPrice(data)))
      .catch(console.error)
  }, [dispatch, needRefresh])

  // 获取交易对信息
  // Todo: zbinnny 这里只在初始化的时候获取一次数据，往后再取数据的话，有可能会发生数据错乱的问题(24小时成交量为负数)
  useEffect(() => {
    async function fetchPairs(): Promise<{ [key: string]: PairData } | undefined> {
      const [{ now }, pairs] = await Promise.all([
        getEtherPrice(),
        client
          .query<any>({
            query: GraphCommand.queryPairs(),
            fetchPolicy: 'cache-first'
          })
          .then<string[]>(result => result.data.pairs.map((pair: any) => pair.id))
      ])

      if (!now || !pairs) return undefined

      return await getBulkPairData(pairs, now)
    }

    fetchPairs()
      .then(pairData => {
        if (pairData) dispatch(initialPairs(pairData))
      })
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetch = useFetchGlobalDataCallback()
  useEffect(() => {
    fetch().catch(console.error)
  }, [fetch])

  return null
}
