import { createAction } from '@reduxjs/toolkit'
import { PairData } from './entities/pair_entity'
import { PairTransactionEntity } from './entities/pair_transaction_entity'
import { TokenEntity } from './entities/token_entity'

export const updateTokens = createAction<{ [key: string]: TokenEntity }>('exchange/updateTokens')
export const updatePairs = createAction<{ [key: string]: PairData }>('exchange/updatePairs')
export const updateTransactionsByPairAddress = createAction<{ address: string; data: PairTransactionEntity }>(
  'exchange/updateTransactionsByAddress'
)
