import { updateVaultsApy, updateVaultsInfo, VaultApyInfo, VaultInfo, VaultStrategyType } from './actions'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo } from 'react'
import { useMulticallContract, useVaultContract, useWETHContract } from '../../hooks/useContract'
import { vaults as VaultsData } from '../../constants/sashimi/constants'
import { BigNumber as BN } from 'bignumber.js'
import { BigNumber, Contract } from 'ethers'
import { AppState } from '../index'
import server from '../../request/server'
import { queryTokenPrice } from '../../hooks/useTokenPrice'
import { LpSourceType } from '../farm-pool/actions'
import { calculateGasMargin } from '../../hooks/useSashimi'

//从区块获取 vault 基础不变信息， 可以直接使用服务器useUpdateVaultInfoApi代替
export function useUpdateVaultInfo() {
  const dispatch = useDispatch()

  const multicallContract = useMulticallContract()
  const vaultContract = useVaultContract()

  // 初始化 Vault 配置信息
  useEffect(() => {
    async function fetchVaultInfo() {
      if (!multicallContract || !vaultContract) return

      const vaultsMap: { [key: string]: VaultInfo } = {}
      const poolChunk: any[] = []
      VaultsData.forEach((value: any) => {
        vaultsMap[value.vaultIdAddr] = {
          vaultIdAddr: value.vaultIdAddr,
          strategyType: value.strategyType,
          tokenName: value.tokenName,
          lpAddress: value?.lpAddress,
          decimals: 0,
          controller: '',
          icon: value.icon,
          apyIcon: value.apyIcon
        }
        poolChunk.push(
          {
            address: value.vaultIdAddr,
            callData: vaultContract.interface.encodeFunctionData('token', [])
          },
          {
            address: value.vaultIdAddr,
            callData: vaultContract.interface.encodeFunctionData('decimals', [])
          },
          {
            address: value.vaultIdAddr,
            callData: vaultContract.interface.encodeFunctionData('controller', [])
          }
        )
      })

      const poolsResult = await multicallContract.aggregate(poolChunk.map((obj: any) => [obj.address, obj.callData]))

      const vaultsLength: number = VaultsData.length
      const lengthPerChunk = poolChunk.length / vaultsLength
      for (let index = 0; index < vaultsLength; index++) {
        const realIndex = index * lengthPerChunk
        if (vaultsMap[poolChunk[realIndex].address]) {
          const vault = vaultsMap[poolChunk[realIndex].address]
          const returnData = poolsResult[1]

          const lpAddress = vaultContract.interface.decodeFunctionResult('token', returnData[realIndex])[0]
          const decimals = vaultContract.interface.decodeFunctionResult('decimals', returnData[realIndex + 1])[0]
          const controller = vaultContract.interface.decodeFunctionResult('controller', returnData[realIndex + 2])[0]

          vaultsMap[vault.vaultIdAddr] = {
            ...vault,
            lpAddress,
            decimals,
            controller
          }
        }
      }
      dispatch(updateVaultsInfo(vaultsMap))
    }
    fetchVaultInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VaultsData, dispatch, multicallContract, vaultContract])
}

export function useUpdateVaultApyOfApi() {
  console.log('从api接口请求，未实现...')
}

const market = 'https://markets.dforce.network/api/v2/getApy/'
const mine = 'https://api.dforce.network/api/getRoi/'

export function useUpdateVaultApy() {
  const dispatch = useDispatch()

  const vaults = useSelector<AppState, AppState['vault']['vaults']>(state => state.vault.vaults)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const uniVaults: VaultInfo[] = []
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dfVaults: VaultInfo[] = []
  Object.values(vaults).forEach(vault => {
    if (vault.strategyType === VaultStrategyType.VAULT_STRATEGY_UNI) {
      uniVaults.push(vault)
    } else if (vault.strategyType === VaultStrategyType.VAULT_STRATEGY_DF) {
      dfVaults.push(vault)
    }
  })

  const multicallContract = useMulticallContract()
  const vaultContract = useVaultContract()
  const ethContract = useWETHContract()

  const getDfVaultsApy = useCallback(async () => {
    if (!multicallContract || !vaultContract || !ethContract) return
    if (uniVaults.length + dfVaults.length === 0) return

    // uni价格查询
    const uniAddress = '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984' //TODO danie
    const uniEthPairAddr = '0xd3d2e2692501a5c9ca623199d38826e513033a17'
    const uniPriceQueryChunk: any = [
      {
        address: uniAddress,
        callData: ethContract.interface.encodeFunctionData('decimals', [])
      },
      {
        address: uniAddress,
        callData: ethContract.interface.encodeFunctionData('balanceOf', [uniEthPairAddr])
      },
      {
        address: ethContract.address,
        callData: ethContract.interface.encodeFunctionData('balanceOf', [uniEthPairAddr])
      }
    ]

    // 构建 uni apy 查询块
    const uniApyQueryChunk: any = []
    for (let index = 0; index < uniVaults.length; index++) {
      const _uniVault = uniVaults[index]
      uniApyQueryChunk.push(
        {
          address: _uniVault.vaultIdAddr,
          callData: vaultContract.interface.encodeFunctionData('totalSupply', [])
        },
        {
          address: _uniVault.lpAddress,
          callData: ethContract.interface.encodeFunctionData('decimals', [])
        },
        {
          address: _uniVault.vaultIdAddr,
          callData: vaultContract.interface.encodeFunctionData('balance', [])
        },
        {
          address: _uniVault.lpAddress,
          callData: vaultContract.interface.encodeFunctionData('totalSupply', [])
        },
        {
          address: ethContract.address,
          callData: ethContract.interface.encodeFunctionData('balanceOf', [_uniVault.lpAddress])
        }
      )
    }

    // 构建 df 信息查询块([vault]的总发行量, lp的精度, [vault]持有的[lp]数量)
    const dfInfoQueryChunk: any = []
    const dfPriceQueryChunk: Promise<{ [key: string]: number }>[] = []
    for (let index = 0; index < dfVaults.length; index++) {
      const _dfVault = dfVaults[index]
      dfInfoQueryChunk.push(
        {
          address: _dfVault.vaultIdAddr,
          callData: vaultContract.interface.encodeFunctionData('totalSupply', [])
        },
        {
          address: _dfVault.lpAddress,
          callData: vaultContract.interface.encodeFunctionData('decimals', [])
        },
        {
          address: _dfVault.vaultIdAddr,
          callData: vaultContract.interface.encodeFunctionData('balance', [])
        }
      )
      dfPriceQueryChunk.push(
        queryTokenPrice(_dfVault.tokenName.toUpperCase(), 'ETH').then<{ [key: string]: number }>(res => ({
          [_dfVault.tokenName]: res.data?.ETH ?? 0
        }))
      )
    }

    const allChunk: any[] = [...uniPriceQueryChunk, ...uniApyQueryChunk, ...dfInfoQueryChunk]
    // 查询 uniApy信息, df相关信息
    const [allChunkRes, dfPrices, marketApys, mineApys] = await Promise.all([
      multicallContract.aggregate(allChunk.map((obj: any) => [obj.address, obj.callData])),
      // multicallContract.aggregate(uniApyQueryChunk.map((obj: any) => [obj.address, obj.callData])),
      // multicallContract.aggregate(dfInfoQueryChunk.map((obj: any) => [obj.address, obj.callData])),
      Promise.all(dfPriceQueryChunk),
      server.get(market).then(res => res.data),
      server.get(mine).then(res => res.data)
    ])

    const vaultsApy: { [key: string]: VaultApyInfo } = {}

    // // [uni]的精度
    // const decimalsOfUniLp: number = ethContract.interface.decodeFunctionResult('decimals', allChunkRes[1][0])[0]
    // // lp 地址持有的 uni 数量
    // const uniLpOwnedUniBalance = ethContract.interface.decodeFunctionResult('balanceOf', allChunkRes[1][1])[0]
    // // lp 地址持有的 eth
    // const uniLpOwnedEthBalance = ethContract.interface.decodeFunctionResult('balanceOf', allChunkRes[1][2])[0]
    //
    // const uniPrice: BN = new BN(uniLpOwnedEthBalance.toString())
    //   .dividedBy(new BN(1e18))
    //   .dividedBy(new BN(uniLpOwnedUniBalance.toString()).dividedBy(new BN(10).pow(decimalsOfUniLp)))

    // 计算 uni Apys 结果
    if (allChunkRes) {
      const oneChunkLength = 5
      for (let index = 0; index < uniApyQueryChunk.length / oneChunkLength; index++) {
        const realIndex: number = index * oneChunkLength + uniPriceQueryChunk.length
        const vault: VaultInfo = uniVaults[index]
        const returnData = allChunkRes[1]

        const _totalSupplyOfVault: BigNumber = vaultContract.interface.decodeFunctionResult(
          'totalSupply',
          returnData[realIndex]
        )[0]
        const totalSupplyOfVault: BN = new BN(_totalSupplyOfVault.toString()).dividedBy(new BN(10).pow(vault.decimals))

        // [uni lp]精度
        const decimalsOfLp: number = vaultContract.interface.decodeFunctionResult(
          'decimals',
          returnData[realIndex + 1]
        )[0]

        // vault 持有的 [uni lp] 数量
        const _vaultOwnedLpBalance: BigNumber = vaultContract.interface.decodeFunctionResult(
          'balance',
          returnData[realIndex + 2]
        )[0]
        const vaultOwnedLpBalance: BN = new BN(_vaultOwnedLpBalance.toString()).dividedBy(new BN(10).pow(decimalsOfLp))

        // [uni lp] 的总发行量
        const _totalSupplyOfLp: BigNumber = vaultContract.interface.decodeFunctionResult(
          'totalSupply',
          returnData[realIndex + 3]
        )[0]
        const totalSupplyOfLp: BN = new BN(_totalSupplyOfLp.toString()).dividedBy(new BN(10).pow(decimalsOfLp))

        // [uni lp] 的价值: uniLp池子持有的Eth数量 * 2
        const _lpValueInEth: BigNumber = ethContract.interface.decodeFunctionResult(
          'balanceOf',
          returnData[realIndex + 4]
        )[0]
        const lpValueInEth: BN = new BN(_lpValueInEth.toString()).dividedBy(new BN(10).pow(new BN(18))).times(new BN(2))

        // roi = uniPrice * blockPerYear * uniLpPerBlock / lpValueInEth * 100%
        // const blockPerYear = new BN(2336000) // 233600: Eth每年产生的块数
        // const uniLpPerBlock = new BN(13.5) // 13.5: 每块产生的 uni 数量
        // const roi: BN = uniPrice
        //   .times(blockPerYear)
        //   .times(uniLpPerBlock)
        //   .dividedBy(new BN(lpValueInEth.toString()))
        //   .times(100)

        // vault持有的[uni lp]总价值 = vault持有的[uni lp]数量 / [uni lp]的总发行量 * [uni lp]的总价值
        const vaultOwnedLpEthValue: BN = vaultOwnedLpBalance.dividedBy(totalSupplyOfLp).times(lpValueInEth)

        vaultsApy[vault.vaultIdAddr] = {
          vaultIdAddr: vault.vaultIdAddr,
          valueInEth: vaultOwnedLpEthValue.toNumber(),
          totalSupply: totalSupplyOfVault.toNumber(),
          apy: 0, // roi.toNumber(),
          apy2: 0
        }
      }
    }

    // 计算 Df Apy 结果
    if (allChunkRes && dfPrices && mineApys && marketApys) {
      const dfPriceMap: { [key: string]: number } = dfPrices.reduce(
        (a: { [key: string]: number }, b: { [key: string]: number }) => {
          return {
            ...a,
            ...b
          }
        },
        {}
      )

      const oneChunkLength = 3
      for (let index = 0; index < dfInfoQueryChunk.length / oneChunkLength; index++) {
        const realIndex: number = index * oneChunkLength + uniPriceQueryChunk.length + uniApyQueryChunk.length
        const vault: VaultInfo = dfVaults[index]

        // 计算apy
        const apyKey = `d${vault.tokenName}`
        const mineApy: BN = new BN(mineApys[apyKey]).times(new BN(100))
        const marketApy: BN = new BN(marketApys[apyKey]?.now_apy ?? 0)
        const apy: number = mineApy.plus(marketApy).toNumber()

        // vault的总发行量
        const _totalSupplyOfVault: BigNumber = vaultContract.interface.decodeFunctionResult(
          'totalSupply',
          allChunkRes[1][realIndex]
        )[0]
        const totalSupplyOfVault: BN = new BN(_totalSupplyOfVault.toString()).dividedBy(new BN(10).pow(vault.decimals))

        // lp的精度
        const lpDecimals: number = vaultContract.interface.decodeFunctionResult(
          'decimals',
          allChunkRes[1][realIndex + 1]
        )[0]

        // vault 持有的 lp 数量
        const _vaultOwnedLpBalance: BigNumber = vaultContract.interface.decodeFunctionResult(
          'balance',
          allChunkRes[1][realIndex + 2]
        )[0]
        const vaultOwnedLpBalance: BN = new BN(_vaultOwnedLpBalance.toString()).dividedBy(new BN(10).pow(lpDecimals))
        const lpPrice = dfPriceMap[vault.tokenName]
        const valueInEth = vaultOwnedLpBalance.times(lpPrice)

        vaultsApy[vault.vaultIdAddr] = {
          vaultIdAddr: vault.vaultIdAddr,
          valueInEth: valueInEth.toNumber(),
          totalSupply: totalSupplyOfVault.toNumber(),
          apy,
          apy2: 0
        }
      }
    }

    return vaultsApy
  }, [uniVaults, dfVaults, multicallContract, vaultContract, ethContract])

  useEffect(() => {
    getDfVaultsApy()
      .then(data => {
        if (data) dispatch(updateVaultsApy(data))
      })
      .catch(console.error)
  }, [dispatch, getDfVaultsApy])
}

export function useVaultApys(): { [key: string]: VaultApyInfo } {
  const farmPools = useSelector<AppState, AppState['poolInfos']['pools']>(state => state.poolInfos.pools)
  const farmApys = useSelector<AppState, AppState['poolInfos']['apys']>(state => state.poolInfos.apys)
  const vaultOriginApys = useSelector<AppState, AppState['vault']['apys']>(state => state.vault.apys)

  // 获取 farm 的年化
  const vaultApys = useMemo((): { [key: string]: VaultApyInfo } => {
    if (
      Object.keys(farmPools).length === 0 ||
      Object.keys(farmApys).length === 0 ||
      Object.keys(vaultOriginApys).length === 0
    )
      return vaultOriginApys

    const vaultApysMap: { [key: string]: VaultApyInfo } = {}

    Object.values(farmPools).forEach(poolInfo => {
      if (poolInfo.lpSourceType !== LpSourceType.VAULT_FARM) return

      const farmApy = farmApys[poolInfo.pid]
      if (farmApy) {
        vaultApysMap[poolInfo.lpAddress] = {
          ...vaultOriginApys[poolInfo.lpAddress],
          apy2: farmApy.apy1
        }
      }
    })

    return vaultApysMap
  }, [farmPools, farmApys, vaultOriginApys])

  return useMemo(() => vaultApys ?? {}, [vaultApys])
}

export default function useVaultContractHandler(
  contract: Contract | null
): {
  deposit: (amount: string, decimals: number) => Promise<any>
  withdraw: (amount: string, decimals: number) => Promise<any>
  balanceOf: (account: string | null | undefined, decimals: number) => Promise<BN>
} {
  // 充值
  const deposit = useCallback(
    async (amount: string, decimals = 18): Promise<any> => {
      if (!contract) {
        console.error('contract is null')
        return
      }

      if (!contract.signer) {
        console.error('signer is null')
        return
      }

      const _amount = new BN(amount).times(new BN(10).pow(decimals))
      const estimatedGas = await contract.estimateGas.deposit(_amount.toString())
      return await contract.deposit(_amount.toString(), {
        gasLimit: calculateGasMargin(estimatedGas)
      })
    },
    [contract]
  )

  // 提款
  const withdraw = useCallback(
    async (amount: string, decimals = 18): Promise<any> => {
      if (!contract) {
        console.error('contract is null')
        return
      }

      if (!contract.signer) {
        console.error('signer is null')
        return
      }

      const _amount = new BN(amount).times(new BN(10).pow(decimals))
      const estimatedGas = await contract.estimateGas.withdraw(_amount.toString())
      return await contract.withdraw(_amount.toString(), {
        gasLimit: calculateGasMargin(estimatedGas)
      })
    },
    [contract]
  )

  const balanceOf = useCallback(
    async (account: string | null | undefined, decimals = 18): Promise<BN> => {
      if (!contract) {
        return new BN(0)
      }

      if (!account) {
        return new BN(0)
      }

      const balance: BigNumber = await contract.balanceOf(account)

      return new BN(balance.toString()).div(new BN(10).pow(decimals))
    },
    [contract]
  )

  return useMemo(
    () => ({
      deposit,
      withdraw,
      balanceOf
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract, deposit, withdraw, balanceOf]
  )
}
