import React, { useState } from 'react'
import styled from 'styled-components'
import { useMedia } from 'react-use'
import { Button, Drawer } from 'antd'
import { MenuOutlined, CloseOutlined } from '@ant-design/icons'
import MainLogo from '../MainLogo'
import Web3Status from '../Web3Status'
import Menu from '../Menu/Menu'
import Footer from '../Footer/Footer'
const SiderBar: React.FC = () => {
  // const below576 = useMedia('(max-width:576px)')
  const below576 = useMedia('(max-width:768px)')
  const [visible, setVisible] = useState<boolean>(false)
  return (
    <SiderContainer>
      <div className="account-wrap">
        <MainLogo />
        <Web3Status />
        {below576 && (
          <Button
            onClick={() => {
              setVisible(true)
            }}
            style={{ marginRight: '16px' }}
            type="primary"
            icon={<MenuOutlined style={{ color: '#fff' }} />}
          />
        )}
        {below576 && visible && (
          <Drawer
            title="SashimiSwap"
            placement="right"
            closable={true}
            onClose={() => {
              setVisible(false)
            }}
            visible={visible}
            getContainer={false}
            closeIcon={
              <CloseOutlined
                onClick={() => {
                  setVisible(false)
                }}
              />
            }
            style={{ position: 'absolute' }}
            bodyStyle={{ padding: 0 }}
          >
            <Menu />
          </Drawer>
        )}
      </div>
      {!below576 && <Menu />}
      {!below576 && <Footer />}
    </SiderContainer>
  )
}
const SiderContainer = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: space-between;
  // @media screen and (max-width: 576px) {
  @media screen and (max-width: 768px) {
    .account-wrap {
      height: 72px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`

export default SiderBar
