import { useUpdateVaultApy, useUpdateVaultInfo } from './hooks'

/**
 * 获取 saVault 的信息列表
 */
export default function Updater() {
  useUpdateVaultInfo()
  useUpdateVaultApy()
  return null
}
