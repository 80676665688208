import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
`;

interface IEnableTips {
  symbol: string
}


const EnableTips: React.FC<IEnableTips> = ({ symbol }) => (
  <StyledContainer>
    To Supply or Repay {symbol} to the Sashimi Loan Protocol, you need to enable it first.
  </StyledContainer>
);

export default EnableTips;
