import { createAction } from '@reduxjs/toolkit'

export const UPDATE_TOP_TOKENS = createAction<any>('tokens/updateTopTokens')

export const UPDATE = createAction<any>('tokens/update')

export const UPDATE_TOKEN_TXNS = createAction<any>('tokens/updateTokenTxns')

export const UPDATE_CHART_DATA = createAction<any>('tokens/updateChartData')

export const UPDATE_PRICE_DATA = createAction<any>('tokens/updatePriceData')

export const UPDATE_ALL_PAIRS = createAction<any>('tokens/updateAllPairs')

export const TOKEN_PAIRS_KEY = createAction<any>('tokens/updatePairsKey')
