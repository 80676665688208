import { createReducer } from '@reduxjs/toolkit'
import { LpBarApyInfo, LpBarInfo, updateLpBarApys, updateLpBarsInfo } from './actions'

export interface LpBarPoolState {
  lpBarsInfo: {
    [key: string]: LpBarInfo
  }
  apys: {
    [key: string]: LpBarApyInfo
  }
}

const initialState: LpBarPoolState = { lpBarsInfo: {}, apys: {} }

export default createReducer(initialState, builder =>
  builder
    .addCase(updateLpBarsInfo, (state, { payload }) => {
      // console.log('2222----------lpbar updateLpBarsInfo-------------------', payload)
      state.lpBarsInfo = payload
    })
    .addCase(updateLpBarApys, (state, { payload }) => {
      // console.log('22222---------lpbar updateLpBarApys-------------------', payload)
      state.apys = payload
    })
)
