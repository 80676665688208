// import { parse, ParsedQs } from 'qs'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export default function useParsedQueryString(): any {
  // const { search } = useLocation()
  const params = useParams()
  return useMemo(
    // () => (search && search.length > 1 ? parse(search, { parseArrays: false, ignoreQueryPrefix: true }) : {}),
    () => params,
    [params]
  )
}
