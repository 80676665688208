import { TokenEntity } from './token_entity'
import { LogoUtils } from '../../../fuck/logoUtils'

export interface PairData {
  createdAtBlockNumber: number
  createdAtTimestamp: string
  id: string
  liquidityChangeUSD: string
  oneDayVolumeUSD: string
  oneDayVolumeUntracked: string
  oneWeekVolumeUSD: string
  reserve0: string
  reserve1: string
  reserveETH: string
  reserveUSD: string
  token0: TokenEntity
  token0Price: string
  token1: TokenEntity
  token1Price: string
  totalSupply: string
  trackedReserveETH: string
  trackedReserveUSD: string
  txCount: string
  untrackedVolumeUSD: string
  volumeChangeUSD: string
  volumeChangeUntracked: string
  volumeUSD: string
}

export class PairUtils {
  public static getPairLogo(pair: PairData): string[] {
    return pair && pair.token0 && pair.token1
      ? [LogoUtils.getTokenLogo(pair.token0.id), LogoUtils.getTokenLogo(pair.token1.id)]
      : []
  }

  // 分隔
  public static getPairString(pair: PairData, separate = '-'): string {
    return pair && pair.token0 && pair.token1 ? (pair.token0.symbol + separate + pair.token1.symbol).toUpperCase() : ''
  }
}
