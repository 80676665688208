import PropTypes from 'prop-types';
import BigNumber from 'bignumber.js';

export const CHAIN_IDS = {
  MAINNET: 1,
  ROPSTEN: 3,
  KOVAN: 42
};

export const NETWORK_URL = {
  [CHAIN_IDS.MAINNET]: `https://mainnet.infura.io/v3/${process.env.INFURA_ID}`,
  [CHAIN_IDS.KOVAN]: `https://kovan.infura.io/v3/${process.env.INFURA_ID}`,
  [CHAIN_IDS.ROPSTEN]: `https://ropsten.infura.io/v3/${process.env.INFURA_ID}`
};

export const AddressZero = '0x0000000000000000000000000000000000000000';

export const ProviderChainData = {
  [CHAIN_IDS.MAINNET]: {
    name: 'Ethereum Mainnet',
    short_name: 'eth',
    chain: 'ETH',
    network: 'mainnet',
    chain_id: 1,
    network_id: 1,
    rpc_url: NETWORK_URL[CHAIN_IDS.MAINNET],
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: 0
    }
  },
  [CHAIN_IDS.KOVAN]: {
    name: 'Ethereum Kovan',
    short_name: 'kov',
    chain: 'ETH',
    network: 'kovan',
    chain_id: 42,
    network_id: 42,
    rpc_url: NETWORK_URL[CHAIN_IDS.KOVAN],
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: 0
    }
  },
  [CHAIN_IDS.ROPSTEN]: {
    name: 'Ethereum Ropsten',
    short_name: 'rop',
    chain: 'ETH',
    network: 'ropsten',
    chain_id: 3,
    network_id: 3,
    rpc_url: NETWORK_URL[CHAIN_IDS.ROPSTEN],
    native_currency: {
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: '18',
      contractAddress: '',
      balance: 0
    }
  }
};

export const SAFE_MAX = 0.8;

export const gasLimit = '250000';
export const gasLimitSupplyDai = '535024';
export const gasLimitSupplySnx = '450000';
export const gasLimitWithdrawDai = '550000';
export const gasLimitWithdrawSnx = '550000';
export const gasLimitWithdraw = '450000';
export const gasLimitEnable = '70000';
export const gasLimitEnableDai = '66537';
export const gasLimitBorrow = '702020';
export const gasLimitBorrowDai = '729897';
export const gasLimitRepayDai = '535024';


export const willRemovedTokenAddressList: {
 [key: string] : {removeTime: string}
} = {
  '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e': {
    removeTime: ''
  },
  '0xc28e27870558cf22add83540d2126da2e4b464c2': {
    removeTime: ''
  }
}
export const CHAIN_DATAS = {
  [CHAIN_IDS.MAINNET]: {},
  [CHAIN_IDS.KOVAN]: {}
};

export const MarketInfoProps = {
  cTokenAddress: PropTypes.string.isRequired,
  underlyingAddress: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
  supplyRate: PropTypes.instanceOf(BigNumber).isRequired,
  borrowRate: PropTypes.instanceOf(BigNumber).isRequired,
  marketTotalSupply: PropTypes.instanceOf(BigNumber).isRequired,
  marketTotalBorrowInTokenUnit: PropTypes.instanceOf(BigNumber).isRequired,
  marketTotalBorrow: PropTypes.instanceOf(BigNumber).isRequired,
  underlyingAmount: PropTypes.instanceOf(BigNumber).isRequired,
  price: PropTypes.instanceOf(BigNumber).isRequired,
  liquidity: PropTypes.instanceOf(BigNumber).isRequired,
  collateralFactor: PropTypes.instanceOf(BigNumber).isRequired,
  sashimiSpeed: PropTypes.instanceOf(BigNumber).isRequired,
  distributionSupplyAPY: PropTypes.instanceOf(BigNumber).isRequired,
  distributionBorrowAPY: PropTypes.instanceOf(BigNumber).isRequired,
  tokenDecimals: PropTypes.number.isRequired,
  entered: PropTypes.bool,
  allowance: PropTypes.instanceOf(BigNumber),
  enabled: PropTypes.bool,
  walletBalance: PropTypes.instanceOf(BigNumber),
  supplyBalanceInTokenUnit: PropTypes.instanceOf(BigNumber),
  supplyBalance: PropTypes.instanceOf(BigNumber),
  borrowBalanceInTokenUnit: PropTypes.instanceOf(BigNumber),
  borrowBalance: PropTypes.instanceOf(BigNumber),
  cTokenBalance: PropTypes.instanceOf(BigNumber)
};

export const MarketInfoDefaultProps = {
  entered: false,
  allowance: new BigNumber(0),
  enabled: false,
  walletBalance: new BigNumber(0),
  supplyBalanceInTokenUnit: new BigNumber(0),
  supplyBalance: new BigNumber(0),
  borrowBalanceInTokenUnit: new BigNumber(0),
  borrowBalance: new BigNumber(0),
  cTokenBalance: new BigNumber(0)
};

export const GlobalInfoProps = {
  totalSupplyBalance: PropTypes.instanceOf(BigNumber),
  totalBorrowBalance: PropTypes.instanceOf(BigNumber),
  totalBorrowLimit: PropTypes.instanceOf(BigNumber),
  totalBorrowLimitUsedPercent: PropTypes.instanceOf(BigNumber),
  yearlySupplyInterest: PropTypes.instanceOf(BigNumber),
  yearlyBorrowInterest: PropTypes.instanceOf(BigNumber),
  netApy: PropTypes.instanceOf(BigNumber)
};

export const GlobalInfoDefaultProps = {
  totalSupplyBalance: new BigNumber(0),
  totalBorrowBalance: new BigNumber(0),
  totalBorrowLimit: new BigNumber(0),
  totalBorrowLimitUsedPercent: new BigNumber(0),
  yearlySupplyInterest: new BigNumber(0),
  yearlyBorrowInterest: new BigNumber(0),
  netApy: new BigNumber(0)
};
