import React from 'react'
import { Button} from 'antd'
import '../Compensation.less';
import { InformationProps } from './index'

const Information: React.FC<InformationProps>
  = ({
       panelInfo,
       setPanelInfo
     }) => {

  return (
    <div className={`information ${panelInfo.information ? null : 'display-none'}`}>
      <div className="info-logo-container info-logo-container-h20">
        {/*<div className="sushi-big">🍣</div>*/}
      </div>
      <div>You have 90 days to deposit your LP token in return of the SASHIMI distribution. Undeposited LP tokens will not count for share of SASHIMI distribution. The distribution plan is available for LP token that received before the block height at 13905777. Depositing LP token that received after the block height at 13905777 does NOT meet the requirement of participating in the distribution plan. If you deposited unqualified LP token, the deposited LP token would be forfeited. The subsequent lost will be responsible to user who deposited.</div>
      <div className="info-blank-24"/>
      <div>There is an 180-day period to claim SASHIMI. 10 days after you deposit the qualified LP token, the correspondent SASHIMI token will be sent on a constant scale every 24-hour. If the LP token were deposited after the start of the distribution plan, LP will receive their share of SASHIMI token on the 10th day after they deposited their LP token. The receiving amount will be equal to their share from the day the distribution plan started. Sooner or later the LP token was deposited, LP will receive the same amount of distributed SASHIMI token.</div>
      <div className="info-blank-44"/>
      <Button
        type="primary"
        className="button-full-width"
        onClick={() => {
          setPanelInfo({
            ...panelInfo,
            information: false,
          });
        }}
      >Confirm</Button>
    </div>
  )
}

export default Information;
