import React from 'react'
import styled from 'styled-components'
import Card from '../../../../components/Card'
import CardContent from '../../../../components/CardContent'
import CardTitle from '../../../../components/CardTitle'
import BalanceUtils from '../../../../fuck/formatBalance'
import { useGlobalStatisticsData } from '../../../../state/global/hooks'
import GlobalChart from '../../../../components/GlobalChart'
// import { useSashimiEstimate } from '../../../../state/overview/hooks'
const Locked: React.FC = () => {
  const statisticsData: any = useGlobalStatisticsData()

  // 总成交量
  const totalVolume = BalanceUtils.formattedNum(statisticsData.totalVolumeUSD, true)

  // 24小时成交量
  // const oneDayVolumeUSD: number | string = statisticsData?.oneDayVolumeUSD ?? 0
  // const volume24: number =
  //   typeof oneDayVolumeUSD === 'string'
  //     ? parseFloat(statisticsData.oneDayVolumeUSD)
  //     : statisticsData.oneDayVolumeUSD ?? 0
  // const fee24: string = BalanceUtils.formattedNum(volume24 * 0.003, true)
  // const saLp: string = BalanceUtils.formattedNum(volume24 * 0.0025, true)
  // const xSashimi: string = BalanceUtils.formattedNum(volume24 * 0.0005, true)
  // const { xSashimiUSD, burnedUSD, treasuryUSD } = useSashimiEstimate()

  return (
    <Wrap>
      <Card>
        <CardContent>
          <CardTitle text="Exchange Volume(Total)">
            <ExchangeNum>{totalVolume}</ExchangeNum>
          </CardTitle>
          <ChartContainer>
            <GlobalChart display="volume" />
          </ChartContainer>
          {/* <TextAreas>
            <p>
              We got trading fees <strong>≈{fee24} (0.3%)</strong> in the last 24hr.
            </p>
            <p className="indent">
              - 0.25% for SALP: <strong>≈{saLp}</strong>
            </p>
            <p className="indent">
              - 0.05% for xSASHIMI: <strong>≈{xSashimi}</strong>(not implemented yet.)
            </p>
          </TextAreas> */}
        </CardContent>
      </Card>

      {/* <Bottom>
        <div>
          <h2>≈{BalanceUtils.formattedNum(xSashimiUSD.toNumber(), true)}</h2>
          <p>xSASHIMI Profit</p>
        </div>
        <div>
          <h2>≈{BalanceUtils.formattedNum(treasuryUSD.toNumber(), true)}</h2>
          <p>Treasury Profit</p>
        </div>
        <div>
          <h2>≈{BalanceUtils.formattedNum(burnedUSD.toNumber(), true)}</h2>
          <p>Burned</p>
        </div>
      </Bottom> */}
    </Wrap>
  )
}

const Wrap = styled.div`
  flex: 1;
  margin-left: 20px;
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 576px) {
    margin-left: 0;
    margin-top: 16px;
  }
`

const ExchangeNum = styled.span`
  font-size: 16px;
  color: #eb591a;
`
const ChartContainer = styled.div`
  position: relative;
  margin-top: 20px;
  /* height: 242px; */
`

// const TextAreas = styled.div`
//   margin-top: 16px;
//   line-height: 18px;
//   color: #5a5a5a;
//   padding-bottom: 30px;
//   p {
//     margin-bottom: 0;
//   }
//   strong {
//     color: #fc855f;
//   }
//   .indent {
//     text-indent: 1em;
//   }
// `
// const Bottom = styled(Card)`
//   margin-top: 30px;
//   text-align: center;
//   display: flex;
//   flex-direction: initial;
//   justify-content: space-around;
//   align-items: center;
//   min-height: 106px;
//   h2 {
//     color: #fc855f;
//     font-size: 24px;
//     line-height: 30px;
//   }
//   p {
//     margin-top: 8px;
//     margin-bottom: 0;
//     font-size: 14px;
//     color: #6d7278;
//     line-height: 20px;
//   }
// `
export default Locked
