import React from 'react'
import CoinLogo from '../../../../components/CoinLogo'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import * as tokenIcons from '../../../../constants/sashimi/tokenIcons';
import { LogoUtils } from '../../../../fuck/logoUtils';
import { AppState } from '../../../../state';

interface ISymbolCoinIcon {
  tokenSymbol: string
  size?: number
  tokenAddress?: string
  warningHint?: boolean
}

const SymbolCoinIcon: React.FC<ISymbolCoinIcon> = ({ tokenSymbol, size = 25, warningHint = false ,tokenAddress = '' }) => {
  const { chainId } = useSelector<AppState, AppState['wallet']>(state => state.wallet);
  const logoUrl = chainId === 42 ? { ...tokenIcons }[tokenSymbol.toLowerCase() as keyof typeof tokenIcons] : LogoUtils.getTokenLogo(tokenAddress === '0xETH' ? '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2' : tokenAddress);
  return <CoinContainer>
    <CoinLogo size={size} url={[logoUrl || '']} preview={false} />
    <h2 style={{...warningHint ? {color: "red"}: {}} }>{tokenSymbol}</h2>
  </CoinContainer>
}

export default SymbolCoinIcon

const CoinContainer = styled.div`
  display: flex;
  div {
    align-self: center;
  }
  h2 {
    color: #333;
    font-size: 18px;
    margin-left: 10px;
  }
`