/**
 * @file borrow modal
 * @author atom-yang
 */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import BigNumber from 'bignumber.js';
import {
  If,
  Then,
  Else
} from 'react-if';
import {
  Divider,
  Modal,
  Tabs,
  Row,
  Col,
  Button,
  message,
  Input
} from 'antd';
import { Web3Provider } from '@ethersproject/providers';
import Compound from '@sashimiswap/compound-js';
import ListItem from '../ListItem';
import {
  approveToken,
} from '../../common/utils';
import './index.less';
import {
  SAFE_MAX,
  GlobalInfoDefaultProps,
  MarketInfoDefaultProps,
} from '../../common/constants';
import EnableTips from '../EnableTips';
import WarningTips from '../WarningTips';
import SymbolCoinIcon from '../SymbolCoinIcon'
import { addTransaction } from '../../../../state/transactions/actions';
import { useCompound } from '../../utils';
import { useActiveWeb3React } from '../../../../hooks';
import { useRefreshMarkets } from '../../hooks'
import { IBaseModalProps, IGlobalDetail } from '../../types'
import { AppState } from '../../../../state';

interface IBorrowProps extends IBaseModalProps {
  willRomoveToken: boolean
}

const BORROW_TABS = {
  BORROW: 'BORROW',
  REPAY: 'REPAY'
};

function getOkProps(loading: boolean, currentTab: string, walletBalance: BigNumber, enable: boolean) {
  let okText = 'REPAY';
  let maxText = 'MAX';
  let disabled = false;
  if (currentTab === 'BORROW') {
    okText = 'BORROW';
    maxText = 'SAFE MAX';
  }
  if (currentTab === BORROW_TABS.REPAY && !enable) {
    okText = 'ENABLE';
  } else if (currentTab === BORROW_TABS.REPAY && walletBalance.eq(0)) {
    okText = 'NO FUNDS AVAILABLE';
    disabled = true;
  }
  return {
    loading,
    okText,
    maxText,
    disabled
  };
}

function getNewLimit(
  value: string,
  action: string,
  limitInfo: IGlobalDetail,
  price: BigNumber
) {
  const {
    totalBorrowLimit,
    totalBorrowBalance
  } = limitInfo;
  const coef = action === BORROW_TABS.BORROW ? 1 : -1;
  const addBorrowBalance = new BigNumber(value || 0).times(coef);
  let newTotalBorrowBalance = totalBorrowBalance.plus(new BigNumber(addBorrowBalance).times(price));
  newTotalBorrowBalance = newTotalBorrowBalance.lt(0) ? new BigNumber(0) : newTotalBorrowBalance;
  return {
    newTotalBorrowBalance,
    newUsed: +totalBorrowLimit.eq(0) ? totalBorrowLimit : new BigNumber(newTotalBorrowBalance)
      .dividedBy(totalBorrowLimit)
      .times(100)
  };
}

function getSafeBorrow(limitInfo: IGlobalDetail, price: BigNumber, underlyingAmount: BigNumber) {
  const {
    totalBorrowLimit,
    totalBorrowBalance
  } = limitInfo;
  const safeLimit = new BigNumber(totalBorrowLimit).times(SAFE_MAX);
  let diff = safeLimit.minus(totalBorrowBalance);
  diff = diff.gte(0) ? diff.dividedBy(price) : new BigNumber(0);
  return diff.gt(underlyingAmount) ? underlyingAmount : diff;
}

function getMaxBorrow(limitInfo: IGlobalDetail, price: BigNumber, underlyingAmount: BigNumber) {
  const {
    totalBorrowLimit,
    totalBorrowBalance
  } = limitInfo;
  let diff = totalBorrowLimit.minus(totalBorrowBalance);
  diff = diff.dividedBy(price);
  return diff.gt(underlyingAmount) ? underlyingAmount : diff;
}

const UINT_NEGATIVE_ONE = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

const MaxAbi = [{
  constant: false,
  inputs: [
    {
      name: 'borrower',
      type: 'address'
    },
    {
      name: 'cEther_',
      type: 'address'
    }
  ],
  name: 'repayBehalfExplicit',
  outputs: [],
  payable: true,
  stateMutability: 'payable',
  type: 'function'
}];

function repayETHFull(
  amount: string,
  walletBalance: BigNumber,
  borrowApy: BigNumber,
  network: string,
  borrower: string,
  slTokenAddress: string,
  provider: Web3Provider
) {
  const value = new BigNumber(borrowApy).dividedBy(100).plus(1).times(amount);
  const minValue = BigNumber.min(value, walletBalance).toFixed(0);
  const contract = new Compound._ethers.Contract(
    Compound.util.getAddress('Maximillion', network),
    MaxAbi,
    provider.getSigner()
  );
  return contract.repayBehalfExplicit(borrower, slTokenAddress, {
    value: minValue
  });
  // return Compound.eth.trx(
  //   Compound.util.getAddress('Maximillion', network),
  //   'repayBehalfExplicit(address,address)',
  //   [borrower, slTokenAddress], // [optional] parameters
  //   {
  //     network,
  //     provider,
  //     value,
  //     abi: [{
  //       constant: false,
  //       inputs: [
  //         {
  //           name: 'borrower',
  //           type: 'address'
  //         },
  //         {
  //           name: 'cEther_',
  //           type: 'address'
  //         }
  //       ],
  //       name: 'repayBehalfExplicit',
  //       outputs: [],
  //       payable: true,
  //       stateMutability: 'payable',
  //       type: 'function'
  //     }],
  //   } // [optional] call options, provider, network, ethers.js "overrides"
  // );
}

const BorrowModal: React.FC<IBorrowProps> = props => {
  const {
    cTokenAddress,
    underlyingAddress,
    enabled,
    visible,
    symbol,
    price,
    tokenDecimals,
    borrowRate,
    underlyingAmount,
    limitInfo,
    walletBalance,
    distributionBorrowAPY,
    onCancel,
    onConfirm,
    borrowBalanceInTokenUnit,
    willRomoveToken
  } = props;
  const {
    totalBorrowLimitUsedPercent,
    totalBorrowBalance,
    totalBorrowLimit
  } = limitInfo;
  const compound = useCompound();
  const dispatch = useDispatch();
  const [hash, setHash] = useState('');
  const { complete } = useRefreshMarkets(hash);
  const { library } = useActiveWeb3React();
  const wallet = useSelector<AppState, AppState['wallet']>(state => state.wallet);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>(BORROW_TABS.BORROW);
  const [input, setInput] = useState<string>('0');
  const okProps = useMemo(() => getOkProps(loading, currentTab, walletBalance, !!enabled), [
    loading,
    currentTab,
    walletBalance,
    enabled
  ]);

  const newLimit = useMemo(() => getNewLimit(
    input,
    currentTab,
    limitInfo,
    price,
  ), [
    input,
    currentTab,
    limitInfo,
    price
  ]);

  async function handleConfirm() {
    const { chainId, account, connected } = wallet;
    if (!account || !connected) {
      message.error('You need to connect to your wallet to continue.');
      return;
    }
    // modify xjz
    // const { currentProvider } = web3 || defaultWeb3;
    const currentProvider = library?.provider;
    if (!currentProvider) {
      return
    }
    const network = Compound.util.getNetNameWithChainId(chainId);
    try {
      if (currentTab === BORROW_TABS.REPAY && !enabled) {
        setLoading(true);
        const tx = await approveToken(underlyingAddress, cTokenAddress, symbol, network, currentProvider);
        setHash(tx.hash)
        dispatch(addTransaction(tx));
        // setLoading(false);
        // onConfirm();
        return;
      }
      if (+input <= 0) {
        message.error('Input needs to be larger than 0');
        return;
      }
      let max;
      const inputNumber = new BigNumber(input).times(`1e${tokenDecimals}`).toFixed(0);
      if (currentTab === BORROW_TABS.BORROW) {
        max = getMaxBorrow(limitInfo, price, underlyingAmount);
        if (new BigNumber(input).gt(underlyingAmount)) {
          message.error('Market dosen\'t have enough liquidiy');
          return;
        }
        if (new BigNumber(input).gt(max)) {
          message.error('Input is too large to borrow, you need to supply more token balance.');
          return;
        }
        setLoading(true)
        const tx = await compound.borrow(symbol, inputNumber, {
          mantissa: true
        });
        dispatch(addTransaction(tx));
        setHash(tx.hash)
        // setLoading(false);
        // onConfirm();
      } else {
        max = walletBalance;
        if (new BigNumber(input).gt(max)) {
          message.error(`Input is too large to repay, you need to have more ${symbol} balance.`);
          return;
        }
        setLoading(true)
        let tx;
        if (borrowBalanceInTokenUnit.times(`1e${tokenDecimals}`).toFixed(0) === inputNumber) {
          if (symbol === 'ETH') {
            const provider = new Web3Provider(currentProvider);
            tx = await repayETHFull(
              inputNumber,
              walletBalance.times(1e18),
              borrowRate,
              network,
              account,
              cTokenAddress,
              provider
            );
          } else {
            tx = await compound.repayBorrow(
              symbol,
              UINT_NEGATIVE_ONE,
              account,
              true,
              {
                mantissa: true
              }
            );
          }
        } else {
          tx = await compound.repayBorrow(
            symbol,
            inputNumber,
            account,
            true,
            {
              mantissa: true
            }
          );
        }
        dispatch(addTransaction(tx));
        setHash(tx.hash)
      }
      // setLoading(false);
      // onConfirm();
    } catch (e) {
      setLoading(false);
      console.error(e);
      message.error((e && e.error)?.message || 'Send Transaction failed');
    }
  }

  function handleValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    try {
      if (Number.isNaN(parseFloat(value)) || +value === 0) {
        setInput(value);
      } else {
        setInput(value);
      }
    } catch (e) {
      console.error(e);
      setInput(value);
    }
  }

  function handleValueBlur() {
    const n = new BigNumber(input || 0);
    const dp = (String(input).split('.')[1] || '').length;
    setInput(n.toFixed(Math.min(dp, tokenDecimals), BigNumber.ROUND_FLOOR));
  }

  const handleMax = useCallback(() => {
    if (currentTab === BORROW_TABS.REPAY) {
      const repay = walletBalance.gte(borrowBalanceInTokenUnit) ? borrowBalanceInTokenUnit : walletBalance;
      setInput(repay.toFixed(tokenDecimals));
    } else {
      setInput(getSafeBorrow(limitInfo, price, underlyingAmount).toFixed(tokenDecimals));
    }
  }, [
    currentTab,
    underlyingAmount,
    limitInfo,
    borrowBalanceInTokenUnit,
    price,
    walletBalance,
    tokenDecimals
  ]);

  function handleTabChange(value: string) {
    setCurrentTab(value);
    setInput('0');
  }

  useEffect(() => {
    if (complete) {
      setLoading(false)
      onConfirm()
    }
  }, [complete, onConfirm])

  return (
    <Modal
      className="borrow-modal"
      visible={visible}
      // title={symbol}
      title={<SymbolCoinIcon size={20} tokenSymbol={symbol} tokenAddress={underlyingAddress} />}
      okText={okProps.okText}
      okButtonProps={{
        ...okProps,
        ...currentTab === BORROW_TABS.BORROW && willRomoveToken ? { disabled: true } : {}
      }}
      maskClosable={!okProps.loading}
      destroyOnClose
      onOk={handleConfirm}
      onCancel={onCancel}
    >
      <Row gutter={32} justify="space-between">
        <If condition={currentTab === BORROW_TABS.REPAY && !enabled}>
          <Then>
            <EnableTips symbol={symbol} />
          </Then>
          <Else>
            <If condition={currentTab === BORROW_TABS.BORROW && willRomoveToken && symbol !='SASHIMI'}>
              <Then>
                <WarningTips content={`${symbol} token will be removed, "borrow" is disabled, please repay`} />
              </Then>
              <Else>
                <>
                  <Col span={16}>
                    <Input
                      type="number"
                      min={0}
                      step={0.1}
                      value={input}
                      onChange={handleValueChange}
                      onBlur={handleValueBlur}
                    />
                  </Col>
                  <Col span={8}>
                    <Button type="link" onClick={handleMax}>{okProps.maxText}</Button>
                  </Col>
                </>
              </Else>
            </If>
          </Else>
        </If>
      </Row>
      <div className="borrow-modal-list gap-top-large">
        <div>
          <Tabs
            activeKey={currentTab}
            onChange={handleTabChange}
            type="card"
          >
            <Tabs.TabPane
              key={BORROW_TABS.BORROW}
              tab={BORROW_TABS.BORROW}
            />
            <Tabs.TabPane
              key={BORROW_TABS.REPAY}
              tab={BORROW_TABS.REPAY}
            />
          </Tabs>
        </div>
        <Divider />
        <ListItem title="Borrow APY" desc={`${borrowRate.toFixed(2)}%`} />
        <Divider />
        <ListItem title="Distribution APY" desc={`${distributionBorrowAPY.toFixed(2)}%`} />
        <Divider />
        <ListItem
          title="Borrow Balance"
          desc={`$${totalBorrowBalance.toFixed(2)} -> $${newLimit.newTotalBorrowBalance.toFixed(2)}`}
        />
        <Divider />
        <ListItem
          title="Borrow Limit"
          desc={`$${totalBorrowLimit.toFixed(2)}`}
        />
        <Divider />
        <ListItem
          title="Borrow Limit Used"
          desc={`${totalBorrowLimitUsedPercent.toFixed(2)} % -> ${newLimit.newUsed.toFixed(2)} %`}
        />
        <Divider />
        <If condition={currentTab === BORROW_TABS.BORROW}>
          <Then>
            <ListItem
              title="Current Borrowing"
              desc={`${borrowBalanceInTokenUnit.toFixed(2)} ${symbol}`}
            />
          </Then>
          <Else>
            <ListItem
              title="Wallet Balance"
              desc={`${walletBalance.toFixed(2)} ${symbol}`}
            />
          </Else>
        </If>
      </div>
    </Modal>
  );
};

BorrowModal.defaultProps = {
  limitInfo: {
    ...GlobalInfoDefaultProps
  },
  ...MarketInfoDefaultProps
};

export default React.memo(BorrowModal);
