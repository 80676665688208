import React from 'react'
import styled from 'styled-components'
import { useMedia } from 'react-use'
import defi from '../../assets/images/logo.svg'
const MainLogo: React.FC = () => {
  const below576 = useMedia('(max-width:576px)')
  return (
    <StyledHeader>
      <a href="/">
        <img src={defi} alt="" />
      </a>
      <LogoTxt>
        <h1>SashimiSwap</h1>
        {!below576 && <p>Keep your wealth growing</p>}
      </LogoTxt>
    </StyledHeader>
  )
}
const StyledHeader = styled.div`
  box-sizing: border-box;
  height: 90px;
  display: felx;
  justify-content: flex-start;
  align-items: center;
  padding: 0 26px;
  @media screen and (max-width: 576px) {
    padding: 0 16px;
  }
  img {
    width: 54px;
    transition: all ease-in-out 0.15s;
    &:hover {
      transform: rotate(-7deg);
      transform-origin: center center;
    }
  }
  @media screen and (max-width: 576px) {
    img {
      width: 32px;
    }
  }
  @media screen and (max-width: 768px) {
    margin-right: auto
  }
`

const LogoTxt = styled.div`
  margin-left: 18px;
  cursor: default;
  h1 {
    font-family: 'Reem Kufi', sans-serif;
    font-size: 20px;
    color: #3c3c3c;
    line-height: 28px;
    font-weight: bold;
  }
  p {
    color: #999;
    font-size: 12px;
    line-height: 16px;
  }
  @media screen and (max-width: 576px) {
    margin-left: 8px;
  }
`
export default MainLogo
