import React from 'react'
import styled from 'styled-components'

interface NoticeProps {
  text?: string
}
// eslint-disable-next-line react/prop-types
const Notice: React.FC<NoticeProps> = ({ text }) => {
  return <StyledNotice>{text}</StyledNotice>
}
const StyledNotice = styled.div`
  box-sizing: border-box;
  width: 100%;
  line-height: 20px;
  padding: 10px 20px;
  word-break: break-all;
  overflow: hidden;
  color: #eb591a;
  background-color: #ffeadb;
`
export default Notice
