import React, { useEffect, useState, useRef } from 'react'
import { formattedPercent } from '../../../../utils'
import { Spin, Select } from 'antd'
import { useMedia } from 'react-use'
import { ResponsiveContainer } from 'recharts'
import { timeframeOptions } from '../../../../constants'
import { useChartActionHandler, useChartDataByAddress } from '../../../../state/pair-chart/hooks'
import { PairChartShowType, PairChartPeriod } from '../../../../state/pair-chart/actions'
import { PairData } from '../../../../state/exchange/entities/pair_entity'
import BalanceUtils from '../../../../fuck/formatBalance'
// import CoinLogo from '../../../../components/CoinLogo'
import styled from 'styled-components'
import CandleStickChart from '../../../../components/CandleChart'
import Chart, { CHART_TYPES } from '../../../../components/GlobalChart/chartView'
// import { useEtherPrice } from '../../../../state/global/hooks'

interface ChartView {
  VOLUME: string
  LIQUIDITY: string
  RATE0: string
  RATE1: string
}
const CHART_VIEW: ChartView = {
  VOLUME: 'Volume',
  LIQUIDITY: 'Liquidity',
  RATE0: 'Rate 0',
  RATE1: 'Rate 1',
}

const Charts: React.FC<PairData> = pair => {
  const below1400 = useMedia('(max-width: 1400px)')
  const [timeWindow, setTimeWindow] = useState(timeframeOptions.WEEK)
  const [chartFilter, setChartFilter] = useState(CHART_VIEW.LIQUIDITY)
  // const ethPrice = useEtherPrice()
  const formattedSymbol0: string =
    pair?.token0?.symbol.length > 6 ? pair?.token0?.symbol.slice(0, 5) + '...' : pair?.token0?.symbol
  const formattedSymbol1: string =
    pair?.token1?.symbol.length > 6 ? pair?.token1?.symbol.slice(0, 5) + '...' : pair?.token1?.symbol

  // // rates
  // const token0Rate = pair.reserve0 && pair.reserve1 ? BalanceUtils.formattedNum(+pair.reserve1 / +pair.reserve0) : '-'
  // const token1Rate = pair.reserve0 && pair.reserve1 ? BalanceUtils.formattedNum(+pair.reserve0 / +pair.reserve1) : '-'

  // const token0USD =
  //   pair.token0?.derivedETH && ethPrice.now
  //     ? BalanceUtils.formattedNum(parseFloat(pair.token0.derivedETH) * parseFloat(`${ethPrice.now}`), true)
  //     : ''

  // const token1USD =
  //   pair.token1?.derivedETH && ethPrice.now
  //     ? BalanceUtils.formattedNum(parseFloat(pair.token1.derivedETH) * parseFloat(`${ethPrice.now}`), true)
  //     : ''

  const oneDayVolumeUSD: number = parseFloat(pair.oneDayVolumeUSD)
  const oneDayVolumeUntracked: number = parseFloat(pair.oneDayVolumeUntracked ?? '0')
  // liquidity
  const liquidity = pair.reserveUSD ? BalanceUtils.formattedNum(pair.reserveUSD, true) : '-'
  const { value } = formattedPercent(pair.liquidityChangeUSD)
  const liquidityChange: string = value

  // mark if using untracked volume
  const [usingUtVolume, setUsingUtVolume] = useState(false)
  // update the width on a window resize
  const ref = useRef<any>()
  const isClient = typeof window === 'object'
  const [width, setWidth] = useState<number>(ref?.current?.container?.clientWidth)
  const [height, setHeight] = useState<number>(ref?.current?.container?.clientHeight)
  useEffect(() => {
    setUsingUtVolume(oneDayVolumeUSD === 0)

    function handleResize() {
      setWidth(ref?.current?.container?.clientWidth ?? width)
      setHeight(ref?.current?.container?.clientHeight ?? height)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [oneDayVolumeUSD, height, isClient, width])

  // volume
  const volume =
    oneDayVolumeUSD || oneDayVolumeUSD === 0
      ? BalanceUtils.formattedNum(oneDayVolumeUSD === 0 ? oneDayVolumeUntracked : oneDayVolumeUSD, true)
      : oneDayVolumeUSD === 0
      ? '$0'
      : '-'
  const volumeChange = formattedPercent(pair.volumeChangeUSD)

  // get fees
  const fees: string =
    pair.oneDayVolumeUSD || oneDayVolumeUSD === 0
      ? usingUtVolume
        ? BalanceUtils.formattedNum(oneDayVolumeUntracked * 0.003, true)
        : BalanceUtils.formattedNum(oneDayVolumeUSD * 0.003, true)
      : '-'

  const { updateType, updatePeriod } = useChartActionHandler()
  const { showList } = useChartDataByAddress(pair.id)

  const aspect = 50 / 25

  // if (showList && showList.length === 0) {
  //   return <EmptyCard>No historical data yet.</EmptyCard>
  // }
  function valueFormatter(val: any): any {
    if (chartFilter === CHART_VIEW.RATE0) {
      return (
        BalanceUtils.formattedNum(val) +
        `<span style="font-size: 12px; margin-left: 4px;">${formattedSymbol1}/${formattedSymbol0}<span>`
      )
    }
    if (chartFilter === CHART_VIEW.RATE1) {
      return (
        BalanceUtils.formattedNum(val) +
        `<span style="font-size: 12px; margin-left: 4px;">${formattedSymbol0}/${formattedSymbol1}<span>`
      )
    }
  }
  const base0 = Number(pair?.reserve0) / Number(pair?.reserve1)
  const base1 = Number(pair?.reserve1) / Number(pair?.reserve0)
  const strategy: {
    [key: string]: any
  } = {
    liq: () => {
      setTimeWindow(timeframeOptions.ALL_TIME)
      setChartFilter(CHART_VIEW.LIQUIDITY)
      updateType(PairChartShowType.Liquidity)
      updatePeriod(PairChartPeriod.ALL)
    },
    vol: () => {
      setTimeWindow(timeframeOptions.ALL_TIME)
      setChartFilter(CHART_VIEW.VOLUME)
      updateType(PairChartShowType.Volume)
      updatePeriod(PairChartPeriod.ALL)
    },
    token0: () => {
      setTimeWindow(timeframeOptions.WEEK)
      setChartFilter(CHART_VIEW.RATE0)
      updateType(PairChartShowType.PositiveCandles)
      updatePeriod(PairChartPeriod.WEEK)
    },
    token1: () => {
      setTimeWindow(timeframeOptions.WEEK)
      setChartFilter(CHART_VIEW.RATE1)
      updateType(PairChartShowType.ReverseCandles)
      updatePeriod(PairChartPeriod.WEEK)
    }
  }
  return (
    <Wrap>
      <TradePairInfoWrap>
        <TradePairInfo>
          <div className="item">
            <p>Total Liquidity</p>
            <h2>
              {liquidity}
              <span className={parseFloat(liquidityChange) >= 0 ? 'green' : 'red'}>{liquidityChange}</span>
            </h2>
          </div>
          <div className="item">
            <p>Volume（24hrs）</p>
            <h2>
              {volume}
              <span className={parseFloat(volumeChange.value) >= 0 ? 'green' : 'red'}>{volumeChange.value}</span>
            </h2>
          </div>
          <div className="item">
            <p>Fees（24hrs）</p>
            <h2>{fees}</h2>
          </div>
        </TradePairInfo>

        <ChartContainer>
          <Cycle>
            {below1400 && (
              <Select
                defaultValue="liq"
                style={{ borderRadius: '4px', height: '24px' }}
                onChange={(val: string) => {
                  strategy[val]()
                }}
              >
                <Select.Option value="liq">Liquidity</Select.Option>
                <Select.Option value="vol">Volumn</Select.Option>
                <Select.Option value="token0">
                  {pair.token0 ? formattedSymbol1 + '/' + formattedSymbol0 : '-'}
                </Select.Option>
                <Select.Option value="token1">
                  {pair.token0 ? formattedSymbol0 + '/' + formattedSymbol1 : '-'}
                </Select.Option>
              </Select>
            )}
            {!below1400 && (
              <div className="cycle-item-list">
                <span
                  className={chartFilter === CHART_VIEW.LIQUIDITY ? 'active' : ''}
                  onClick={() => {
                    setTimeWindow(timeframeOptions.ALL_TIME)
                    setChartFilter(CHART_VIEW.LIQUIDITY)
                    updateType(PairChartShowType.Liquidity)
                    updatePeriod(PairChartPeriod.ALL)
                  }}
                >
                  Liquidity
                </span>
                <span
                  className={chartFilter === CHART_VIEW.VOLUME ? 'active' : ''}
                  onClick={() => {
                    setTimeWindow(timeframeOptions.ALL_TIME)
                    setChartFilter(CHART_VIEW.VOLUME)
                    updateType(PairChartShowType.Volume)
                    updatePeriod(PairChartPeriod.ALL)
                  }}
                >
                  Volume
                </span>
                <span
                  className={chartFilter === CHART_VIEW.RATE0 ? 'active' : ''}
                  onClick={() => {
                    setTimeWindow(timeframeOptions.WEEK)
                    setChartFilter(CHART_VIEW.RATE0)
                    updateType(PairChartShowType.PositiveCandles)
                    updatePeriod(PairChartPeriod.WEEK)
                  }}
                >
                  {pair.token0 ? formattedSymbol1 + '/' + formattedSymbol0 : '-'}
                </span>
                <span
                  className={chartFilter === CHART_VIEW.RATE1 ? 'active' : ''}
                  onClick={() => {
                    setTimeWindow(timeframeOptions.WEEK)
                    setChartFilter(CHART_VIEW.RATE1)
                    updateType(PairChartShowType.ReverseCandles)
                    updatePeriod(PairChartPeriod.WEEK)
                  }}
                >
                  {pair.token0 ? formattedSymbol0 + '/' + formattedSymbol1 : '-'}
                </span>
              </div>
            )}

            <div className="cycle-item-list">
              <span
                className={timeWindow === timeframeOptions.WEEK ? 'active' : ''}
                onClick={() => {
                  setTimeWindow(timeframeOptions.WEEK)
                  updatePeriod(PairChartPeriod.WEEK)
                }}
              >
                1W
              </span>
              <span
                className={timeWindow === timeframeOptions.MONTH ? 'active' : ''}
                onClick={() => {
                  setTimeWindow(timeframeOptions.MONTH)
                  updatePeriod(PairChartPeriod.MONTH)
                }}
              >
                1M
              </span>
              <span
                className={timeWindow === timeframeOptions.ALL_TIME ? 'active' : ''}
                onClick={() => {
                  setTimeWindow(timeframeOptions.ALL_TIME)
                  updatePeriod(PairChartPeriod.ALL)
                }}
              >
                All
              </span>
            </div>
          </Cycle>
          {chartFilter === CHART_VIEW.LIQUIDITY &&
            (showList ? (
              <ResponsiveContainer maxHeight={285} aspect={aspect} ref={ref}>
                <Chart
                  data={showList}
                  // base={1}
                  // baseChange={0}
                  title={'(USD) '}
                  field="reserveUSD"
                  width={width}
                  height={285}
                  type={CHART_TYPES.AREA}
                  toolTipMove={true}
                />
              </ResponsiveContainer>
            ) : (
              <SpinWrap>
                <Spin size="large" tip="Loading···" />
              </SpinWrap>
            ))}
          {chartFilter === CHART_VIEW.VOLUME &&
            (showList ? (
              <ResponsiveContainer maxHeight={285} aspect={aspect} ref={ref}>
                <Chart
                  data={showList}
                  // base={1}
                  // baseChange={0}
                  title={'Volume '}
                  field="dailyVolumeUSD"
                  width={width}
                  height={285}
                  type={CHART_TYPES.BAR}
                  toolTipMove={true}
                />
              </ResponsiveContainer>
            ) : (
              <SpinWrap>
                <Spin size="large" tip="Loading···" />
              </SpinWrap>
            ))}
          {/* ref={ref} */}
          {chartFilter === CHART_VIEW.RATE1 &&
            (showList ? (
              <CandleStickChart
                data={showList}
                base={base1}
                margin={false}
                width={width}
                height={285}
                valueFormatter={valueFormatter}
              />
            ) : (
              <SpinWrap>
                <Spin size="large" tip="Loading···" />
              </SpinWrap>
            ))}

          {chartFilter === CHART_VIEW.RATE0 &&
            (showList ? (
              <CandleStickChart
                data={showList}
                base={base0}
                margin={false}
                width={width}
                height={285}
                valueFormatter={valueFormatter}
              />
            ) : (
              <SpinWrap>
                <Spin size="large" tip="Loading···" />
              </SpinWrap>
            ))}
        </ChartContainer>
      </TradePairInfoWrap>
    </Wrap>
  )
}
const SpinWrap = styled.div`
  width: 100%;
  min-height: 300px;
  display: felx;
  align-items: center;
  justify-content: center;
`
const Wrap = styled.div`
  flex: 1;
`

const TradePairInfoWrap = styled.div`
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 64px;
  @media screen and (max-width: 576px) {
    padding: 0;
  }
`
const TradePairInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    flex: 1;
    text-align: left;
    p {
      line-height: 16px;
      color: #666;
    }
    h2 {
      margin-top: 7px;
      font-size: 20px;
      color: #131313;
      font-weight: 500;
      span {
        padding-left: 10px;
        font-size: 12px;
      }
    }
    .green {
      color: #06b478;
    }
    .red {
      color: #e04a59;
    }
  }
`

const Cycle = styled.div`
  width: 100%;
  z-index: 0;
  position: absolute;
  top: 30px;
  left: 0;
  display: flex;
  justify-content: space-between;
  .ant-select-selector {
    height: 24px !important;
    .ant-select-selection-item {
      line-height: 22px !important;
    }
  }
  .cycle-item-list {
    display: flex;
    span {
      padding: 0 10px;
      line-height: 22px;
      font-size: 12px;
      color: #666;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      margin-right: 10px;
      cursor: pointer;
      &:hover,
      &.active {
        background: rgba(252, 133, 95, 0.05);
        border: 1px solid #fc855f;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`
const ChartContainer = styled.div`
  position: relative;
  padding-top: 60px;
  overflow: hidden;
`
export default Charts
