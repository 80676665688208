import * as online from './online'
import * as testKovan from './test-kovan';
import BigNumber from 'bignumber.js'

const CHAIN_ENV = process.env.REACT_APP_CHAIN_ENV || 'main'

const output = {
  main: online,
  kovan: testKovan,
}

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
})
const TOKENS_DECIMAL = {
  USDT: 6,
  USDC: 6,
  SVUSDT: 6,
  SVUSDC: 6
};
export const {
  contractAddresses,
  sashimiAddress,
  wethAddress,
  wethSashimiLpPAddress,
  mainContractAddr,
  supportedInvestmentPools,
  supportedLendingInvestmentPools,
  supportedPools,
  waitingInfo,
  vaults,
  tokensDecimal = TOKENS_DECIMAL,
  vaultStableTokenPriceAPI,
  tokenPriceAPI = 'https://min-api.cryptocompare.com/data/price' // ?fsym=ETH&tsyms=USD
} = {
  ...output[CHAIN_ENV]
}
