import { createReducer } from '@reduxjs/toolkit'
import {
  UPDATE_TOP_TOKENS,
  UPDATE_CHART_DATA,
  UPDATE_PRICE_DATA
} from './actions'

// const defaultChainId = store.get('chain_id') || 1;
// const defaultChainId = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1');

interface TokenState {
  allTokens: {}
  tokensData: {}
}

const initialState: TokenState = {
  allTokens: {},
  tokensData: {}
}

export default createReducer(initialState, builder => {
  builder
    .addCase(UPDATE_TOP_TOKENS, (state, action) => {
      const topTokens = action.payload
      state.allTokens = topTokens.reduce((prev: any, cur: any) => {
        return {
          ...prev,
          [cur.id]: cur
        }
      }, {})
    })
    .addCase(UPDATE_CHART_DATA, (state, action) => {
      const chartData = action.payload
      state.tokensData = chartData
    })
    .addCase(UPDATE_PRICE_DATA, (state, action) => {
      const chartData = action.payload
      state.allTokens = chartData
    })
})
