import BigNumber from 'bignumber.js';
import { getAddress } from '@ethersproject/address';
import Compound from '@sashimiswap/compound-js';
import { MaxUint256 } from '@ethersproject/constants';
import {
  CHAIN_IDS, willRemovedTokenAddressList
} from './constants';

export function isAddress(value: string) {
  try {
    getAddress(value);
    return true;
  } catch {
    return false;
  }
}

const ETHERSCAN_PREFIXES = {
  [CHAIN_IDS.MAINNET]: '',
  [CHAIN_IDS.KOVAN]: 'kovan.'
};

export function getEtherscanLink(chainId: number, data: any, type: string) {
  const prefix = `https://${ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]}etherscan.io`;

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`;
    }
    case 'token': {
      return `${prefix}/token/${data}`;
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`;
    }
  }
}

export function ellipseAddress(
  address: any,
  width = 10
) {
  return `${address.slice(0, width)}...${address.slice(-width)}`;
}

export function nFormatter(num: number, digits = 2) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'k' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'G' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return `${(num / si[i].value).toFixed(digits).replace(rx, '$1')}${si[i].symbol}`;
}

export function getRatePerYear(str: string, precision = 6) {
  return new BigNumber(str).times(2102400).toFixed(precision);
}

export async function approveToken(address:string, cTokenAddress: string, symbol: string, network: any, provider: any) {
  return Compound.eth.trx(
    address,
    'approve',
    [cTokenAddress, MaxUint256], // [optional] parameters
    {
      network,
      provider,
      abi: Compound.abi.cErc20,
    } // [optional] call options, provider, network, ethers.js "overrides"
  );
}

export function judgeWillRemoveToken(address: string) {
  return !!willRemovedTokenAddressList[address]
}