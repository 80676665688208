import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

/**
 * 合约项目节点图
 */
export const SubgraphClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/sashimiproject/sashimi-v2-fix'
  }),
  cache: new InMemoryCache()
})

/**
 * 区块链
 */
export const BlockClient = new ApolloClient({
  link: new HttpLink({
    // 主网链接
    uri: 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks'
    // kovan链接
    // uri: 'https://api.thegraph.com/subgraphs/name/blocklytics/kovan-blocks'
  }),
  cache: new InMemoryCache()
})

/**
 * 健康检查
 */
export const HealthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql'
  }),
  cache: new InMemoryCache()
})

/**
 * 获取lending
 */
export const LendingClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/sashimiproject/loan'
  }),
  cache: new InMemoryCache()
})
export const SwapInfoClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswapv2'
  }),
  cache: new InMemoryCache(),
})
