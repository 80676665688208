/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react'
import { createChart } from 'lightweight-charts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import BalanceUtils from '../../fuck/formatBalance'
import styled from 'styled-components'
import { usePrevious } from 'react-use'
// import { Play } from 'react-feather'

export const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  border-radius: 3px;
  height: 16px;
  width: 16px;
  padding: 0px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.text1};

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`
dayjs.extend(utc)

export const CHART_TYPES = {
  BAR: 'BAR',
  AREA: 'AREA'
}

const Wrapper = styled.div`
  position: relative;
`

const Chart = ({
  type = CHART_TYPES.BAR,
  data,
  base,
  baseChange,
  field,
  title,
  width,
  height,
  useWeekly = false,
  toolTipMove = false
}: {
  type: any
  data: any
  base?: number
  baseChange?: number
  field: string
  title?: string
  width: number
  height?: number
  useWeekly?: boolean
  toolTipMove?: boolean
}) => {
  // reference for DOM element to create with chart
  const ref = useRef<any>()

  // pointer to the chart object
  const [chartCreated, setChartCreated] = useState<any>(false)
  const dataPrev = usePrevious(data)

  useEffect(() => {
    if (data !== dataPrev && chartCreated) {
      // remove the tooltip element
      const tooltip: HTMLElement | null = document.getElementById('tooltip-id' + type)
      const node: any = document.getElementById('test-id' + type)
      node?.removeChild(tooltip as HTMLElement)
      chartCreated?.resize(0, 0)
      setChartCreated(false)
    }
  }, [chartCreated, data, dataPrev, type])

  // parese the data and format for tardingview consumption
  const formattedData = data
    // 过滤错误日期格式数据
    ?.filter((kLine: any) => dayjs.unix(kLine.date).isValid())
    ?.map((entry: any) => {
      return {
        time: dayjs
          .unix(entry.date)
          .utc()
          .format('YYYY-MM-DD'),
        value: parseFloat(entry[field])
      }
    })

  // adjust the scale based on the type of chart
  const topScale = 0.2

  // const [darkMode] = useDarkModeManager()
  const darkMode = false
  const textColor = darkMode ? 'white' : 'black'
  const previousTheme = usePrevious(darkMode)
  const previousCreated = usePrevious(chartCreated)
  // reset the chart if them switches
  useEffect(() => {
    if (chartCreated && previousTheme !== darkMode) {
      // remove the tooltip element
      const tooltip: HTMLElement | null = document.getElementById('tooltip-id' + type)
      const node: any = document.getElementById('test-id' + type)
      node?.removeChild(tooltip as HTMLElement)
      chartCreated?.resize(0, 0)
      setChartCreated(false)
    }
  }, [chartCreated, darkMode, previousTheme, type])

  // if no chart created yet, create one with options and add to DOM manually
  useEffect(() => {
    if (!chartCreated && formattedData && !previousCreated ) {
      const chartWarp: HTMLElement | null = document.getElementById(`test-id${type}`)
      chartWarp?.childNodes.forEach(item=>{
        item.remove()
      })
      const chart = createChart(ref.current, {
        width: width,
        height: height,
        layout: {
          backgroundColor: 'transparent',
          textColor: textColor
        },
        rightPriceScale: {
          scaleMargins: {
            top: topScale,
            bottom: 0.03
          },
          borderVisible: false
        },
        timeScale: {
          borderVisible: false
        },
        grid: {
          horzLines: {
            color: 'rgba(197, 203, 206, 0.5)',
            visible: false
          },
          vertLines: {
            color: 'rgba(197, 203, 206, 0.5)',
            visible: false
          }
        },
        crosshair: {
          horzLine: {
            visible: toolTipMove && type === CHART_TYPES.AREA,
            style: 0,
            width: 2,
            color: 'rgba(32, 38, 46, 0.1)',
            labelVisible: false
          },
          vertLine: {
            visible: true,
            style: 0,
            width: 2,
            color: 'rgba(32, 38, 46, 0.1)',
            labelVisible: false
          }
        },
        localization: {
          priceFormatter: (val: string | number) => BalanceUtils.formattedNum(val, true)
        }
      })
      chart.applyOptions({
        localization: {
          locale: 'en-US',
          dateFormat: "dd MMM 'yy"
        }
      })
      const series =
        type === CHART_TYPES.BAR
          ? chart.addHistogramSeries({
              color: '#fc855f',
              priceFormat: {
                type: 'volume'
              },
              scaleMargins: {
                top: 0.32,
                bottom: 0
              }
              // lineColor: '#fc855f',
              // lineWidth: 3
            })
          : chart.addAreaSeries({
              topColor: '#fc855f',
              bottomColor: 'rgba(252,133,95, 0.3)',
              lineColor: '#EB591A',
              lineWidth: 2
            })

      series.setData(formattedData)
      const toolTip = document.createElement('div')
      toolTip.setAttribute('id', 'tooltip-id' + type)
      toolTip.className = darkMode ? 'three-line-legend-dark' : 'three-line-legend'
      ref.current.appendChild(toolTip)
      toolTip.style.display = 'block'
      toolTip.style.fontWeight = '500'
      toolTip.style.left = '0px'
      toolTip.style.top = '-8px'
      toolTip.style.backgroundColor = 'transparent'
      // format numbers
      const percentChange: any = baseChange?.toFixed(2)
      const formattedPercentChange = (percentChange > 0 ? '+' : '') + percentChange + '%'
      const color = percentChange >= 0 ? 'green' : 'red'

      // get the title of the chart
      const setLastBarText = () => {
        toolTip.innerHTML =
          `<div style="font-size: 16px; margin: 4px 0px; color: ${textColor};">${title} ${
            type === CHART_TYPES.BAR && !useWeekly ? '(24hr)' : ''
          }</div>` +
          `<div style="font-size: 22px; margin: 4px 0px; color:${textColor}" >` +
          BalanceUtils.formattedNum(base ?? 0, true) +
          `<span style="margin-left: 10px; font-size: 16px; color: ${color};">${formattedPercentChange}</span>` +
          '</div>'
      }
      if (!toolTipMove) setLastBarText()

      // update the title when hovering on the chart
      chart.subscribeCrosshairMove(function(param: any) {
        if (
          param === undefined ||
          param.time === undefined ||
          param.point.x < 0 ||
          param.point.x > width ||
          param.point.y < 0 ||
          param.point.y > (height ?? 258)
        ) {
          if (toolTipMove) {
            toolTip.style.display = 'none'
          } else {
            setLastBarText()
          }
        } else {
          toolTip.style.display = 'block'
          const dateStr = useWeekly
            ? dayjs(param.time.year + '-' + param.time.month + '-' + param.time.day)
                .startOf('week')
                .format('MMMM D, YYYY') +
              '-' +
              dayjs(param.time.year + '-' + param.time.month + '-' + param.time.day)
                .endOf('week')
                .format('MMMM D, YYYY')
            : dayjs(param.time.year + '-' + param.time.month + '-' + param.time.day).format('MMMM D, YYYY')
          const price = param.seriesPrices.get(series)
          if (!toolTipMove) {
            toolTip.innerHTML =
              `<div style="font-size: 16px; margin: 4px 0px; color: ${textColor};">${title}</div>` +
              `<div style="font-size: 22px; margin: 4px 0px; color: ${textColor}">` +
              BalanceUtils.formattedNum(price, true) +
              '</div>' +
              '<div>' +
              dateStr +
              '</div>'
            return
          }
          toolTip.innerHTML =
            `<div style="border-radius:10px;padding:10px;border:1px solid #EB591A;background-color:#fff;width:max-content;"><div style="font-size: 16px; color: ${textColor};">${title}</div>` +
            `<div style="font-size: 20px; color: ${textColor}">` +
            BalanceUtils.formattedNum(price, true) +
            '</div>' +
            '<div>' +
            dateStr +
            '</div></div>'
          let shiftedCoordinate = param.point.x - 50
          const coordinate = series.priceToCoordinate(price)
          if (coordinate === null) {
            return
          }
          const toolTipWidth = 80
          const toolTipHeight = 80
          const toolTipMargin = 15
          shiftedCoordinate = Math.max(0, Math.min(toolTip.clientWidth - toolTipWidth, shiftedCoordinate))
          const coordinateY =
            coordinate - toolTipHeight - toolTipMargin > 0
              ? coordinate - toolTipHeight - toolTipMargin
              : Math.max(0, Math.min(toolTip.clientHeight - toolTipHeight - toolTipMargin, coordinate + toolTipMargin))
          toolTip.style.left = shiftedCoordinate + 'px'
          toolTip.style.top = coordinateY + 'px'
        }
      })

      chart.timeScale().fitContent()
      setChartCreated(chart)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    base,
    baseChange,
    chartCreated,
    darkMode,
    data,
    formattedData,
    textColor,
    title,
    toolTipMove,
    topScale,
    type,
    useWeekly,
    width
  ])

  // responsiveness
  useEffect(() => {
    if (width) {
      chartCreated && chartCreated.resize(width, height)
      chartCreated && chartCreated.timeScale().scrollToPosition(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartCreated, width])

  return (
    <Wrapper>
      <div ref={ref} id={`test-id${type}`}></div>
    </Wrapper>
  )
}

export default Chart
