import { createAction } from '@reduxjs/toolkit'
// import exp from 'constants'

export enum PairChartShowType {
  Liquidity,
  Volume,
  PositiveCandles,
  ReverseCandles
}

export enum PairChartPeriod {
  MONTH = 'month',
  WEEK = 'week',
  ALL = 'all'
}

export interface PairChartData {
  // 条形图
  barChart?: any[]
  // 蜡烛图
  candles: {
    [period: string]: [any[], any[]]
  }
}

export function isBarChart(type: PairChartShowType): boolean {
  return type === PairChartShowType.Liquidity || type === PairChartShowType.Volume
}

export function isCandles(type: PairChartShowType): boolean {
  return type === PairChartShowType.PositiveCandles || type === PairChartShowType.ReverseCandles
}

export const initialChartData = createAction<{ [address: string]: PairChartData }>('pair/chart/initial')
export const updateChartType = createAction<{ showType: PairChartShowType; data: any }>(
  'pair/chart/updateChartShowType'
)
export const updateChartPeriod = createAction<{ period: PairChartPeriod }>('pair/chart/updatePeriod')

export const updatePairAddress = createAction<{ pairAddress: string }>('pair/chart/updateAddress')
