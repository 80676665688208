import { AppState } from '../index'
import { useSelector } from 'react-redux'
import { PairTransactionEntity } from './entities/pair_transaction_entity'
import { TokenEntity } from './entities/token_entity'

export function useTokens(): { [key: string]: TokenEntity } {
  return useSelector((state: AppState) => state.exchange.tokens)
}

export function usePairTransactionEntityByAddress(pairAddress: string): PairTransactionEntity {
  return useSelector((state: AppState) => state.exchange.pairTransaction[pairAddress])
}
