import { TPoolsInfo, TConfig } from '../components'

const SASHIMI_ADDRESS = '0x89d5f2e52d36b85e72e25e8fe46d709d445c03fc';
const LP_BLOCK_TAG = 29454670;
// const START_TIME = 1642592490543;
// const END_TIME = 1643592490543;

const LP_DECIMAL = 18;
const LP_DECIMAL_TENTH_POWER = 10 ** LP_DECIMAL;

const POOLS_INFO: TPoolsInfo = [
  {
    "lpName": "DAI-WETH",
    "lpToken": "0x7020612AEF38a14d76FF30915e185fFC555c8823"
  },
  {
    "lpName": "USDT-WETH",
    "lpToken": "0xb769468CB379784ee12F7517DbfEeADE75bDe07d"
  },
  {
    "lpName": "ELF-USDT",
    "lpToken": "0xfBCA2Ac9c5a45Bcd06d285473c9876Cd0808DD04"
  },
  {
    "lpName": "ELF-WETH",
    "lpToken": "0xEEe8dE2d1bEC528D1C53a2649eA1cAaab8A3eAb4"
  }
];

const EXCHANGE_DELEGATE_ADDRESS = '0x73BeA51E854FDD4bdfc3f32109a45C69c5c19f92';
const SHARDING_DAO_ADDRESS = '0x627Af3B7bB61CB5E96a1F31277eb387DeFe737A1';

export {
  SHARDING_DAO_ADDRESS,
  SASHIMI_ADDRESS,
  EXCHANGE_DELEGATE_ADDRESS,
  LP_BLOCK_TAG,
  // START_TIME,
  // END_TIME,
  POOLS_INFO,
  // LP_DECIMAL,
  LP_DECIMAL_TENTH_POWER
};
